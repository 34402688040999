import Clock from "@/component/Timer";

import { Avatar } from "@/Ticket/Avatar";
import { MyUser } from "@/Ticket/User";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuSeparator,
    ContextMenuSub,
    ContextMenuSubContent,
    ContextMenuSubTrigger,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";
import { useApi } from "@/interfaces/api";
import type {
    GetTopicsResponse,
    GetUserResponse,
    IconEntry,
    Query,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    arraysAreEqual,
    cleanText,
    getColorLight,
    getExternalIssueIcon,
    getHtmlStringFromReactContent,
    getIconForType,
    getOptions,
    saveIssue,
} from "@/utilities/methods";
import {
    AvatarIcon,
    ComponentBooleanIcon,
    DotFilledIcon,
    DoubleArrowLeftIcon,
    OpenInNewWindowIcon,
    ReloadIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import parse from "html-react-parser";
import React, {
    useEffect,
    useMemo,
    useState,
    useCallback,
    memo,
    Suspense,
} from "react";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { toHTML } from "slack-markdown";
import { ElementDisplay } from "./FilterDropdownElement";
import { statusPriorityOrder } from "./constants";

import { IssueListType } from "@/pages/Admin/AdminQueriesPage";

const areEqual = (
    prevProps: IssuesListCardProps,
    nextProps: IssuesListCardProps,
) => {
    return (
        prevProps.issue === nextProps.issue &&
        arraysAreEqual(prevProps.topics, nextProps.topics) &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.userID === nextProps.userID &&
        arraysAreEqual(prevProps.users, nextProps.users) &&
        prevProps.aiTaggingEnabled === nextProps.aiTaggingEnabled &&
        prevProps.listType === nextProps.listType
    );
};

interface IssuesListCardProps {
    issue: Query;
    topics: { color: string; label: string; value: string }[];
    topicsMap: Map<string, GetTopicsResponse>;
    userID: string;
    users: GetUserResponse[];
    aiTaggingEnabled: boolean;
    listType: IssueListType;
}

function IssuesListCard({
    issue,
    topics,
    topicsMap,
    userID,
    users,
    aiTaggingEnabled,
    listType,
}: IssuesListCardProps) {
    const navigate = useNavigate();
    const api = useApi();
    const dropdownOptions = ["Assignee", "Status", "Tag", "Topic"];
    const [issueState, setIssueState] = useState<Query>(issue);
    const [filters, setFilters] = React.useState(
        new Map<string, Set<string>>(),
    );

    const foundUser: GetUserResponse | undefined = useMemo(
        () => users.find((user) => user.id === issueState.assignee_user_id),
        [issueState.assignee_user_id, users],
    );
    const pictureURL = foundUser?.picture_url ?? "";
    const userName = `${foundUser?.first_name} ${foundUser?.last_name}`;

    const SourceSvgImage: React.ElementType | undefined = useMemo(
        () =>
            integrationBackEndDataMappingToSvg.get(
                issueState.source ?? "Unknown",
            ),
        [issueState.source],
    );

    const externalIssuesIcons = useMemo(() => {
        const icons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        issueState.external_issues?.forEach((url) => {
            icons.add({
                Component: getExternalIssueIcon(url),
                props: {
                    width: 20,
                    height: 20,
                    style: { marginLeft: "2", marginRight: "2" },
                },
            });
        });
        return icons;
    }, [issueState.external_issues]);

    const date: string = useMemo(() => {
        let updatedDate = new Date(issueState.ticket_updated_at);
        if (
            Number.isNaN(updatedDate.getTime()) ||
            !issueState.ticket_updated_at
        ) {
            updatedDate = new Date();
        }
        const today = new Date();

        const isToday =
            updatedDate.getDate() === today.getDate() &&
            updatedDate.getMonth() === today.getMonth() &&
            updatedDate.getFullYear() === today.getFullYear();

        if (isToday) {
            const userLocale = navigator.language || "en-US";
            return updatedDate.toLocaleTimeString(userLocale, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
        }

        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        return updatedDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }, [issueState.ticket_updated_at]);

    const [visibleTopics, setVisibleTopics] = useState<
        { label: string; value: string }[]
    >([]);
    const [overflowTopics, setOverflowTopics] = useState<Map<string, string[]>>(
        new Map(),
    );
    const [showTagText, setShowTagText] = useState<boolean>(true);
    const [titleMaxWidth, setTitleMaxWidth] = useState(
        window.innerWidth * 0.35,
    );

    // Calculate topic dimensions
    const measureTopicWidth = useMemo(() => {
        const measurementElement = document.createElement("div");
        measurementElement.style.position = "absolute";
        measurementElement.style.visibility = "hidden";
        measurementElement.style.whiteSpace = "nowrap";
        measurementElement.style.padding = "0";
        document.body.appendChild(measurementElement);

        return (text: string): number => {
            measurementElement.innerText = text;
            return measurementElement.offsetWidth;
        };
    }, []);
    const updateDimensions = useCallback(() => {
        let allTopics: string[];
        if (aiTaggingEnabled) {
            allTopics = [...issueState.user_added_topic, ...issueState.topic];
        } else {
            allTopics = issueState.user_added_topic ?? [];
        }

        const topicWidths = allTopics.map(measureTopicWidth);
        const maxVisibleWidth = window.innerWidth * 0.1; // 10% of screen width
        let totalWidth = 0;
        const topicsToShow = [];
        const overflow: Map<string, string[]> = new Map();

        for (let i = 0; i < topicWidths.length; i++) {
            let topicColor: string;
            let value: string;
            if (i < issueState.user_added_topic.length) {
                topicColor =
                    topicsMap.get(issueState.user_added_topic[i])?.color ??
                    "#9B9EF0";
                value = issueState.user_added_topic[i];
            } else {
                topicColor =
                    topicsMap.get(issueState.topic[i])?.color ?? "#9B9EF0";
                value = issueState.topic[i];
            }
            if (topicColor === "") {
                topicColor = "#9B9EF0";
            }
            if (totalWidth + topicWidths[i] <= maxVisibleWidth) {
                topicsToShow.push({
                    value: value,
                    label: topicColor,
                });
                totalWidth += topicWidths[i];
            } else {
                if (overflow.has(topicColor)) {
                    const curr = overflow.get(topicColor) || [];
                    overflow.set(topicColor, [...curr, value]);
                } else {
                    overflow.set(topicColor, [value]);
                }
            }
        }
        setVisibleTopics(topicsToShow);
        setOverflowTopics(overflow);
        setShowTagText(window.innerWidth * 0.03 > 50);
        setTitleMaxWidth(window.innerWidth * 0.4);
    }, [issueState.topic, issueState.user_added_topic, topicsMap]);

    useEffect(() => {
        updateDimensions();
    }, [issueState.topic, issueState.user_added_topic]);

    useEffect(() => {
        const newFilters = new Map<string, Set<string>>();
        newFilters.set("Tag", new Set([issueState.bot_category]));
        newFilters.set("Topic", new Set(issueState.topic));
        newFilters.set("Status", new Set([issueState.ticket_status]));
        if (issueState.assignee_user_id) {
            newFilters.set("Assignee", new Set([issueState.assignee_user_id]));
        }

        setFilters(newFilters);
    }, [issueState]);

    const handleRowClick = (id: string) => {
        let from: string;
        if (listType === IssueListType.Inbox) {
            from = "inbox";
        } else if (listType === IssueListType.Issues) {
            from = "issues";
        } else if (listType === IssueListType.Team) {
            from = "team";
        } else {
            from = "issues"; // Default case if needed
        }
        navigate(`/issue/${id}`, { state: { from: from } });
    };

    const updateIssueState = (newState: Partial<Query>) => {
        setIssueState((prevState) => ({
            ...prevState,
            ...newState,
        }));
        if (newState.bot_category) {
            issue.bot_category = newState.bot_category;
        }
        if (newState.topic) {
            issue.topic = newState.topic.sort();
        }
        if (newState.user_added_topic) {
            issue.user_added_topic = newState.user_added_topic.sort();
        }
        if (newState.ticket_status) {
            issue.ticket_status = newState.ticket_status;
        }
        if (newState.assignee_user_id) {
            issue.assignee_user_id = newState.assignee_user_id;
        }
    };

    const handleOpenInNewTab = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        id: string,
    ) => {
        event.stopPropagation();
        const from = listType === IssueListType.Inbox ? "inbox" : "issues";
        sessionStorage.setItem("from", from);
        window.open(`/issue/${id}`, "_blank");
    };

    const handleReload = () => {
        window.location.reload();
    };

    const timestampCutoff = "2024-09-30T23:50:00.000000Z";
    const parsedTimestampCutoff = new Date(timestampCutoff);
    const commentParsedTimestamp = new Date(issue.created_at);

    return (
        <Card
            className="py-2.5 px-6 border-[#fafafa] border-l-transparent border-r-transparent border-b-transparent hover:bg-muted rounded w-full h-20"
            onClick={() =>
                handleRowClick(
                    `${issueState.ticket_identifier}-${issueState.ticket_number}`,
                )
            }
        >
            <CardContent className="p-0">
                <ContextMenu>
                    <ContextMenuTrigger>
                        <button
                            className="text-xs bg-transparent border-none p-0 cursor-pointer w-full"
                            type="button"
                        >
                            <div
                                className={`flex items-center justify-between w-full gap-1.5 ${issueState.user_info.id === "" && "pt-2"}`}
                            >
                                <div className="flex items-center gap-3">
                                    {issueState.user_info.id !== "" && (
                                        <div className="lb-root">
                                            <div className="lb-comment-details">
                                                <Suspense
                                                    fallback={
                                                        <div className="relative aspect-square w-8 flex-none animate-pulse rounded-full bg-gray-100" />
                                                    }
                                                >
                                                    <Avatar
                                                        user={
                                                            issueState.user_info
                                                        }
                                                    />
                                                </Suspense>
                                            </div>
                                        </div>
                                    )}
                                    <div
                                        className="flex flex-col gap-0.5"
                                        style={{ maxWidth: titleMaxWidth }}
                                    >
                                        <div className="flex items-center gap-2">
                                            {issueState.user_info.id !== "" ? (
                                                <div className="lb-comment-details-labels">
                                                    <MyUser
                                                        user={
                                                            issueState.user_info
                                                        }
                                                        className="lb-comment-author text-sm font-semibold"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="text-sm font-semibold overflow-hidden whitespace-nowrap text-ellipsis">
                                                    {issueState.title?.trim() ||
                                                        issueState.query}
                                                </div>
                                            )}
                                            <div className="flex-shrink-0 text-xs text-muted-foreground">
                                                {`${issueState.ticket_identifier}-${issueState.ticket_number}`}
                                            </div>
                                        </div>
                                        {issueState.user_info.id !== "" && (
                                            <div className="text-xs overflow-hidden whitespace-nowrap text-ellipsis">
                                                {issueState.title?.trim() ||
                                                    issueState.query}
                                            </div>
                                        )}
                                        {(issueState.source === "Slack" ||
                                            issueState.source ===
                                                "CommunitySlack") &&
                                        commentParsedTimestamp <
                                            parsedTimestampCutoff ? (
                                            <div className="text-xs text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                                <ReactMarkdown
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw]}
                                                >
                                                    {getHtmlStringFromReactContent(
                                                        parse(
                                                            toHTML(
                                                                cleanText(
                                                                    issueState.query ??
                                                                        "",
                                                                ),
                                                            ),
                                                        ),
                                                    )}
                                                </ReactMarkdown>
                                            </div>
                                        ) : (
                                            <div className="text-xs text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                                <ReactMarkdown
                                                    remarkPlugins={[remarkGfm]}
                                                    rehypePlugins={[rehypeRaw]}
                                                >
                                                    {cleanText(
                                                        issueState.query ?? "",
                                                    )}
                                                </ReactMarkdown>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center justify-end">
                                    {issue.breaching !== "" &&
                                        issue.breaching !== undefined &&
                                        issue.breaching !== null && (
                                            <Clock
                                                targetTime={issue.breaching}
                                                variant="soft"
                                            />
                                        )}
                                    <div className="relative flex-none">
                                        <div className="flex items-center gap-1 mx-1">
                                            {visibleTopics.map((topic) => (
                                                <Badge
                                                    color="gray"
                                                    size="2"
                                                    radius="full"
                                                    variant="outline"
                                                    className="m-0.5"
                                                    key={topic.value}
                                                >
                                                    <div className="flex flex-row items-center">
                                                        <DotFilledIcon
                                                            color={
                                                                topic.label !==
                                                                ""
                                                                    ? topic.label
                                                                    : "#9B9EF0"
                                                            }
                                                            style={{
                                                                transform:
                                                                    "scale(1.8)",
                                                            }}
                                                        />
                                                        <p className="pl-0.3">
                                                            {topic.value}
                                                        </p>
                                                    </div>
                                                </Badge>
                                            ))}
                                            {overflowTopics.size > 0 &&
                                                Array.from(
                                                    overflowTopics.entries(),
                                                ).map(([label, topics]) => {
                                                    return (
                                                        <Badge
                                                            color="gray"
                                                            size="2"
                                                            radius="full"
                                                            variant="outline"
                                                            className="m-0.5"
                                                            key={label}
                                                        >
                                                            <div className="flex flex-row items-center">
                                                                <DotFilledIcon
                                                                    color={
                                                                        label ??
                                                                        "#9B9EF0"
                                                                    }
                                                                    style={{
                                                                        transform:
                                                                            "scale(1.8)",
                                                                    }}
                                                                />
                                                                <div className="flex items-center pl-0.3">
                                                                    +
                                                                    {
                                                                        topics.length
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Badge>
                                                    );
                                                })}
                                        </div>
                                    </div>

                                    <Badge
                                        color="gray"
                                        size="2"
                                        radius="full"
                                        variant="outline"
                                        className="m-0.5"
                                    >
                                        <div className="flex flex-row items-center">
                                            <ComponentBooleanIcon
                                                color={getColorLight(
                                                    issueState.bot_category,
                                                )}
                                            />
                                            {showTagText && (
                                                <p className="pl-0.5">
                                                    {issueState.bot_category}
                                                </p>
                                            )}
                                        </div>
                                    </Badge>
                                    <div className="mx-1.5 text-xs w-15">
                                        {date}
                                    </div>
                                    {SourceSvgImage && (
                                        <SourceSvgImage className="w-5 h-5 mr-1" />
                                    )}
                                    {Array.from(externalIssuesIcons).map(
                                        (icon) =>
                                            React.createElement(
                                                icon.Component,
                                                icon.props,
                                            ),
                                    )}
                                    {issueState.assignee_user_id &&
                                    issueState.assignee_user_id !==
                                        "noAssignee" ? (
                                        <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                                            {pictureURL && (
                                                <img
                                                    className="lb-avatar-image"
                                                    src={pictureURL}
                                                    alt={userName}
                                                />
                                            )}
                                            <span>{userName ?? ""}</span>
                                        </div>
                                    ) : (
                                        <AvatarIcon className="w-6 h-6 mx-1" />
                                    )}
                                </div>
                            </div>
                        </button>
                    </ContextMenuTrigger>
                    <ContextMenuContent className="w-60">
                        <ContextMenuItem
                            inset
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                            onClick={(event) =>
                                handleOpenInNewTab(
                                    event,
                                    `${issueState.ticket_identifier}-${issueState.ticket_number}`,
                                )
                            }
                        >
                            <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                            Open in New Tab
                        </ContextMenuItem>
                        <ContextMenuItem
                            inset
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                        >
                            <DoubleArrowLeftIcon className="w-3.5 h-3.5" />
                            Back
                        </ContextMenuItem>
                        <ContextMenuItem
                            inset
                            onClick={handleReload}
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                        >
                            <ReloadIcon className="w-3 h-3" />
                            Reload
                        </ContextMenuItem>
                        <ContextMenuSeparator />
                        {dropdownOptions.map((type) => {
                            const options: {
                                label: string;
                                value: string;
                                color: string;
                            }[] = getOptions(type, topics, users, []) ?? [];
                            return (
                                <ContextMenuSub key={type}>
                                    <ContextMenuSubTrigger
                                        inset
                                        className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                    >
                                        {getIconForType(type)}
                                        {type}
                                    </ContextMenuSubTrigger>
                                    <ContextMenuSubContent className="w-55 max-h-60 overflow-y-auto text-xs">
                                        {options
                                            .sort((a, b) => {
                                                // Statuses has a specific priority order
                                                if (type === "Status") {
                                                    return (
                                                        statusPriorityOrder.indexOf(
                                                            a.value,
                                                        ) -
                                                        statusPriorityOrder.indexOf(
                                                            b.value,
                                                        )
                                                    );
                                                }
                                                const prioritizeLabel =
                                                    "No Assignee";
                                                if (
                                                    a.label ===
                                                        prioritizeLabel &&
                                                    b.label !== prioritizeLabel
                                                ) {
                                                    return -1;
                                                }
                                                if (
                                                    b.label ===
                                                        prioritizeLabel &&
                                                    a.label !== prioritizeLabel
                                                ) {
                                                    return 1;
                                                }
                                                // Default sorting by label if neither is prioritized
                                                return a.label.localeCompare(
                                                    b.label,
                                                );
                                            })
                                            .map((option) => (
                                                <ContextMenuItem
                                                    key={option.value}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        saveIssue(
                                                            type,
                                                            option.value,
                                                            api,
                                                            issueState,
                                                            updateIssueState,
                                                            userID,
                                                        );
                                                    }}
                                                    className="flex items-center justify-between text-xs rounded-md text-gray-700 hover:bg-gray-100 hover:text-gray-950 w-full px-3 py-1.5"
                                                >
                                                    {ElementDisplay(
                                                        type,
                                                        option.label,
                                                        option.value,
                                                        option.color,
                                                        filters,
                                                        users,
                                                        [],
                                                    )}
                                                </ContextMenuItem>
                                            ))}
                                    </ContextMenuSubContent>
                                </ContextMenuSub>
                            );
                        })}
                    </ContextMenuContent>
                </ContextMenu>
            </CardContent>
        </Card>
    );
}

export default memo(IssuesListCard, areEqual);
