import { Card } from "@/component/shadcn/ui/card";
import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import type {
    CustomerGroup,
    CustomerGroupMetadata,
    GetTopicsResponse,
    GetUserResponse,
    Query,
} from "@/interfaces/serverData";
import type { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import { arraysAreEqual } from "@/utilities/methods";
import type { UseQueryResult } from "@tanstack/react-query";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VariableSizeList } from "react-window";
import FilterBar from "./FilterBar";
import IssuesListCard from "./IssuesListCard";
import { IssuesListHeaderCard } from "./IssuesListHeaderCard";
import { HeaderType } from "./constants";

const areEqual = (prevProps: IssuesListProps, nextProps: IssuesListProps) => {
    return (
        arraysAreEqual(prevProps.issues, nextProps.issues) &&
        arraysAreEqual(prevProps.topics, nextProps.topics) &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.userID === nextProps.userID &&
        arraysAreEqual(
            prevProps.usersQuery.data ?? [],
            nextProps.usersQuery.data ?? [],
        ) &&
        arraysAreEqual(
            prevProps.customerGroupsQuery.data ?? [],
            nextProps.customerGroupsQuery.data ?? [],
        ) &&
        prevProps.aiTaggingEnabled === nextProps.aiTaggingEnabled &&
        prevProps.onFilterChange === nextProps.onFilterChange &&
        prevProps.onGroupChange === nextProps.onGroupChange &&
        prevProps.inputFilters === nextProps.inputFilters &&
        prevProps.inputGrouping === nextProps.inputGrouping &&
        prevProps.listType === nextProps.listType &&
        prevProps.viewId === nextProps.viewId
    );
};

export enum Grouping {
    Status = "ticket_status",
    Owner = "assignee_user_id",
    Source = "source",
    None = "None",
}

interface IssuesListProps {
    issues: Query[];
    topics: { color: string; label: string; value: string }[];
    topicsMap: Map<string, GetTopicsResponse>;
    userID: string;
    listType: IssueListType;
    usersQuery: UseQueryResult<GetUserResponse[], Error>;
    customerGroupsQuery: UseQueryResult<CustomerGroup[], Error>;
    aiTaggingEnabled: boolean;
    onFilterChange?: (filters: Map<string, Set<string>>) => void; // New prop
    onGroupChange?: (grouping: Grouping) => void; // New prop
    inputFilters?: Map<string, Set<string>>;
    inputGrouping?: string;
    viewId?: string;
    listHeight?: number;
}

function IssuesList({
    issues,
    topics,
    topicsMap,
    userID,
    listType,
    usersQuery,
    customerGroupsQuery,
    aiTaggingEnabled,
    onFilterChange,
    onGroupChange,
    inputFilters,
    inputGrouping,
    viewId,
    listHeight,
}: IssuesListProps) {
    const [filters, setFilters] = useState<Map<string, Set<string>>>(
        inputFilters ?? new Map(),
    );
    const [searchQuery, setSearchQuery] = useState<string>("");
    // Default: Grouping by status
    const [grouping, setGrouping] = useState<Grouping>(
        (inputGrouping as Grouping) ?? Grouping.Status,
    );
    const [groupingExpanded, setGroupingExpanded] = useState<
        Map<string, boolean>
    >(new Map());
    const [issuesByGroup, setIssuesByGroup] = useState<Map<string, Query[]>>(
        new Map(),
    );
    const listRef = useRef<VariableSizeList>(null);

    const updateFilters = (newFilters: Map<string, Set<string>>) => {
        setFilters(newFilters);
        if (onFilterChange) {
            onFilterChange(newFilters); // Pass filters up to parent
        }
    };

    const updateGrouping = (newGrouping: Grouping) => {
        setGrouping(newGrouping);
        if (onGroupChange) {
            onGroupChange(newGrouping); // Pass grouping up to parent
        }
    };

    useEffect(() => {
        setFilters(inputFilters ?? new Map());
    }, [viewId]);

    useEffect(() => {
        const result = new Map<string, Query[]>();
        const mustKeys = ["Breaching", "NeedsResponse", "Open", "Closed"];

        // Initialize with empty arrays for mustKeys if grouping by Status
        if (grouping === Grouping.Status) {
            // biome-ignore lint/complexity/noForEach: <explanation>
            mustKeys.forEach((key) => {
                result.set(key, []);
            });
        }

        // Filter issues based on the current filters and search query
        const visibleIssues = issues.filter((issue) => {
            let matchesFilters = true;
            const entries = Array.from(filters.entries());
            for (const [type, values] of entries) {
                matchesFilters =
                    matchesFilters && shouldInclude(type, values, issue);
            }

            // Check if the issue matches the search query
            const matchesSearch =
                searchQuery === "" ||
                issue.title
                    ?.toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                issue.query
                    ?.toLowerCase()
                    .includes(searchQuery.toLowerCase()) ||
                `${issue.ticket_identifier}-${issue.ticket_number}`
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase());

            return matchesFilters && matchesSearch;
        });

        for (const issue of visibleIssues) {
            // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
            let groupVal;

            // Determine group value based on current grouping
            if (grouping === Grouping.Owner) {
                const assignedUser = usersQuery.data?.find(
                    (user) => user.id === issue.assignee_user_id,
                );
                groupVal = assignedUser
                    ? `${assignedUser.first_name} ${assignedUser.last_name}`
                    : "Unassigned";
            } else {
                groupVal =
                    grouping === Grouping.None
                        ? "All"
                        : issue[grouping as keyof Query];
            }

            const groupKey = groupVal ? groupVal.toString() : "None";
            if (!result.has(groupKey)) {
                result.set(groupKey, [issue]);
            } else {
                result.get(groupKey)?.push(issue);
            }
        }

        setIssuesByGroup(result);

        // Initialize groupingExpanded
        const newGroupingExpanded = new Map<string, boolean>();
        result.forEach((_, key) => {
            newGroupingExpanded.set(key, true);
        });
        setGroupingExpanded(newGroupingExpanded);
    }, [grouping, issues, usersQuery.data, filters, searchQuery]);

    function shouldInclude(
        type: string,
        values: Set<string>,
        issue: Query,
    ): boolean {
        switch (type) {
            case "Source": {
                return !!(issue.source && values.has(issue.source));
            }
            case "Tag": {
                return !!values.has(issue.bot_category);
            }
            case "Topic": {
                for (const topic of issue.user_added_topic) {
                    if (values.has(topic)) {
                        return true; // At least one topic is in the filter set
                    }
                }
                for (const topic of issue.topic) {
                    if (values.has(topic)) {
                        return true; // At least one topic is in the filter set
                    }
                }
                return false;
            }
            case "Status": {
                return !!values.has(issue.ticket_status);
            }
            case "Assignee": {
                return !!(
                    (!issue.assignee_user_id && values.has("noAssignee")) ||
                    (issue.assignee_user_id &&
                        values.has(issue.assignee_user_id))
                );
            }
            case "Assembly Bot": {
                return !!issue.assembly_responded; // Convert to boolean
            }
            case "Customer Group": {
                // Will only contain channels from slack, discord, and community slack
                const channel = issue.source_specific_id.split("_")[0];
                const customerGroups = customerGroupsQuery.data ?? [];
                for (const value of Array.from(values)) {
                    const cg = customerGroups.find(
                        (elem) => elem.group_name === value,
                    );
                    let slackChannels: string[] = [];
                    let discordChannels: string[] = [];
                    let communitySlackChannels: string[] = [];
                    if (cg?.metadata) {
                        const metadata: CustomerGroupMetadata = JSON.parse(
                            cg?.metadata ?? "",
                        );
                        slackChannels =
                            metadata.Slack?.map((elem) => elem.key ?? "") ?? [];
                        discordChannels =
                            metadata.Discord?.map((elem) => elem.key ?? "") ??
                            [];
                        communitySlackChannels =
                            metadata.CommunitySlack?.map(
                                (elem) => elem.key ?? "",
                            ) ?? [];
                        switch (issue.source) {
                            case "Slack": {
                                return slackChannels.includes(channel);
                            }
                            case "Discord": {
                                return discordChannels.includes(channel);
                            }
                            case "CommunitySlack": {
                                return communitySlackChannels.includes(channel);
                            }
                            default: {
                                return false;
                            }
                        }
                    } else if (cg?.group_name === "All Slack Accounts") {
                        return issue.source === "Slack";
                    } else if (cg?.group_name === "All Discord Accounts") {
                        return issue.source === "Discord";
                    } else if (
                        cg?.group_name === "All Community Slack Accounts"
                    ) {
                        return issue.source === "CommunitySlack";
                    }
                }
                return true;
            }
            default: {
                return false; // Handle any unmatched case
            }
        }
    }

    const updateExpanded = useCallback((groupKey: string, value: boolean) => {
        setGroupingExpanded((prevMap) => {
            const newMap = new Map(prevMap);
            newMap.set(groupKey, value);
            return newMap;
        });
    }, []);

    const headerHeight = 35;
    const itemHeight = 80;

    const getItemSize = useCallback(
        (index: number) => {
            const groupKeys = Array.from(issuesByGroup.keys());
            let currentIndex = 0;
            for (const groupKey of groupKeys) {
                if (index === currentIndex) {
                    return headerHeight;
                }
                currentIndex++;
                if (groupingExpanded.get(groupKey)) {
                    const groupSize = issuesByGroup.get(groupKey)?.length || 0;
                    if (index < currentIndex + groupSize) {
                        return itemHeight;
                    }
                    currentIndex += groupSize;
                }
            }
            return 0;
        },
        [issuesByGroup, groupingExpanded],
    );

    const listItems = useMemo(() => {
        const items: { key: string; content: JSX.Element }[] = [];
        issuesByGroup.forEach((groupIssues, groupKey) => {
            // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
            let headerKey;
            if (
                ["Closed", "NeedsResponse", "Open", "Breaching"].includes(
                    groupKey,
                )
            ) {
                headerKey = HeaderType.Status;
            } else {
                headerKey = HeaderType.Other;
            }
            items.push({
                key: `header-${groupKey}`,
                content: (
                    <IssuesListHeaderCard
                        key={`header-${
                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                            groupKey
                        }`}
                        title={groupKey}
                        value={groupKey}
                        header_type={headerKey}
                        isExpanded={groupingExpanded}
                        updateExpanded={updateExpanded}
                    />
                ),
            });

            if (groupingExpanded.get(groupKey)) {
                // biome-ignore lint/complexity/noForEach: <explanation>
                groupIssues.forEach((issue) => {
                    items.push({
                        key: issue.id,
                        content: (
                            <IssuesListCard
                                key={issue.id}
                                issue={issue}
                                topics={topics}
                                topicsMap={topicsMap}
                                userID={userID}
                                users={usersQuery.data ?? []}
                                aiTaggingEnabled={aiTaggingEnabled}
                                listType={listType}
                            />
                        ),
                    });
                });
            }
        });
        return items;
    }, [
        issuesByGroup,
        groupingExpanded,
        updateExpanded,
        topics,
        topicsMap,
        userID,
        usersQuery.data,
        listType,
    ]);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [grouping, groupingExpanded]);

    return (
        <Card className="m-2 rounded-sm h-full w-full flex flex-col">
            <FilterBar
                issues={issues}
                setFilters={updateFilters}
                filters={filters}
                topics={topics}
                users={usersQuery.data ?? []}
                customerGroups={customerGroupsQuery.data ?? []}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                savedViewFilters={inputFilters ?? new Map()}
            />
            <ScrollArea className="h-full w-full">
                <VariableSizeList
                    ref={listRef}
                    width={"100%"}
                    height={listHeight ?? window.innerHeight * 0.73} // Adjust based on your layout
                    itemCount={listItems.length}
                    itemSize={getItemSize}
                    overscanCount={5}
                >
                    {({ index, style }) => {
                        const item = listItems[index];
                        return (
                            <div style={style} key={item.key}>
                                {item.content}
                            </div>
                        );
                    }}
                </VariableSizeList>
            </ScrollArea>
        </Card>
    );
}

export default memo(IssuesList, areEqual);
