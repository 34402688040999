import { CreateInsightPopup } from "@/Insights/CreateInsightPopup";
import GenerateInsights from "@/Insights/GenerateInsights";
import SavedInsightsList from "@/Insights/SavedInsightsList";
import SuggestedInsightsList from "@/Insights/SuggestedInsightsList";
import TopInsights from "@/Insights/TopInsights";
import { useInsightsData } from "@/Insights/useInsightsData";
import { Button } from "@/component/shadcn/ui/button";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetTopicsResponse,
    GetUserResponse,
    QueriesWithPaginationResponse,
} from "@/interfaces/serverData";
import { DoubleArrowLeftIcon } from "@radix-ui/react-icons";
import { Box, Callout, Flex, Heading, Skeleton, Text } from "@radix-ui/themes";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";

interface AdminInsightPageProps {
    userID: string;
}

const AdminInsightsPage: React.FC<AdminInsightPageProps> = ({ userID }) => {
    const api = useApi();
    const [topicsMap, setTopicsMap] = useState<Map<string, GetTopicsResponse>>(
        new Map(),
    );
    const [topics, setTopics] = useState<
        { color: string; label: string; value: string }[]
    >([]);
    const [loadingTopicsState, setLoadingTopicsState] = useState<number>(0);

    const [users, setUsers] = useState<GetUserResponse[]>([]);

    const [onDashboard, setOnDashboard] = useState<boolean>(true);
    const [generatedIsOpen, setGeneratedIsOpen] = useState<boolean>(true);

    const [playgroundIsOpen, setPlaygroundIsOpen] = useState<boolean>(true);
    const [playgroundFilters, setPlaygroundFilters] = useState<
        Map<string, Set<string>>
    >(new Map());
    const [dateRange, setDateRange] = useState<DateRange>()
    const [suggestedIsOpen, setSuggestedIsOpen] = useState<boolean>(true);

    const {
        loadingState,
        aiInsights,
        topTopics,
        insightStates,
        handleSaveAIGeneratedInsight,
        updateInsightState,
        queryClient,
        generatedInsights,
        setGeneratedInsights,
    } = useInsightsData(userID);

    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        limit: 1000,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ["queries"],
        queryFn: fetchQueries,
        getNextPageParam: (lastPage) => {
            if (lastPage.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                .filter((page) => page !== null && page !== undefined)
                .flatMap((page) =>
                    Array.isArray(page.data)
                        ? page.data.filter(
                            (item) => item !== null && item !== undefined,
                        )
                        : [],
                ) // Filter out null or undefined items in page.data
            : [];

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    // Grab the list of topics for the org
    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: {
                        color: string;
                        label: string;
                        value: string;
                    }[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setTopics(newTopics);
                    setLoadingTopicsState(1);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    // Grab the users for the org
    useEffect(() => {
        api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.data);
            } else {
                console.log("Call to grab users failed");
            }
        });
    }, [api]);

    // Reset to dashboard page on reload
    useEffect(() => {
        setOnDashboard(true);
    }, []);

    return (
        <div>
            <Flex direction="column" align="center" justify="center">
                <Box mt="7" height="100%" width="98%">
                    <Flex
                        align="start"
                        direction="column"
                        justify={"start"}
                        style={{ paddingLeft: "20px" }}
                    >
                        <Heading size="5" align="left">
                            Product Insights
                        </Heading>
                        <Text mb="12px" size="2">
                            Navigate insights gathered from the issues. Create
                            your own insights or build off of our AI Generated
                            insights. To save an AI generated insight, press the
                            bookmark icon.
                        </Text>
                    </Flex>
                    <div className="flex items-center absolute top-4 right-4 my-4">
                        <CreateInsightPopup
                            userID={userID}
                            queryClient={queryClient}
                            issues={combinedData}
                        />
                    </div>
                    {loadingState === 0 && (
                        <Flex maxWidth="85%" style={{ paddingLeft: "20px" }}>
                            <Text>
                                <Skeleton maxWidth="85%">
                                    {[...Array(6)].map((_, index) => (
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        <Text key={index}>{loremIpsum}</Text>
                                    ))}
                                </Skeleton>
                            </Text>
                        </Flex>
                    )}
                    {loadingState === 1 &&
                        loadingTopicsState === 1 &&
                        (onDashboard ? (
                            <div className="flex flex-col gap-2 px-2">
                                <TopInsights
                                    suggestedInsights={aiInsights}
                                    issuesCount={combinedData.length}
                                    userID={userID}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                    updateInsightState={updateInsightState}
                                    topicsMap={topicsMap}
                                    setOnDashboard={setOnDashboard}
                                    generatedIsOpen={generatedIsOpen}
                                    setGeneratedIsOpen={setGeneratedIsOpen}
                                />
                                <SavedInsightsList
                                    savedInsights={Array.from(
                                        insightStates.values(),
                                    )}
                                    userID={userID}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                    topicsMap={topicsMap}
                                    generatedIsOpen={generatedIsOpen}
                                />
                            </div>
                        ) : (
                            <div className="flex flex-col gap-2 px-2">
                                <div className="flex justify-start">
                                    <Button
                                        onClick={() => setOnDashboard(true)}
                                        variant="ghost"
                                        className="flex items-center justify-start gap-1 hover:bg-background text-gray-600 hover:text-gray-900 text-[12px] -mb-2 -mt-1"
                                    >
                                        <DoubleArrowLeftIcon />
                                        Back to Saved Insights
                                    </Button>
                                </div>
                                <GenerateInsights
                                    savedInsights={Array.from(
                                        insightStates.values(),
                                    )}
                                    userID={userID}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                    topicsMap={topicsMap}
                                    topics={topics}
                                    users={users}
                                    generatedInsights={generatedInsights}
                                    setGeneratedInsights={setGeneratedInsights}
                                    filters={playgroundFilters}
                                    setFilters={setPlaygroundFilters}
                                    isOpen={playgroundIsOpen}
                                    setIsOpen={setPlaygroundIsOpen}
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                    suggestedIsOpen={suggestedIsOpen}
                                />
                                <SuggestedInsightsList
                                    suggestedInsights={aiInsights}
                                    generatedInsights={generatedInsights}
                                    issuesCount={combinedData.length}
                                    userID={userID}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                    topicsMap={topicsMap}
                                    isOpen={suggestedIsOpen}
                                    setIsOpen={setSuggestedIsOpen}
                                    playgroundIsOpen={playgroundIsOpen}
                                />
                            </div>
                        ))}
                    {loadingState === 2 && (
                        <Callout.Root size="1" variant="outline" color="red">
                            <Callout.Text>
                                Sorry, something's wrong! Please notify us at
                                support@askassembly.app.
                            </Callout.Text>
                        </Callout.Root>
                    )}
                </Box>
            </Flex>
        </div>
    );
};

export default AdminInsightsPage;
