import { Card, CardContent } from "@/component/shadcn/ui/card";
import { API, URLS, loadingTypes } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { ScopeResponse } from "@/interfaces/serverData";
import { CrossCircledIcon, Pencil2Icon } from "@radix-ui/react-icons";
import {
    Badge,
    Button,
    DataList,
    Dialog,
    Flex,
    Select,
    Skeleton,
    Switch,
    Text,
} from "@radix-ui/themes";
import type React from "react";
import { useState, useEffect } from "react";
import { ReactComponent as SlackSvg } from "../../images/integrations/slack.svg";
import { useQuery } from "@tanstack/react-query";

interface SlackAlertsProps {
    isOpen: boolean;
    onOpenChange: (open: boolean) => void;
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    timerRef: React.MutableRefObject<number>;
}

const SlackAlerts: React.FC<SlackAlertsProps> = ({
    isOpen,
    onOpenChange,
    setToastOpen,
    setToastSymbol,
    setToastText,
    timerRef,
}) => {
    const [slackChannel, setSlackChannel] = useState<ScopeResponse | undefined>(undefined);
    const [slackEnabled, setSlackEnabled] = useState<boolean>(false);
    const [savedSlackEnabledValue, setSavedSlackEnabledValue] = useState<boolean>(false);
    const [savedSlackChannel, setSavedSlackChannel] = useState<ScopeResponse | undefined>(undefined);
    const [loading, setLoading] = useState<loadingTypes>(loadingTypes.loading);

    const api = useApi();

    // Fetch the Slack scopes using useQuery
    const {
        data: scopeData,
        isError: scopeError,
        isLoading: scopeLoading,
    } = useQuery({
        queryKey: ['fetchScopes'],
        queryFn: async () => {
            const response = await api.post(`${URLS.serverUrl}${API.getScopes}`, { type: 'Slack' }, {
                headers: { 'Content-Type': 'application/json' }
            });
            return response.data.data;
        },
    });

    // Fetch the Slack notification settings using useQuery
    const {
        data: slackData,
        isError: slackError,
        isLoading: slackLoading,
    } = useQuery({
        queryKey: ['fetchSlackSettings'],
        queryFn: async () => {
            const response = await api.get(`${URLS.serverUrl}${API.getNotifications}/Slack`, {
                headers: { Accept: 'application/json' }
            });
            return response.data.data;
        },
    });

    useEffect(() => {
        if (slackData) {
            // Update the state with the Slack notification settings
            setSlackEnabled(slackData.enabled);
            setSavedSlackEnabledValue(slackData.enabled);
            setSlackChannel(slackData.channel);
            setSavedSlackChannel(slackData.channel);
            setLoading(loadingTypes.loaded);
        } else if (slackError) {
            // Handle the error state for Slack settings
            setLoading(loadingTypes.error);
        }
    }, [slackData, slackError]);

    const channelOptions = scopeData || [];

    function saveSettings() {
        const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
        const requestData = {
            enabled: slackEnabled,
            channel: slackChannel, // Send full object here
        };
        api.post(`${serverUrl}${API.saveNotificationSettings}/Slack`, requestData, {
            headers: { "Content-Type": "application/json" }
        })
            .then((res) => {
                if (res.status === 200) {
                    const slackResData = res.data.data;
                    setSlackEnabled(slackResData.enabled);
                    setSavedSlackEnabledValue(slackResData.enabled);
                    setSavedSlackChannel(slackResData.channel);
                    setLoading(loadingTypes.loaded);
                    setToastText("Updated Settings!");
                } else {
                    setToastText("Oops! Something's wrong. Please try again at a later time.");
                    setToastSymbol(CrossCircledIcon);
                }
            })
            .catch((res) => {
                setLoading(loadingTypes.error);
                setToastText("Oops! Something's wrong. Please try again at a later time.");
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setToastOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    }

    return (
        <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
            <Card className="pt-6 pb-6 px-6">
                <CardContent>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-row items-center justify-between">
                            <div className="flex flex-row items-center gap-1">
                                <SlackSvg className="max-h-5 max-w-5" />
                                <h2 className="text-md font-medium">Slack Notifications</h2>
                            </div>

                            <Dialog.Trigger className="self-end justify-self-end">
                                <Button className="iris text-white">
                                    <Pencil2Icon />
                                    Edit
                                </Button>
                            </Dialog.Trigger>
                        </div>
                        {savedSlackEnabledValue && savedSlackChannel && (
                            <Card className="mx-6">
                                <CardContent className="py-6 px-6 ">
                                    <DataList.Root>
                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">Status</DataList.Label>
                                            <DataList.Value>
                                                <Badge color="jade" variant="soft" radius="full">
                                                    Enabled
                                                </Badge>
                                            </DataList.Value>
                                        </DataList.Item>
                                        <DataList.Item align="center">
                                            <DataList.Label minWidth="88px">Notification Channel</DataList.Label>
                                            <DataList.Value>
                                                <p className="text-iris11">{`#${savedSlackChannel.name}`}</p>
                                            </DataList.Value>
                                        </DataList.Item>
                                    </DataList.Root>
                                </CardContent>
                            </Card>
                        )}
                    </div>
                </CardContent>
            </Card>

            <Dialog.Content style={{ maxWidth: 450 }}>
                <Dialog.Title>Slack Notification Settings</Dialog.Title>
                <Flex direction="column" gap="4">
                    <Flex align="center" justify="between">
                        <Text>Enable Slack Notifications</Text>
                        <Switch
                            checked={slackEnabled}
                            onCheckedChange={setSlackEnabled}
                        />
                    </Flex>
                    {slackEnabled && (scopeLoading || slackLoading) && (
                        <Skeleton>
                            <Select.Root>
                                <Select.Trigger className="w-full" placeholder="Select Slack channel" />
                                <Select.Content />
                            </Select.Root>
                        </Skeleton>
                    )}

                    {slackEnabled && !scopeLoading && !slackLoading && (
                        <Select.Root
                            value={slackChannel?.name}
                            onValueChange={(name) => {
                                const selectedChannel = channelOptions.find(
                                    (channel) => channel.name === name
                                );
                                setSlackChannel(selectedChannel);
                            }}
                        >
                            <Select.Trigger className="w-full" placeholder="Select Slack channel" />
                            <Select.Content>
                                {channelOptions.map((channel) => (
                                    <Select.Item key={channel.key} value={channel.name}>
                                        #{channel.name}
                                    </Select.Item>
                                ))}
                            </Select.Content>
                        </Select.Root>
                    )}

                    {loading === loadingTypes.loaded && (
                        <Flex justify="end" gap="3" mt="4">
                            <Dialog.Close>
                                <Button className="iris text-white" onClick={saveSettings}>
                                    Save
                                </Button>
                            </Dialog.Close>
                        </Flex>
                    )}
                </Flex>
            </Dialog.Content>
        </Dialog.Root>
    );
};

export default SlackAlerts;
