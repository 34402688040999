import { TriangleDownIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { AnimatePresence, motion } from "framer-motion";

export const DropdownSection = ({
    title,
    expanded,
    setExpanded,
    children,
}: {
    title: string;
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
    children: React.ReactNode;
}) => {
    const contentVariants = {
        expanded: {
            opacity: 1,
            height: "auto",
            transition: {
                height: {
                    duration: 0.3,
                    ease: [0.04, 0.62, 0.23, 0.98],
                },
                opacity: { duration: 0.25, delay: 0.1 },
            },
        },
        collapsed: {
            opacity: 0,
            height: 0,
            transition: {
                height: {
                    duration: 0.3,
                    ease: [0.04, 0.62, 0.23, 0.98],
                },
                opacity: { duration: 0.2 },
            },
        },
    };

    return (
        <div className="w-full overflow-hidden">
            <motion.button
                className="flex items-center w-full px-5 py-2 text-xs font-bold text-gray-500 transition-colors"
                onClick={() => setExpanded(!expanded)}
                initial={false}
            >
                {title}
                <motion.div
                    initial="collapsed"
                    animate={expanded ? "expanded" : "collapsed"}
                    variants={IconVariants}
                >
                    {expanded ? (
                        <TriangleRightIcon className="h-4 w-4" />
                    ) : (
                        <TriangleDownIcon className="h-4 w-4" />
                    )}
                </motion.div>
            </motion.button>
            <AnimatePresence initial={false}>
                {expanded && (
                    <motion.div
                        key="content"
                        initial="collapsed"
                        animate="expanded"
                        exit="collapsed"
                        variants={contentVariants}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export const IconVariants = {
    expanded: {
        rotate: 90,
        transition: { duration: 0.3, ease: "easeInOut" },
    },
    collapsed: {
        rotate: 0,
        transition: { duration: 0.3, ease: "easeInOut" },
    },
};
export const DropdownSectionButton = ({
    parentButton,
    children,
    expanded,
    setExpanded,
}: {
    parentButton: React.ReactNode;
    children: React.ReactNode;
    expanded: boolean;
    setExpanded: (expanded: boolean) => void;
}) => {
    const contentVariants = {
        expanded: {
            opacity: 1,
            height: "auto",
            transition: {
                height: {
                    duration: 0.3,
                    ease: [0.04, 0.62, 0.23, 0.98],
                },
                opacity: { duration: 0.25, delay: 0.1 },
            },
        },
        collapsed: {
            opacity: 0,
            height: 0,
            transition: {
                height: {
                    duration: 0.3,
                    ease: [0.04, 0.62, 0.23, 0.98],
                },
                opacity: { duration: 0.2 },
            },
        },
    };

    return (
        <>
            <motion.button
                className="transition-colors w-full"
                onClick={() => {
                    setExpanded(!expanded);
                }}
                initial={false}
            >
                {parentButton}
            </motion.button>
            <AnimatePresence initial={false}>
                {expanded && (
                    <motion.div
                        key="content"
                        initial="collapsed"
                        animate="expanded"
                        exit="collapsed"
                        variants={contentVariants}
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};
