import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbSeparator,
} from "@/component/shadcn/ui/breadcrumb";
import { Button } from "@/component/shadcn/ui/button";
import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/component/shadcn/ui/resizable";

import * as ToggleGroup from "@radix-ui/react-toggle-group";

import {
    TooltipContent,
    Tooltip as TooltipRoot,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { TooltipProvider } from "@/component/shadcn/ui/tooltip";

import { ticketStatus } from "@/IssuesTable/constants";
import { Card } from "@/component/shadcn/ui/card";
import type { HistoryResponse, Ticket } from "@/interfaces/serverData";
import { IssueListType } from "@/pages/Admin/AdminQueriesPage";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { arraysAreEqual } from "@/utilities/methods";
import { RoomProvider } from "@liveblocks/react/suspense";
import { useAuthInfo } from "@propelauth/react";
import {
    ArrowLeftIcon,
    ArrowRightIcon,
    CalendarIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    CopyIcon,
    Link2Icon,
} from "@radix-ui/react-icons";
import { Badge, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { Separator } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { CommandIcon } from "lucide-react";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SlackSvg } from "../images/integrations/slack.svg";
import { Avatar } from "./Avatar";
import Room from "./Room";
import SidebarCard from "./SidebarCard";
import { MyUser } from "./User";

function processTitle(ticket: Ticket): string {
    // Older tickets don't have a generated title, just use the query
    if (ticket.title == null) {
        return ticket.query;
    } else if (ticket.title.startsWith('"') && ticket.title.endsWith('"')) {
        // If the generated title is surrounded by quotes, remove the quotes
        return ticket.title.slice(1, -1);
    } else {
        return ticket.title;
    }
}

const areEqual = (prevProps: TicketProps, nextProps: TicketProps) => {
    return (
        arraysAreEqual(prevProps.threadData, nextProps.threadData) &&
        prevProps.refetchTicketData === nextProps.refetchTicketData &&
        prevProps.refetchThreadData === nextProps.refetchThreadData &&
        prevProps.loadingThreadState === nextProps.loadingThreadState &&
        prevProps.identifier === nextProps.identifier &&
        prevProps.ticket === nextProps.ticket &&
        prevProps.userID === nextProps.userID &&
        prevProps.ai_response === nextProps.ai_response &&
        prevProps.listType === nextProps.listType
    );
};

interface TicketProps {
    threadData: HistoryResponse[];
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    loadingThreadState: number;
    identifier: string;
    ticket: Ticket;
    userID: string;
    ai_response: string;
    listType: IssueListType;
    moveToNextTicket: () => void;
    moveToPreviousTicket: () => void;
    saveStatus: (status: string) => void;
}

function TicketDisplay({
    threadData,
    refetchTicketData,
    refetchThreadData,
    loadingThreadState,
    identifier,
    ticket,
    userID,
    ai_response,
    listType,
    moveToNextTicket,
    moveToPreviousTicket,
    saveStatus,
}: TicketProps) {
    const authInfo = useAuthInfo();
    const [ticketThread, setTicketThread] = useState<HistoryResponse[]>([]);

    const toggleGroupItemClasses =
        "hover:bg-gray-100 active:bg-gray-200 text-xs flex outline outline-1 outline-gray-300 h-[18px] w-[30px] items-center justify-center text-base leading-4 first:rounded-l last:rounded-r focus:z-10 focus:bg-iris3";

    useEffect(() => {
        if (threadData && threadData.length === 0) {
            const message: HistoryResponse[] = [
                {
                    id: ticket.id,
                    content: ticket.query,
                    source: ticket.source ?? "",
                    timestamp: ticket.created_at,
                    type: "Message",
                    metadata: "",
                    user_data: {
                        name: "Anonymous",
                        id: "Anonymous",
                    },
                    reactions: [],
                    files: [],
                },
            ];
            setTicketThread(message);
        } else {
            setTicketThread(threadData);
        }
    }, [threadData]);
    const [update, setUpdate] = useState<boolean>(false);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false); // State for tooltip visibility

    const [animate, setAnimate] = useState<string | null>(null);
    const [attributesIsOpen, setAttributesIsOpen] = useState<boolean>(true);
    const [labelsIsOpen, setLabelsIsOpen] = useState<boolean>(true);
    const [customerIsOpen, setCustomerIsOpen] = useState<boolean>(true);
    const [extIssuesIsOpen, setExtIssuesIsOpen] = useState<boolean>(false);
    const [insightsIsOpen, setInsightsIsOpen] = useState<boolean>(false);
    const [noteIsOpen, setNoteIsOpen] = useState<boolean>(false);

    const triggerAnimation = useCallback((animationName: string): void => {
        setAnimate(animationName);
        setTimeout(() => setAnimate(null), 200); // Reset after animation duration
    }, []);

    const copyIdentifier = useCallback((): void => {
        navigator.clipboard.writeText(identifier);
        triggerAnimation("scale-on-click");
        setTooltipOpen(false);
    }, [identifier]);

    const copyLink = useCallback((): void => {
        navigator.clipboard.writeText(
            `${process.env.REACT_APP_CALLBACK_URL}issue/${identifier}`,
        );
        triggerAnimation("scale-on-click");
        setTooltipOpen(false);
    }, [identifier]);

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";

    const source: string = ticket.source ?? "";
    const SourceSvgImage = useMemo(
        () => integrationBackEndDataMappingToSvg.get(source) ?? SlackSvg,
        [source],
    );
    const created_date = useMemo(() => {
        try {
            const d = new Date(ticket.created_at);
            return d.toLocaleDateString();
        } catch (err) {
            console.log(
                `Could not convert ticket's created at timestamp ${ticket.created_at} to a valid date, so using the original timestamp format. Error: ${err}`,
            );
            return ticket.created_at;
        }
    }, [ticket.created_at]);
    // User info of first message in the ticket
    const customerUserInfo = useMemo(() => {
        return threadData.length > 0 ? threadData[0].user_data : null;
    }, [threadData]);

    const [orgId, setOrgId] = useState<string>("");
    const navigate = useNavigate();

    useEffect(() => {
        const orgIds = authInfo.orgHelper?.getOrgIds();
        if (orgIds === undefined || orgIds.length !== 1) {
            navigate("/*");
            return;
        }
        setOrgId(orgIds[0]);
    }, [authInfo.orgHelper, navigate]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.metaKey && event.key === "ArrowRight") {
                moveToNextTicket();
            } else if (event.metaKey && event.key === "ArrowLeft") {
                moveToPreviousTicket();
            } else if (
                event.metaKey &&
                (event.key === "e" || event.key === "E")
            ) {
                saveStatus(ticketStatus.Closed);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [moveToNextTicket, moveToPreviousTicket, saveStatus]);

    const sidebarLabels = "font-medium text-xs text-gray9";
    return (
        <div className="bg-muted overflow-hidden min-h-screen">
            <div className="h-screen overflow-hidden">
                <TooltipProvider delayDuration={0}>
                    <ResizablePanelGroup
                        direction="horizontal"
                        onLayout={(sizes: number[]) => {
                            document.cookie = `react-resizable-panels:layout=${JSON.stringify(
                                sizes,
                            )}`;
                        }}
                        className="h-fit items-stretch"
                    >
                        <ResizablePanel
                            defaultSize={window.innerWidth * 0.75}
                            key="ticketInfo"
                            className="flex flex-col"
                        >
                            <Card className="bg-white m-3 shadow-sm border rounded-lg relative flex flex-col px-3 py-2">
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center py-0.5 gap-3 pl-3">
                                        <Breadcrumb>
                                            <BreadcrumbList className="flex items-center pl-2">
                                                <BreadcrumbItem>
                                                    <BreadcrumbLink
                                                        href={
                                                            listType ===
                                                            IssueListType.Inbox
                                                                ? "/inbox"
                                                                : "/issues"
                                                        }
                                                    >
                                                        {listType ===
                                                        IssueListType.Inbox
                                                            ? "Inbox"
                                                            : "Interactions"}
                                                    </BreadcrumbLink>
                                                </BreadcrumbItem>
                                                <BreadcrumbSeparator className="mx-2" />
                                                <TooltipProvider>
                                                    <TooltipRoot
                                                        open={tooltipOpen}
                                                        onOpenChange={
                                                            setTooltipOpen
                                                        }
                                                    >
                                                        <TooltipTrigger asChild>
                                                            <BreadcrumbItem
                                                                onMouseEnter={() =>
                                                                    setTooltipOpen(
                                                                        true,
                                                                    )
                                                                }
                                                            >
                                                                <BreadcrumbLink className="hover:underline">
                                                                    {processTitle(
                                                                        ticket,
                                                                    )}
                                                                </BreadcrumbLink>
                                                            </BreadcrumbItem>
                                                        </TooltipTrigger>
                                                        <TooltipContent className="bg-white text-black p-4 rounded shadow-lg">
                                                            <div className="flex flex-col gap-2">
                                                                <Button
                                                                    size="sm"
                                                                    variant="ghost"
                                                                    onClick={
                                                                        copyIdentifier
                                                                    }
                                                                    className="flex items-center gap-2 text-black"
                                                                >
                                                                    <CopyIcon className="h-4 w-4" />{" "}
                                                                    Copy
                                                                    Identifier
                                                                </Button>
                                                                <Button
                                                                    size="sm"
                                                                    variant="ghost"
                                                                    onClick={
                                                                        copyLink
                                                                    }
                                                                    className="flex items-center gap-2 text-black"
                                                                >
                                                                    <Link2Icon className="h-4 w-4" />{" "}
                                                                    Copy Link
                                                                </Button>
                                                            </div>
                                                        </TooltipContent>
                                                    </TooltipRoot>
                                                </TooltipProvider>
                                            </BreadcrumbList>
                                        </Breadcrumb>

                                        <Badge variant="outline" color="gray">
                                            {identifier}
                                        </Badge>
                                    </div>
                                    <div className="flex flex-row">
                                        <ToggleGroup.Root
                                            className="inline-flex rounded space-x-px"
                                            type="single"
                                            aria-label="Contract Type"
                                        >
                                            <ToggleGroup.Item
                                                className={`${toggleGroupItemClasses}`}
                                                value="Left"
                                                aria-label="Left"
                                                onClick={() => {
                                                    moveToPreviousTicket();
                                                }}
                                            >
                                                <ChevronLeftIcon color="gray" />
                                            </ToggleGroup.Item>
                                            <ToggleGroup.Item
                                                className={`${toggleGroupItemClasses}`}
                                                value="Right"
                                                aria-label="Right"
                                                onClick={() => {
                                                    moveToNextTicket();
                                                }}
                                                onKeyDown={(e) => {
                                                    if (
                                                        e.metaKey &&
                                                        e.key === "ArrowRight"
                                                    ) {
                                                        moveToNextTicket();
                                                    }
                                                }}
                                            >
                                                <ChevronRightIcon color="gray" />
                                            </ToggleGroup.Item>
                                        </ToggleGroup.Root>
                                    </div>
                                </div>
                                <Separator size="4" className="mt-2 mb-4" />
                                <Flex
                                    align="start"
                                    direction="column"
                                    justify={"start"}
                                    className="px-4"
                                >
                                    <div className="flex items-center gap-3">
                                        <div className="lb-comment-header lb-root mb-3">
                                            <div className="lb-comment-details items-center ">
                                                <Suspense
                                                    fallback={
                                                        <div className="relative aspect-square w-8 flex-none animate-pulse rounded-full bg-gray-100" />
                                                    }
                                                >
                                                    <Avatar
                                                        user={
                                                            customerUserInfo ?? {
                                                                id: "Anonymous",
                                                                name: "Anonymous",
                                                            }
                                                        }
                                                    />
                                                </Suspense>
                                                <div>
                                                    <MyUser
                                                        user={
                                                            customerUserInfo ?? {
                                                                id: "Anonymous",
                                                                name: "Anonymous",
                                                            }
                                                        }
                                                        className="text-md font-bold"
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex flex-row gap-1 pl-1 items-center">
                                                <span className="flex flex-row gap-1 items-center">
                                                    <p className="text-xs text-gray-600">
                                                        from
                                                    </p>

                                                    <a
                                                        href={ticket.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="font-semibold text-xs underline"
                                                    >
                                                        {ticket.channel_name.trim() !==
                                                        ""
                                                            ? ticket.channel_name
                                                            : ticket.source}
                                                    </a>
                                                </span>

                                                <span className="flex items-center justify-center w-4 h-4 bg-[#eceefb] rounded-full">
                                                    <SourceSvgImage
                                                        className="w-3 h-3"
                                                        justify="start"
                                                    />
                                                </span>

                                                <p className="text-xs text-gray-600">
                                                    on
                                                </p>

                                                <Card className="rounded-md shadow-sm shadow-[#f3f4f6] flex flex-row gap-1 px-1 py-1 items-center">
                                                    <CalendarIcon />
                                                    <p className="text-xs ">
                                                        {created_date}
                                                    </p>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                    {loadingThreadState === 0 && (
                                        <div>
                                            <Skeleton>
                                                <Text>
                                                    {[...Array(6)].map(
                                                        (_, index) => (
                                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                                            <Text key={index}>
                                                                {loremIpsum}
                                                            </Text>
                                                        ),
                                                    )}
                                                </Text>
                                            </Skeleton>
                                        </div>
                                    )}

                                    {loadingThreadState === 2 && (
                                        <Callout.Root
                                            size="1"
                                            variant="outline"
                                            color="red"
                                            className="pt-4 w-[50%]"
                                        >
                                            <Callout.Text>
                                                Sorry, something's wrong with
                                                loading the thread! Please
                                                notify us at
                                                support@askassembly.app.
                                            </Callout.Text>
                                        </Callout.Root>
                                    )}

                                    {loadingThreadState === 1 &&
                                        ticketThread?.length !== 0 && (
                                            <RoomProvider
                                                id={`${orgId}:ticket`}
                                            >
                                                <Room
                                                    threadData={ticketThread}
                                                    source={source}
                                                    source_specific_id={
                                                        ticket.source_specific_id
                                                    }
                                                    classNameComposer={
                                                        "sticky bottom-16 z-10 mt-5"
                                                    }
                                                    update={update}
                                                    setUpdate={setUpdate}
                                                    title={ticket.title}
                                                    ai_response={ai_response}
                                                    refetchThreadData={
                                                        refetchThreadData
                                                    }
                                                    showComposer={true}
                                                    url={ticket.url}
                                                    classNameThread="mb-52"
                                                    className="pb-4"
                                                />
                                            </RoomProvider>
                                        )}
                                </Flex>
                            </Card>
                            <Card className="bg-white mx-3 mt-2 shadow-sm border rounded-lg relative flex flex-row px-3 py-3 gap-5">
                                <div className="text-xs flex flex-row gap-2 items-center text-gray10 mt-auto">
                                    <Card className="rounded-md shadow-sm shadow-[#f3f4f6] flex flex-row gap-1 px-1 items-center">
                                        <CommandIcon
                                            strokeWidth={1.5}
                                            size={12}
                                        />
                                        <ArrowLeftIcon />
                                    </Card>
                                    <p className="text-gray11">Previous</p>
                                </div>
                                <div className="text-xs flex flex-row gap-2 items-center text-gray10 mt-auto">
                                    <Card className="rounded-md shadow-sm shadow-[#f3f4f6] flex flex-row gap-1 px-1 items-center">
                                        <CommandIcon
                                            strokeWidth={1.5}
                                            size={12}
                                        />
                                        <ArrowRightIcon />
                                    </Card>
                                    <p className="text-gray11">Next</p>
                                </div>

                                <div className="text-xs flex flex-row gap-2 items-center text-gray10 mt-auto ml-auto">
                                    <Card className="rounded-md shadow-sm shadow-[#f3f4f6] flex flex-row gap-1 px-1 items-center">
                                        <CommandIcon
                                            strokeWidth={1.5}
                                            size={12}
                                        />{" "}
                                        <p>Enter </p>
                                    </Card>{" "}
                                    <p className="text-gray11">to send</p>
                                </div>
                            </Card>
                        </ResizablePanel>
                        <ResizableHandle />
                        <ResizablePanel
                            defaultSize={window.innerWidth * 0.25}
                            key="ticketMetadata"
                            className="flex flex-col h-full"
                        >
                            <div className="m-3 flex flex-1 flex-col gap-3 rounded-md overflow-y-auto overflow-visible">
                                <Card className="rounded-lg hover:outline-0.5 hover:outline-offset-0 flex flex-col p-3 shadow-sm border relative bg-white">
                                    <div className="text-sm font-medium ">
                                        Properties
                                    </div>
                                </Card>
                                <SidebarCard
                                    isOpen={attributesIsOpen}
                                    setIsOpen={setAttributesIsOpen}
                                    sidebarLabels={sidebarLabels}
                                    type="Attributes"
                                    analytics={ticket}
                                    refetchTicketData={refetchTicketData}
                                    refetchThreadData={refetchThreadData}
                                    userID={userID}
                                    customerUserInfo={customerUserInfo}
                                />
                                <SidebarCard
                                    isOpen={labelsIsOpen}
                                    setIsOpen={setLabelsIsOpen}
                                    sidebarLabels={sidebarLabels}
                                    type="Labels"
                                    analytics={ticket}
                                    refetchTicketData={refetchTicketData}
                                    refetchThreadData={refetchThreadData}
                                    userID={userID}
                                    customerUserInfo={customerUserInfo}
                                />
                                {source !== "ChatWidget" && (
                                    <SidebarCard
                                        isOpen={customerIsOpen}
                                        setIsOpen={setCustomerIsOpen}
                                        sidebarLabels={sidebarLabels}
                                        type="Customer Profile"
                                        analytics={ticket}
                                        refetchTicketData={refetchTicketData}
                                        refetchThreadData={refetchThreadData}
                                        userID={userID}
                                        customerUserInfo={customerUserInfo}
                                    />
                                )}
                                <SidebarCard
                                    isOpen={extIssuesIsOpen}
                                    setIsOpen={setExtIssuesIsOpen}
                                    sidebarLabels={sidebarLabels}
                                    type="External Issues"
                                    analytics={ticket}
                                    refetchTicketData={refetchTicketData}
                                    refetchThreadData={refetchThreadData}
                                    userID={userID}
                                    customerUserInfo={customerUserInfo}
                                    alertNum={
                                        ticket.external_issues?.length ?? 0
                                    }
                                />
                                <SidebarCard
                                    isOpen={insightsIsOpen}
                                    setIsOpen={setInsightsIsOpen}
                                    sidebarLabels={sidebarLabels}
                                    type="Insights"
                                    analytics={ticket}
                                    refetchTicketData={refetchTicketData}
                                    refetchThreadData={refetchThreadData}
                                    userID={userID}
                                    customerUserInfo={customerUserInfo}
                                    alertNum={ticket.insights?.length ?? 0}
                                />
                                <SidebarCard
                                    isOpen={noteIsOpen}
                                    setIsOpen={setNoteIsOpen}
                                    sidebarLabels={sidebarLabels}
                                    type="Internal Notes"
                                    analytics={ticket}
                                    refetchTicketData={refetchTicketData}
                                    refetchThreadData={refetchThreadData}
                                    userID={userID}
                                    customerUserInfo={customerUserInfo}
                                    alertNum={
                                        ticket.internal_note === "" ? 0 : 1
                                    }
                                />
                                <div className="pb-10" />
                            </div>
                        </ResizablePanel>
                    </ResizablePanelGroup>
                </TooltipProvider>
            </div>
        </div>
    );
}

export default memo(TicketDisplay, areEqual);
