import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";

import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";

import { AIResponsePlugin } from "./AIResponsePlugin";

import { $convertToMarkdownString, TRANSFORMERS } from "@lexical/markdown";

import { LexicalComposer } from "@lexical/react/LexicalComposer";

import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { ListItemNode, ListNode } from "@lexical/list";

import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LineBreakNode, ParagraphNode } from "lexical";
import { ExampleTheme } from "../../component/textEditor/Theme";
import Toolbar from "../../component/textEditor/Toolbar";
import ActionsPlugin from "./ActionsPlugin";
import EnterCommand from "./EnterCommand";
import ImagesPlugin from "./ImagesPlugin";
import { EmojiNode } from "./nodes/EmojiNode";
import { ImageNode } from "./nodes/ImageNode";
import type { UploadedFile } from "@/interfaces/serverData";
import { Cross1Icon } from "@radix-ui/react-icons";
import { Button } from "../shadcn/ui/button";

interface EditorProps {
    className?: string;
    handleSubmit: (markdwn: string, files: UploadedFile[]) => void;
    enableAIResponse: boolean;
    ai_response: string;
    isToggled?: boolean;
    setIsToggled?: React.Dispatch<React.SetStateAction<boolean>>;
    aiResponseButton?: boolean;
    setIsSendDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isSendDisabled: boolean;
    loading?: boolean;
    setKey?: React.Dispatch<React.SetStateAction<number>>;
    handleFileUpload: (src: string, altText: string) => void;
    handleDeleteFile: (fileToDelete: UploadedFile) => void
    uploadedFiles: UploadedFile[]
    source: string;
}

export function Editor({
    className,
    handleSubmit,
    enableAIResponse,
    ai_response,
    isToggled,
    setIsToggled = () => { },
    aiResponseButton,
    isSendDisabled,
    setIsSendDisabled,
    setKey = () => { },
    loading = false,
    handleFileUpload,
    handleDeleteFile,
    uploadedFiles,
    source
}: EditorProps) {
    const initialConfig = {
        namespace: "MyEditor",
        theme: ExampleTheme,
        onError: (error: unknown) => {
            console.error(error);
            throw error;
        },
        nodes: [
            HeadingNode,
            QuoteNode,
            ListNode,
            ListItemNode,
            EmojiNode,
            LineBreakNode,
            ParagraphNode,
            ImageNode,
        ],
    };

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div
                className={`relative flex flex-row justify-between w-full pb-2 gap-[50px] bg-white ${className || ""}`}
            >
                <div className="text-sm relative flex flex-col flex-1 gap-1 rounded-lg transition-all">
                    <Toolbar handleFileUpload={handleFileUpload} source={source} />
                    {enableAIResponse && (
                        <AIResponsePlugin
                            ai_response={ai_response}
                            isToggled={isToggled ?? false}
                            setIsSendDisabled={setIsSendDisabled}
                            setIsToggled={setIsToggled}
                            aiResponseButton={aiResponseButton ?? false}
                            setKey={setKey}
                        />
                    )}
                    <div className="max-h-32 overflow-scroll scrollbar-white">
                        <RichTextPlugin
                            contentEditable={
                                <ContentEditable className="w-full" />
                            }
                            placeholder={<div />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                    </div>
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <ClearEditorPlugin />
                    <ImagesPlugin handleFileUpload={handleFileUpload} />
                    {/* <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
                    <EnterCommand
                        onSubmit={() => {
                            const mrkdwn =
                                $convertToMarkdownString(TRANSFORMERS);
                            handleSubmit(mrkdwn, uploadedFiles);
                        }}
                    />
                    <ListPlugin />
                    <div className="flex items-center flex-wrap gap-1">
                        {uploadedFiles.map((file) => (
                            <div key={file.alt} className="relative">
                                <div
                                    className="w-[100px] h-[100px] overflow-hidden relative m-1">
                                    <img
                                        src={file.src}
                                        alt={file.alt}
                                        style={{
                                            maxWidth: 'auto',
                                            height: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </div>
                                <Button
                                    onClick={() => handleDeleteFile(file)}
                                    className="p-1 absolute top-0 right-0 bg-[#5B5BD6]"
                                >
                                    <Cross1Icon className="w-2.5 h-2.5" />
                                </Button>
                            </div>
                        ))}
                    </div>
                    <ActionsPlugin
                        isSendDisabled={isSendDisabled}
                        setIsSendDisabled={setIsSendDisabled}
                        loading={loading}
                        handleSubmit={() => {
                            const mrkdwn =
                                $convertToMarkdownString(TRANSFORMERS);
                            handleSubmit(mrkdwn, uploadedFiles);
                        }}
                        uploadedFiles={uploadedFiles}
                    />
                </div>
            </div>
        </LexicalComposer>
    );
}
