"use client";

import { LexicalComposer } from "@lexical/react/LexicalComposer";

import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";

import { ContentEditable } from "@lexical/react/LexicalContentEditable";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import { ListItemNode, ListNode } from "@lexical/list";

import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";

import {
	LiveblocksPlugin,
	liveblocksConfig,
	useEditorStatus,
} from "@liveblocks/react-lexical";

import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ExampleTheme } from "../../component/textEditor/Theme";
import Toolbar from "../../component/textEditor/Toolbar";
import { EmojiNode } from "../../component/textEditor/nodes/EmojiNode";
import { Loading } from "./Loading";

import "./editor.css";
import { AIResponsePlugin } from "@/component/textEditor/AIResponsePlugin";
import { ImageNode } from "@/component/textEditor/nodes/ImageNode";
import { useState } from "react";
import type { UploadedFile } from "@/interfaces/serverData";

// Wrap your initial config with `liveblocksConfig`
const initialConfig = liveblocksConfig({
	namespace: "liveblocks",
	nodes: [HeadingNode, QuoteNode, ListNode, ListItemNode, EmojiNode, ImageNode],
	onError: (error: unknown) => {
		console.error(error);
		throw error;
	},
	theme: ExampleTheme,
});

interface CollaborativeEditorProps {
	getAIResponse?: () => void;
	aiResponse: string;
	aiResponseCompleted: number;
	aiResponseButton: boolean;
}

export default function CollaborativeEditor({
	getAIResponse = () => { },
	aiResponse,
	aiResponseCompleted,
	aiResponseButton,
}: CollaborativeEditorProps) {
	const status = useEditorStatus();
	const [isToggled, setIsToggled] = useState(false);
	// TODO: file upload is not synced with liveblocks for collaboration so currently commented out
	const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
	const handleFileUpload = (src: string, altText: string) => {
		setUploadedFiles(prevFiles => [
			...prevFiles,
			{ src: src, alt: altText },
		]);
	};
	const handleDeleteFile = (fileToDelete: UploadedFile) => {
		const newUploadedFiles = uploadedFiles.filter(file => file.alt !== fileToDelete.alt)
		setUploadedFiles(newUploadedFiles)
	}
	return (
		<div className="relative min-h-screen flex flex-col">
			<LexicalComposer initialConfig={initialConfig}>
				<LiveblocksPlugin>
					{status === "not-loaded" || status === "loading" ? (
						<Loading />
					) : (
						<div className="relative flex flex-row justify-between w-full gap-[50px]">
							<div className="focus-within:shadow-md focus-within:outline-0.5 focus-within:outline-offset-0  relative text-sm flex flex-col flex-1 pb-4 pr-4 border rounded-lg shadow-sm  transition-all min-h-[500px]">
								<Toolbar handleFileUpload={handleFileUpload} source={"None"} />
								<AIResponsePlugin
									className="right-2"
									isToggled={isToggled ?? false}
									setIsToggled={setIsToggled}
									aiResponseButton={aiResponseButton}
									ai_response={aiResponse}
									getAIResponse={getAIResponse}
									aiResponseCompleted={aiResponseCompleted}
								/>
								<RichTextPlugin
									contentEditable={
										<ContentEditable className="min-h-[500px] pl-4 pt-4 focus:outline-purple-600" />
									}
									placeholder={<div />}
									ErrorBoundary={LexicalErrorBoundary}
								/>
								<ListPlugin />
								{/* <ImagesPlugin handleFileUpload={handleFileUpload} /> */}
								{/* <div className="flex items-center flex-wrap gap-1">
									{uploadedFiles.map((file) => (
										<div key={file.alt} className="relative">
											<div
												className="w-[100px] h-[100px] overflow-hidden relative m-1">
												<img
													src={file.src}
													alt={file.alt}
													style={{
														maxWidth: 'auto',
														height: '100%',
														objectFit: 'cover'
													}}
												/>
											</div>
											<Button
												onClick={() => handleDeleteFile(file)}
												className="p-1 absolute top-0 right-0 bg-[#5B5BD6]"
											>
												<Cross1Icon className="w-2.5 h-2.5" />
											</Button>
										</div>
									))}
								</div> */}
							</div>
						</div>
					)}
				</LiveblocksPlugin>
			</LexicalComposer>
		</div>
	);
}
