import type { ComposerFormProps } from "@liveblocks/react-ui/primitives";
import "./styles.css";
import { Card } from "@/component/shadcn/ui/card";
import { Editor } from "@/component/textEditor/Editor";
import { useApi } from "@/interfaces/api";
import type {
    HistoryResponse,
    PublishMessage,
    UploadedFile,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { CheckCircledIcon, CrossCircledIcon } from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import { Flex } from "@radix-ui/themes";

import { API, URLS } from "@/constant";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import slackifyMarkdown from "slackify-markdown";

interface ComposerProps extends ComposerFormProps {
    placeholder?: string;
    submit?: string;
    source_specific_id: string;
    source: string;
    update: boolean;
    setUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    ai_response: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

export function MyComposer({
    className,
    placeholder = "Write a comment…",
    submit = "Send",
    source,
    source_specific_id,
    title,
    update,
    setUpdate,
    ai_response,
    refetchThreadData,
    ...props
}: ComposerProps) {
    const api = useApi();

    const [loading, setLoading] = useState(false);

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    useEffect(() => {
        authInfoRef.current = authInfo;
    }, [authInfo]);

    const [open, setOpen] = useState<boolean>(false);

    const [toastSuccess, setToastSuccess] = useState<boolean>(true);
    const [toastText, setToastText] = useState<string>("Message sent");
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    const [isSendDisabled, setIsSendDisabled] = useState(false);
    function handleContentChange(content: string) {
        setIsSendDisabled(
            (content.trim().length ?? -1) > 0 || uploadedFiles?.length > 0,
        );
    }
    const [aiResponseButton, hideAIResponseButton] = useState(true);

    const [key, setKey] = useState<number>(0);

    const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
    const handleFileUpload = (src: string, altText: string) => {
        setUploadedFiles((prevFiles) => [
            ...prevFiles,
            { src: src, alt: altText },
        ]);
    };

    const handleDeleteFile = (fileToDelete: UploadedFile) => {
        const newUploadedFiles = uploadedFiles.filter(
            (file) => file.alt !== fileToDelete.alt,
        );
        setUploadedFiles(newUploadedFiles);
        if (newUploadedFiles.length === 0) {
            setIsSendDisabled(false);
        }
    };

    useEffect(() => {
        if (ai_response !== undefined && ai_response.trim() !== "") {
            hideAIResponseButton(false);
        }
    }, [ai_response]);

    const [isToggled, setIsToggled] = useState(false);

    const handleSend = (mrkdwn: string, files: UploadedFile[]) => {
        let content = mrkdwn;
        if (!authInfoRef.current.isLoggedIn) {
            return;
        }

        console.log(mrkdwn);

        if (mrkdwn === undefined || !isSendDisabled) {
            return;
        }

        const userId = authInfoRef.current.user?.userId;
        if (userId === undefined) {
            return;
        }

        if (source === "Slack") {
            content = slackifyMarkdown(mrkdwn);
        }
        if (source === "CommunitySlack") {
            content = slackifyMarkdown(mrkdwn);
        }
        const requestData: PublishMessage = {
            message: content,
            source: source,
            user: userId,
            source_specific_id: source_specific_id,
            message_title: title,
            files: files,
        };
        setLoading(true);
        setIsSendDisabled(false);
        setUploadedFiles([]);
        api.post(URLS.serverUrl + API.publishMessage, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    console.log("sent the message with publish message");
                    setToastText("Message sent");
                    setToastSymbol(CheckCircledIcon);
                    setToastSuccess(true);
                    setTimeout(() => {
                        setUpdate(!update);
                        setLoading(false);
                        refetchThreadData();
                    }, 2000);
                } else {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setLoading(false);
                    setToastSymbol(CrossCircledIcon);
                    console.log("Call to update tag failed");
                    setToastSuccess(false);
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setLoading(false);
                setToastSymbol(CrossCircledIcon);
                setToastSuccess(false);
            })
            .finally(() => {
                setOpen(true);
            });
    };

    return (
        <>
            <Toast.Provider swipeDirection="right">
                <Toast.Root
                    className="ToastRoot"
                    open={open}
                    onOpenChange={setOpen}
                >
                    <Toast.Title className="ToastTitle">
                        <Flex direction={"row"} align={"center"} gap="2">
                            <ToastSymbol
                                color={toastSuccess ? "green" : "red"}
                            />
                            {toastText}
                        </Flex>
                    </Toast.Title>
                </Toast.Root>
                <Toast.Viewport className="ToastViewport" />
            </Toast.Provider>

            <div className="flex flex-col">
                <Card className="focus-within:shadow-md focus-within:outline-0.5 focus-within:outline-offset-0 flex flex-row px-2 py-1 mt-4 mb-2 shadow-sm shadow-[#f3f4f6] color-[#f3f4f6] border relative">
                    <Editor
                        className="max-h-80 overflow-scroll scrollbar-white"
                        enableAIResponse={true}
                        handleSubmit={handleSend}
                        ai_response={ai_response}
                        isToggled={isToggled}
                        setIsSendDisabled={setIsSendDisabled}
                        isSendDisabled={isSendDisabled}
                        setIsToggled={setIsToggled}
                        aiResponseButton={aiResponseButton}
                        setKey={setKey}
                        loading={loading}
                        handleFileUpload={handleFileUpload}
                        handleDeleteFile={handleDeleteFile}
                        uploadedFiles={uploadedFiles}
                        source={source}
                    />
                </Card>
            </div>
        </>
    );
}
