import { Button } from "@/component/shadcn/ui/button";
import type { File } from "@/interfaces/serverData.js";
import { CaretDownIcon, CaretUpIcon, Cross2Icon, DownloadIcon, OpenInNewWindowIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useState } from "react";
import ExcelPreview from "./ExcelPreview";

interface FilePopUpDisplayProps {
    file: File
}

// TODO: support audio files?
export function FilePopUpDisplay({
    file
}: FilePopUpDisplayProps) {
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isTriggerOpen, setIsTriggerOpen] = useState(true);

    const handleFileClick = () => {
        setIsPreviewOpen(true);
    };

    const closeModal = () => {
        setIsPreviewOpen(false);
    };

    function getEmojiForMimeType(mimetype: string) {
        if (mimetype.startsWith("image/")) {
            return "🖼️"
        } else if (mimetype.startsWith("video/")) {
            return "🎬"
        } else if (mimetype === "application/msword" ||
            mimetype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            return "📘"
        } else if (mimetype === "application/vnd.ms-excel" ||
            mimetype === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            return "📊"
        } else if (mimetype === "application/pdf") {
            return "📕"
        } else if (mimetype.startsWith("text/")) {
            return "📗"
        } else {
            return "📓"
        }
    }

    return (
        <div className="my-0 mr-1.5">
            {(() => {
                if (file.mimetype.startsWith("image/")) {
                    return (
                        <div>
                            <div className="flex items-center gap-1 text-xs text-gray-600">
                                <Button variant="ghost" className="m-0 py-1 px-0" onClick={() => handleFileClick()}>
                                    <OpenInNewWindowIcon className="w-3 h-3" />
                                </Button>
                                🖼️ {file.name}
                                <Button variant="ghost" className="m-0 py-1 px-0" onClick={() => setIsTriggerOpen(!isTriggerOpen)}>
                                    {isTriggerOpen ? <CaretDownIcon className="w-3 h-3" /> : <CaretUpIcon className="w-3 h-3" />}
                                </Button>
                            </div>

                            {isTriggerOpen && file.url_local !== "" && (
                                <img
                                    key={file.id}
                                    src={file.url_local}
                                    alt={file.name}
                                    style={{
                                        maxWidth: '50%',
                                        height: 'auto',
                                    }}
                                />
                            )}
                        </div>
                    );
                } else if (file.mimetype.startsWith("video/")) {
                    return (
                        <div>
                            <div className="flex items-center gap-1 text-xs text-gray-600">
                                <Button variant="ghost" className="m-0 py-1 px-0" onClick={() => handleFileClick()}>
                                    <OpenInNewWindowIcon className="w-3 h-3" />
                                </Button>
                                🎬 {file.name}
                                <Button variant="ghost" className="m-0 py-1 px-0" onClick={() => setIsTriggerOpen(!isTriggerOpen)}>
                                    {isTriggerOpen ? <CaretDownIcon className="w-3 h-3" /> : <CaretUpIcon className="w-3 h-3" />}
                                </Button>
                            </div>

                            {isTriggerOpen && file.url_local !== "" && (
                                // biome-ignore lint/a11y/useMediaCaption: <explanation>
                                <video
                                    controls
                                    style={{ width: '50%', height: 'auto' }} // Adjust as needed
                                    title="Video Playback"
                                >
                                    <source src={file.url_local} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <Button variant="ghost" className="m-0 py-1 px-0" onClick={() => handleFileClick()}>
                            <Badge
                                color="gray"
                                size="1"
                                radius="medium"
                                variant="outline"
                                className="text-xs px-2 py-1 flex items-center gap-0.5"
                            >
                                <span aria-label="PDF" style={{ marginRight: '5px' }}>
                                    {getEmojiForMimeType(file.mimetype)}
                                </span>
                                {file.name}
                            </Badge>
                        </Button>
                    );
                }

            })()}

            {isPreviewOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded-lg max-w-6xl w-full max-h-full">
                        <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center gap-2">
                                {getEmojiForMimeType(file.mimetype)}
                                <h2 className="text-lg font-semibold">{file.name}</h2>
                                <a
                                    href={file.url_local || "#"} // Use "#" to avoid href being empty
                                    download={file.name}
                                    className={`bg-[#5B5BD6] text-white inline-flex items-center py-1.5 px-2 hover:shadow-lg shadow-md rounded-md ${file.url_local === "" ? "opacity-50 cursor-not-allowed" : ""
                                        }`}
                                    onClick={(e) => {
                                        // Prevent default action if the URL is empty
                                        if (file.url_local === "") {
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    {file.url_local === "" ? (
                                        <div className="flex items-center gap-1">
                                            <DownloadIcon />
                                            <div className="w-3 h-3 border-2 border-t-4 border-white border-dashed rounded-full animate-spin" />
                                        </div>
                                    ) : (
                                        <DownloadIcon />
                                    )}
                                </a>
                            </div>
                            <Button
                                type="button"
                                onClick={closeModal}
                                variant="ghost"
                                className="px-2.5 py-1.5 rounded hover:bg-muted"
                            >
                                <Cross2Icon />
                            </Button>
                        </div>
                        {(() => {
                            if (file.too_big_to_preview) {
                                return <div className="flex items-center gap-1 text-sm flex items-center rounded-lg w-full">
                                    File is too large to preview. Please download it to view it.
                                </div>
                            }
                            if (file.url_local === "") {
                                return <div className="flex items-center gap-1 text-sm flex items-center rounded-lg w-full">
                                    Loading File Preview...{" "}
                                    <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                                </div>
                            }
                            if (
                                file.mimetype === "application/msword" ||
                                file.mimetype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            ) {
                                // Additional html_url also needs to be populated
                                if (file.html_url === "") {
                                    return <div className="flex items-center gap-1 text-sm flex items-center rounded-lg w-full">
                                        Loading File Preview...{" "}
                                        <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                                    </div>
                                }
                                return (
                                    <iframe
                                        src={file.html_url}
                                        style={{ width: '100%', height: '650px' }}
                                        title={file.name}
                                    />
                                );
                            } else if (
                                file.mimetype === "application/vnd.ms-excel" ||
                                file.mimetype === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ) {
                                // Additional excel rows also needs to be populated
                                if (!file.excel_rows || file.excel_rows?.length === 0) {
                                    return <div className="flex items-center gap-1 text-sm flex items-center rounded-lg w-full">
                                        Loading File Preview...{" "}
                                        <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                                    </div>
                                }
                                return <ExcelPreview excel_rows={file.excel_rows} />;
                            } else if (file.mimetype.startsWith("image/")) {
                                return (
                                    <img
                                        key={file.id}
                                        src={file.url_local}
                                        alt={file.name}
                                        style={{
                                            maxWidth: '100%',
                                            height: 'auto',
                                        }}
                                    />
                                );
                            } else if (file.mimetype.startsWith("video/")) {
                                return (
                                    <div className="video-container">
                                        {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
                                        <video
                                            controls
                                            style={{ width: '100%', height: 'auto' }} // Adjust as needed
                                            title="Video Playback"
                                        >
                                            <source src={file.url_local} type="video/mp4" />
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                );
                            } else if (file.mimetype.startsWith("text/csv")) {
                                // Additional excel rows also needs to be populated
                                if (!file.csv_rows || file.csv_rows?.length === 0) {
                                    return <div className="flex items-center gap-1 text-sm flex items-center rounded-lg w-full">
                                        Loading File Preview...{" "}
                                        <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                                    </div>
                                }
                                return (
                                    <div className="overflow-auto max-h-[650px]">
                                        <table className="min-w-full border-collapse border border-gray-200">
                                            <thead>
                                                <tr>
                                                    {file.csv_rows.length > 0 && Object.keys(file.csv_rows[0]).map((header) => (
                                                        <th key={header} className="border border-gray-300 px-4 py-2">
                                                            {header}
                                                        </th>
                                                    ))}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {file.csv_rows.map((row, rowIndex) => (
                                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                                    <tr key={rowIndex}>
                                                        {Object.values(row).map((cell, cellIndex) => (
                                                            // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                                            <td key={cellIndex} className="border border-gray-300 px-4 py-2">
                                                                {cell}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                );
                            } else {
                                return (
                                    <iframe
                                        src={file.url_local}
                                        style={{ width: '100%', height: '650px' }}
                                        title={file.name}
                                    />
                                );
                            }

                        })()}

                    </div>
                </div>
            )}
        </div>
    );
};