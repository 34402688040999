import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    CheckCircledIcon,
    Cross2Icon,
    CrossCircledIcon,
    ExclamationTriangleIcon,
} from "@radix-ui/react-icons";
import type React from "react";
import { useEffect, useRef, useState } from "react";

import * as ToggleGroup from "@radix-ui/react-toggle-group";

import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import { Button } from "@/component/shadcn/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/component/shadcn/ui/form";
import { Input } from "@/component/shadcn/ui/input";
import { ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { ContractType } from "@/interfaces/serverData";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useSearch } from "../../SearchContext";

import type {
    Account,
    AccountUpdatePayload,
    IndividualCreatePayload,
} from "@/interfaces/serverData";

const formSchemaCompany = z.object({
    companyName: z
        .string()
        .min(1, "Company Name is required")
        .max(50, "Company Name must be at most 50 characters"),
    companyDomain: z
        .string()
        .min(3, "Domain must be at least 3 characters")
        .max(50, "Domain must be at most 50 characters")
        .regex(/^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Invalid domain format")
        .optional(),
    metadata: z.string().optional(),
    plan: z.string().optional(),
    contract_value: z
        .string()
        .transform((val) => (val === "" ? undefined : Number(val)))
        .refine((val) => val === undefined || !Number.isNaN(val), {
            message: "Only numbers allowed!",
        })
        .optional(),
    contract_type: z.string().optional(),
    image_url: z.string().optional(),
});

const formSchemaAccount = z.object({
    companyName: z
        .string()
        .min(1, "Account Name is required")
        .max(50, "Account Name must be at most 50 characters"),
    companyDomain: z
        .string()
        .min(3, "Domain must be at least 3 characters")
        .max(50, "Domain must be at most 50 characters")
        .email("This is not a valid email.")
        .optional(),
    metadata: z.string().optional(),
    plan: z.string().optional(),
    contract_value: z
        .string()
        .transform((val) => (val === "" ? undefined : Number(val)))
        .refine((val) => val === undefined || !Number.isNaN(val), {
            message: "Only numbers allowed!",
        })
        .optional(),
    contract_type: z.string().optional(),
    image_url: z.string().optional(),
});

interface AccountPopupProps {
    saveCompany?: () => void;
    icon?: React.ElementType;
    triggerElement: React.ReactNode;
    editing: boolean;
    setToastOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    type: "Company" | "Account";
    companyId?: string;
    editingObject?: Account;
}

const AccountPopup: React.FC<AccountPopupProps> = ({
    saveCompany = () => {},
    icon,
    triggerElement,
    editing,
    setToastOpen,
    setToastSymbol,
    setToastText,
    type,
    companyId,
    editingObject,
}) => {
    const formSchema =
        type === "Account" ? formSchemaAccount : formSchemaCompany;

    const form = useForm<z.infer<typeof formSchema>>({
        resolver: zodResolver(formSchema),
        defaultValues: {
            companyName: editingObject?.name ?? "",
            companyDomain: editingObject?.domain ?? editingObject?.email ?? "",
            image_url: editingObject?.image_url ?? "",
            plan: editingObject?.plan ?? "",
            contract_value: editingObject?.contract_value ?? 0,
            contract_type: editingObject?.contract_type ?? "",
        },
    });

    const { reset, handleSubmit, getValues } = form;

    const api = useApi();

    const timerRef = useRef(0);

    function onSubmit() {
        const values = getValues();
        const requestData: IndividualCreatePayload = {
            name: values.companyName,
            domain: values.companyDomain ?? "",
            image_url: values.image_url,
            contract_value: values.contract_value,
            contract_type: values.contract_type
                ? (values.contract_type as ContractType)
                : ContractType.Month,
            plan: values.plan,
        };

        if (type === "Account") {
            requestData.company_id = companyId;
            api.put(
                `${URLS.serverUrl}${ContactsAPI.createContact.url}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        setToastText(
                            "Oops! Something's wrong. Please try again at a later time.",
                        );
                        setToastSymbol(CrossCircledIcon);
                    } else {
                        setToastSymbol(CheckCircledIcon);
                        setToastText("Updated Settings!");
                    }
                })
                .catch((res) => {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                })
                .finally(() => {
                    setOpen(false);
                    setToastOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                });
        } else {
            api.put(
                `${URLS.serverUrl}${ContactsAPI.createCompany.url}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        setToastText(
                            "Oops! Something's wrong. Please try again at a later time.",
                        );
                        setToastSymbol(CrossCircledIcon);
                    } else {
                        setToastText("Updated Settings!");
                    }
                })
                .catch((res) => {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                })
                .finally(() => {
                    setOpen(false);
                    setToastOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                });
        }
    }

    function onEdit() {
        const values = getValues();
        const requestData: AccountUpdatePayload = {
            id: editingObject?.id ?? "",
            name: values.companyName,
            domain: values.companyDomain ?? "",
            image_url: values.image_url ?? "",
            contract_value: values.contract_value ?? 0,
            contract_type: values.contract_type
                ? (values.contract_type as ContractType)
                : ContractType.Month,
            plan: values.plan ?? "",
        };

        if (type === "Account") {
            requestData.company_id = companyId;
            api.patch(
                `${URLS.serverUrl}${ContactsAPI.updateContact.url}/${editingObject?.id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        setToastText(
                            "Oops! Something's wrong. Please try again at a later time.",
                        );
                        setToastSymbol(CrossCircledIcon);
                    } else {
                        setToastText("Updated Settings!");
                        setToastSymbol(CheckCircledIcon);
                    }
                })
                .catch((res) => {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                })
                .finally(() => {
                    setOpen(false);
                    setToastOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                });
        } else {
            api.patch(
                `${URLS.serverUrl}${ContactsAPI.editCompany.url}/${editingObject?.id}`,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            )
                .then((res) => {
                    if (res.status !== 200) {
                        setToastText(
                            "Oops! Something's wrong. Please try again at a later time.",
                        );
                        setToastSymbol(CrossCircledIcon);
                    } else {
                        setToastText("Updated Settings!");
                        setToastSymbol(CheckCircledIcon);
                    }
                })
                .catch((res) => {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                })
                .finally(() => {
                    setOpen(false);
                    setToastOpen(false);
                    window.clearTimeout(timerRef.current);
                    timerRef.current = window.setTimeout(() => {
                        setToastOpen(true);
                    }, 100);
                });
        }
    }

    const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active
    const [open, setOpen] = useState<boolean>(false);
    const [filteredCompanies, setFilteredCompanies] = useState([
        {
            label: "Slack",
            value: "Slack",
            color: "",
        },
        {
            label: "Discord",
            value: "Discord",
            color: "",
        },
        {
            label: "Google",
            value: "Google",
            color: "",
        },
        {
            label: "GitHubTicket",
            value: "GitHubTicket",
            color: "",
        },
    ]);

    useEffect(() => {
        if (loading) {
            const updatedCompanies = filteredCompanies
                .filter((broadcast) => searchBarFilters.has(broadcast.value))
                .map((broadcast) => {
                    if (broadcast.value === "Google") {
                        return {
                            ...broadcast,
                            label: "Gmail",
                            value: "Gmail",
                        };
                    }
                    return broadcast;
                });
            setFilteredCompanies(updatedCompanies);
        }
    }, [searchBarFilters, loading]);

    const [companyTitle, setCompanyTitle] = useState<string>(
        type === "Company" ? "Add New Company" : "Add New Account",
    );

    const [errorMsg, setErrorMsg] = useState<string>("");
    const [validationErr, setValidationErr] = useState<boolean>(false);

    useEffect(() => {
        if (editing) {
            setCompanyTitle(
                type === "Company" ? "Edit Company" : "Edit Account",
            );
        }
    }, [editing, setCompanyTitle]);

    const formItemClassName = "flex flex-col";
    const formItemClassNameDiv = "flex flex-row items-center";
    const formLabelCN = "w-56 pt-1";
    const formLabelError = "text-xs";

    const toggleGroupItemClasses =
        "text-xs flex outline outline-1 outline-gray-300 h-[35px] w-[60px] items-center justify-center text-base leading-4 first:rounded-l last:rounded-r focus:z-10 focus:bg-iris3";

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>{triggerElement}</DialogTrigger>
            <DialogContent className="fixed inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50">
                <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                    <div className="ml-10 mr-10">
                        <DialogHeader className="justify-left text-left items-left pb-5">
                            <DialogTitle>{companyTitle}</DialogTitle>
                            <DialogDescription>
                                Add a new company to track.
                            </DialogDescription>
                        </DialogHeader>

                        <Form {...form}>
                            <form
                                onSubmit={
                                    editing
                                        ? handleSubmit(onEdit)
                                        : handleSubmit(onSubmit)
                                }
                                className="space-y-2 flex flex-col gap-3"
                            >
                                <FormField
                                    control={form.control}
                                    name="companyName"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div
                                                className={formItemClassNameDiv}
                                            >
                                                <FormLabel
                                                    className={formLabelCN}
                                                >
                                                    {type === "Company"
                                                        ? "Company Name"
                                                        : "Account Name"}
                                                </FormLabel>

                                                <FormControl className="max-w-lg">
                                                    <Input
                                                        placeholder={
                                                            type === "Company"
                                                                ? "Assembly"
                                                                : "Account Name"
                                                        }
                                                        {...field}
                                                    />
                                                </FormControl>
                                            </div>

                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="companyDomain"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div
                                                className={formItemClassNameDiv}
                                            >
                                                <FormLabel
                                                    className={formLabelCN}
                                                >
                                                    {type === "Company"
                                                        ? "Email Domain"
                                                        : "Email"}
                                                </FormLabel>

                                                <FormControl className="max-w-lg">
                                                    <Input
                                                        placeholder={
                                                            type === "Company"
                                                                ? "askassembly.app"
                                                                : "account@example.com"
                                                        }
                                                        {...field}
                                                    />
                                                </FormControl>
                                            </div>

                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="image_url"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div
                                                className={formItemClassNameDiv}
                                            >
                                                <FormLabel
                                                    className={formLabelCN}
                                                >
                                                    Image URL
                                                </FormLabel>

                                                <FormControl className="max-w-lg">
                                                    <Input
                                                        placeholder="Image URL"
                                                        {...field}
                                                    />
                                                </FormControl>
                                            </div>

                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />
                                <FormField
                                    control={form.control}
                                    name="plan"
                                    render={({ field }) => (
                                        <FormItem className={formItemClassName}>
                                            <div
                                                className={formItemClassNameDiv}
                                            >
                                                <FormLabel
                                                    className={formLabelCN}
                                                >
                                                    Plan
                                                </FormLabel>

                                                <FormControl className="max-w-lg">
                                                    <Input
                                                        placeholder="Basic"
                                                        {...field}
                                                    />
                                                </FormControl>
                                            </div>

                                            <FormMessage
                                                className={formLabelError}
                                            />
                                        </FormItem>
                                    )}
                                />

                                <div className="flex flex-row gap-3 items-center">
                                    <FormField
                                        control={form.control}
                                        name="contract_value"
                                        render={({ field }) => (
                                            <FormItem
                                                className={formItemClassName}
                                            >
                                                <div
                                                    className={
                                                        formItemClassNameDiv
                                                    }
                                                >
                                                    <FormLabel className="w-50 pr-[105px] pt-1">
                                                        Contract Size
                                                    </FormLabel>

                                                    <FormControl className="w-50 ml-0">
                                                        <Input
                                                            placeholder="$500"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                </div>

                                                <FormMessage
                                                    className={formLabelError}
                                                />
                                            </FormItem>
                                        )}
                                    />

                                    <FormField
                                        control={form.control}
                                        name="contract_type"
                                        render={({ field }) => (
                                            <FormItem
                                                className={formItemClassName}
                                            >
                                                <div
                                                    className={
                                                        formItemClassNameDiv
                                                    }
                                                >
                                                    <FormControl>
                                                        <div className="flex items-center">
                                                            <ToggleGroup.Root
                                                                className="inline-flex bg-mauve6 rounded space-x-px"
                                                                type="single"
                                                                defaultValue={
                                                                    field.value ||
                                                                    "Month"
                                                                }
                                                                aria-label="Contract Type"
                                                                onValueChange={(
                                                                    value,
                                                                ) => {
                                                                    field.onChange(
                                                                        value,
                                                                    );
                                                                }}
                                                            >
                                                                <ToggleGroup.Item
                                                                    className={`${toggleGroupItemClasses} ${
                                                                        field.value ===
                                                                            "Month" &&
                                                                        "bg-iris3"
                                                                    }`}
                                                                    value="Month"
                                                                    aria-label="Month"
                                                                >
                                                                    Month
                                                                </ToggleGroup.Item>
                                                                <ToggleGroup.Item
                                                                    className={`${toggleGroupItemClasses} ${
                                                                        field.value ===
                                                                            "Year" &&
                                                                        "bg-iris3"
                                                                    }`}
                                                                    value="Year"
                                                                    aria-label="Year"
                                                                >
                                                                    Year
                                                                </ToggleGroup.Item>
                                                            </ToggleGroup.Root>
                                                        </div>
                                                    </FormControl>
                                                </div>
                                                <FormMessage
                                                    className={formLabelError}
                                                />
                                            </FormItem>
                                        )}
                                    />
                                </div>

                                <div className="pt-4 justify-end text-end items-end">
                                    <Button className="bg-iris9" type="submit">
                                        Save
                                    </Button>
                                </div>
                                <DialogFooter className="flex flex-col">
                                    {validationErr && (
                                        <Alert
                                            variant="destructive"
                                            className="mt-4"
                                        >
                                            <div className="flex flex-row gap-3 items-center">
                                                <ExclamationTriangleIcon className="h-4 w-4" />
                                                <div>
                                                    <AlertTitle>
                                                        Error
                                                    </AlertTitle>
                                                    <AlertDescription>
                                                        {errorMsg}
                                                    </AlertDescription>
                                                </div>
                                            </div>
                                        </Alert>
                                    )}
                                    <DialogClose asChild>
                                        <Button
                                            type="button"
                                            variant="ghost"
                                            className="absolute top-4 right-4"
                                            onClick={() => {
                                                setValidationErr(false);
                                                if (!editing) {
                                                    reset();
                                                }
                                            }}
                                        >
                                            <Cross2Icon />
                                        </Button>
                                    </DialogClose>
                                </DialogFooter>
                            </form>
                        </Form>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default AccountPopup;
