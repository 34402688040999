import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import type { GetConnectionsResponse } from "@/interfaces/serverData";
import type { ScopeResponse } from "@/interfaces/serverData";
import type { BotConnection } from "@/interfaces/serverData";
import {
    CheckCircledIcon,
    CrossCircledIcon,
    PlusIcon,
    ReloadIcon,
} from "@radix-ui/react-icons";
import * as Toast from "@radix-ui/react-toast";
import {
    Badge,
    Box,
    Button,
    Callout,
    Card,
    DataList,
    Flex,
    Separator,
    Skeleton,
    Switch,
    Text,
} from "@radix-ui/themes";
import { format, parseISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SlackSvg } from "../../../images/integrations/slack.svg";
import type { BotMetadata } from "../../../interfaces/serverData";
import type { metadata } from "../IntegrationsManagementPage";
import "../toast_styles.css";
import { useQuery } from "@tanstack/react-query";
import IntegrationHeader from "./IntegrationHeader";
import Shortcuts from "./Shortcuts";
import SlackConfigPage from "./SlackConfigPage";

const SlackIntegration = () => {
    const slackContinue = () => {
        const client_id = process.env.REACT_APP_SLACK_CLIENT_ID;
        //dev mode: TODO - change this to prod mode when ready
        // const redirect_url =
        //     "https://redirectmeto.com/http://localhost:3001/admin/integrations/slack";
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/slack`;
        const state = process.env.REACT_APP_SLACK_STATE;
        const slack_url = `https://slack.com/oauth/v2/authorize?state=${state}&client_id=${client_id}&&redirect_uri=${redirect_url}&scope=app_mentions:read,channels:history,channels:join,channels:read,chat:write,chat:write.customize,chat:write.public,commands,files:read,files:write,groups:history,groups:read,im:history,im:read,links:read,mpim:history,pins:read,reactions:read,reactions:write,team:read,users:read,users:read.email&user_scope=`;
        window.open(slack_url, "_self");
    };

    const [enabledIntegrations, setEnabledIntegrations] = useState<string[]>(
        [],
    );

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
        slack_team_id: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );

    const [loadingStateIntegrationEnabled, setLoadingStateIntegrationEnabled] =
        useState<number>(loadingTypes.loading);
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    const [askAIEnabled, setAskAIEnabled] = useState<boolean>(false);
    const [toastText, setToastText] = useState<string>("Updated Settings!");

    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);
    const timerRef = useRef(0);

    const [loadingStateShortCuts, setLoadingStateShortCuts] = useState<number>(
        loadingTypes.loading,
    );
    const [asmTicketChannels, setAsmTicketChannels] = useState<ScopeResponse[]>(
        [],
    );
    const integrationToEmoji: { [key: string]: string } = {
        GitHubTicket: "\u{1F63F}",
        Linear: "\u{1F39F}",
        Jira: "\u{1F516}",
        "Intercom Low Priority": "\u{1F7E2}",
        "Intercom Medium Priority": "\u{1F7E0}",
        "Intercom High Priority": "\u{1F534}",
    };

    function saveSettings(askAIEnabledInput: boolean, indexCode: boolean) {
        const requestData: BotMetadata = {
            bot_type: "Slack",
            responding_enabled: askAIEnabledInput,
            index_code: indexCode,
        };
        // Populate the past settings when the toggle was on
        if (askAIEnabledInput) {
            requestData.auto_escalate = autoEscalate;
            requestData.preview_message = previewMessage;
            requestData.auto_respond = autoRespond;
            requestData.channels_selected = selectedChannels;
            requestData.confidence_enabled = confidenceScore;
            requestData.icon_url = iconUrl;
        }
        setAskAIEnabled(askAIEnabledInput);

        api.post(
            `${URLS.serverUrl}${API.saveBotSettingsV2}/slack`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Settings!");
                    // Hit the 'initialize_bot' endpoint when Ask AI is enabled
                    if (askAIEnabledInput) {
                        api.post(
                            URLS.serverUrl + API.initializeBot,
                            {},
                            {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            },
                        );
                    }
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                setRefreshPage((prev) => !prev);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setOpen(true);
                }, 100);
            });
    }

    function saveNotificationsSettings() {
        const requestData = {
            notifyOption1,
            // Add more options as needed
        };

        api.post(
            `${URLS.serverUrl}${API.saveNotificationSettings}/Slack`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Settings!");
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                window.setTimeout(() => {
                    setOpen(true);
                }, 100);
            });
    }

    function updateShortcuts(
        connectionInput: string,
        connectionMetadata: ScopeResponse[],
        connectionPriority: string,
    ) {
        const requestData: BotConnection = {
            bot_type: "Slack",
            connection_source: connectionInput,
            connection_channels: connectionMetadata,
            operation: "Add",
        };
        if (connectionPriority !== "") {
            requestData.connection_priority = connectionPriority;
        }

        api.post(URLS.serverUrl + API.updateConnection, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Settings!");
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setOpen(true);
                }, 100);
            });
        connectionsQuery.refetch();
    }

    useEffect(() => {
        const requestData = {
            types: ["Slack", "GitHubTicket", "Linear", "Jira", "Intercom"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.Slack) {
                            setIntegrationEnabled(true);
                        }
                        if (orgInfo.Slack?.scopes) {
                            for (const repo of orgInfo.Slack.scopes) {
                                temp.scopes.push(repo.name);
                            }

                            if (orgInfo.Slack.date) {
                                const date: Date = parseISO(orgInfo.Slack.date);
                                const humanReadableDate = format(
                                    date,
                                    "MMMM dd, yyyy hh:mm a",
                                );
                                temp.indexedOn = humanReadableDate;
                            }

                            if (orgInfo.Slack.slack_team_id) {
                                temp.slack_team_id =
                                    orgInfo.Slack.slack_team_id;
                            }
                        }

                        setAdditionalMetadata(temp);
                        const enabledInts: string[] = [];
                        if (orgInfo.GitHubTicket) {
                            enabledInts.push("GitHubTicket");
                        }
                        if (orgInfo.Linear) {
                            enabledInts.push("Linear");
                        } else if (orgInfo.Jira) {
                            // can only have linear or jira
                            enabledInts.push("Jira");
                        }
                        if (orgInfo.Intercom) {
                            enabledInts.push("Intercom");
                        }

                        setEnabledIntegrations(enabledInts);

                        setAdditionalMetadata(temp);
                        setLoadingStateIntegrationEnabled(loadingTypes.loaded);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingStateIntegrationEnabled(loadingTypes.error);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingStateIntegrationEnabled(loadingTypes.error);
            });
    }, [api]);

    const [autoEscalate, setAutoEscalate] = useState<boolean>(false);
    const [previewMessage, setPreviewMessage] = useState<boolean>(false);
    const [autoRespond, setAutoRespond] = useState<boolean>(false);
    const [indexCode, setIndexCode] = useState<boolean>(true);
    const [confidenceScore, setConfidenceScore] = useState<boolean>(true);
    const [iconUrl, setIconUrl] = useState<string>("");

    const [selectedChannels, setSelectedChannels] = useState<ScopeResponse[]>(
        [],
    );
    const [notificationChannels, setNotificationChannels] =
        useState<ScopeResponse>();

    const [errDialogOpen, setErrDialogOpen] = useState<boolean>(false);

    const [notificationsEnabled, setNotificationsEnabled] =
        useState<boolean>(false);
    const [notifyOption1, setNotifyOption1] = useState<boolean>(false);

    useEffect(() => {
        if (integrationEnabled) {
            const serverUrl = URLS.serverUrl ? URLS.serverUrl : "";
            api.get(`${serverUrl}${API.getBotSettingsV2}/Slack`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        const settingsData: BotMetadata = res.data.data;
                        setAskAIEnabled(
                            settingsData.responding_enabled ?? false,
                        );
                        setAutoEscalate(settingsData.auto_escalate ?? false);
                        setAutoRespond(settingsData.auto_respond ?? false);
                        setConfidenceScore(
                            settingsData.confidence_enabled ?? false,
                        );
                        setPreviewMessage(
                            settingsData.preview_message ?? false,
                        );
                        setIconUrl(settingsData.icon_url ?? "");
                        if (
                            settingsData.auto_respond === true &&
                            settingsData.channels_selected !== null
                        ) {
                            setSelectedChannels(
                                settingsData.channels_selected ?? [],
                            );
                        }

                        setAsmTicketChannels(
                            settingsData.asm_ticket_channels ?? [],
                        );

                        setIndexCode(settingsData.index_code ?? true);
                        setLoadingState(loadingTypes.loaded);
                    } else {
                        setErrDialogOpen(true);
                    }
                })
                .catch((res) => {
                    setErrDialogOpen(true);
                });
        }
        setLoadingState(loadingTypes.none);
    }, [integrationEnabled]);

    const navigate = useNavigate();
    const addMoreIndexingRepositories = () => {
        navigate("/admin/integrations/add/slack");
    };
    const addMoreListeningRepositories = () => {
        navigate("/admin/integrations/update/slack");
    };

    const [refreshPage, setRefreshPage] = useState<boolean>(false);

    const connectionsQuery = useQuery<GetConnectionsResponse[]>({
        queryKey: ["connections_slack"],
        queryFn: async () => {
            const requestData = {
                bot_type: "Slack",
            };
            const response = await api.post(
                URLS.serverUrl + API.getConnections,
                requestData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                },
            );
            return response.data.data ?? [];
        },
    });

    useEffect(() => {
        if (refreshPage) {
            // keep this here to force update get connections after an update goes through
        }
        if (asmTicketChannels.length !== 0) {
            connectionsQuery.refetch();
            setLoadingStateShortCuts(loadingTypes.loaded);
        } else {
            setLoadingStateShortCuts(loadingTypes.loaded);
        }
    }, [asmTicketChannels, api, refreshPage]);

    return (
        <div className="bg-gray-500">
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="Slack"
                        description="Stay in sync with all customer bugs/issues, or feature request threads"
                        SvgIcon={SlackSvg}
                    />
                    {loadingStateIntegrationEnabled === loadingTypes.loaded && (
                        <Flex
                            style={{
                                width: "100%",
                                justifyContent: "flex-end",
                            }}
                        >
                            {!integrationEnabled ? (
                                <Button onClick={slackContinue}>Enable</Button>
                            ) : (
                                <Flex gap="2" direction="column">
                                    <Button disabled={true}>Enabled</Button>
                                    <Button
                                        size="1"
                                        onClick={slackContinue}
                                        mb={"20px"}
                                    >
                                        <ReloadIcon /> Refresh Scopes
                                    </Button>
                                </Flex>
                            )}
                        </Flex>
                    )}
                </Flex>
                {loadingStateIntegrationEnabled === loadingTypes.loading && (
                    <Flex
                        style={{
                            width: "100%",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Flex gap="2" direction="column" mb={"20px"}>
                            <Skeleton>
                                <Button>Enabled</Button>
                            </Skeleton>
                        </Flex>
                    </Flex>
                )}
                {loadingStateIntegrationEnabled === loadingTypes.error && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}

                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.loaded && integrationEnabled && (
                    <Flex direction={"column"} gap="2" mb="2">
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                                marginBottom: "20px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"center"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Flex direction="column">
                                        <Text size={"2"}>
                                            Customer Channels Selected:{" "}
                                        </Text>
                                        <Text size={"1"} color="gray">
                                            Channels that Assembly will listen
                                            to and create tickets for.
                                        </Text>
                                    </Flex>
                                    <Button
                                        variant="outline"
                                        size="1"
                                        onClick={addMoreListeningRepositories}
                                    >
                                        <PlusIcon width="15" height="15" /> Add
                                        More Channels
                                    </Button>
                                </Flex>
                                {asmTicketChannels?.length !== 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                {asmTicketChannels?.length !== 0 && (
                                    <Flex
                                        style={{
                                            paddingRight: "20px",
                                            paddingLeft: "20px",
                                        }}
                                        direction="row"
                                        gap="3"
                                        align="center"
                                        wrap="wrap"
                                    >
                                        {asmTicketChannels.map((item) => (
                                            <Badge key={item.name}>
                                                {item.name}
                                            </Badge>
                                        ))}{" "}
                                    </Flex>
                                )}
                            </Flex>
                        </Card>
                        <Card
                            style={{
                                padding: "20px",
                                marginBottom: "20px",
                            }}
                        >
                            <Flex
                                direction={"row"}
                                justify={"between"}
                                align={"center"}
                            >
                                <Flex direction={"column"}>
                                    <Text size={"2"}>Index Code</Text>
                                    <Text size={"1"}>
                                        Enable this to index code snippets in
                                        the conversations.
                                    </Text>
                                </Flex>
                                <Switch
                                    checked={indexCode}
                                    onCheckedChange={(checked) => {
                                        setIndexCode(checked);
                                        saveSettings(askAIEnabled, checked);
                                    }}
                                />
                            </Flex>
                        </Card>
                        <Card
                            style={{
                                paddingRight: "0px",
                                paddingLeft: "0px",
                            }}
                        >
                            <Flex
                                direction={"column"}
                                gap="2"
                                mb="2"
                                justify={"between"}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={"start"}
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "5px",
                                        paddingBottom: "5px",
                                    }}
                                >
                                    <Flex direction="column">
                                        <Text size={"2"}>
                                            Slack Channels Indexed:{" "}
                                        </Text>
                                        <Text size={"1"} color="gray">
                                            Index channels to add to your
                                            knowledge base.
                                        </Text>
                                    </Flex>
                                    <Button
                                        variant="outline"
                                        size="1"
                                        onClick={addMoreIndexingRepositories}
                                    >
                                        <PlusIcon width="15" height="15" /> Add
                                        More Channels
                                    </Button>
                                </Flex>
                                {additionalMetadata?.scopes?.length > 0 && (
                                    <Separator
                                        size={"4"}
                                        mt="1"
                                        mb="1"
                                        style={{ width: "100%" }}
                                    />
                                )}
                                <Flex
                                    style={{
                                        paddingRight: "20px",
                                        paddingLeft: "20px",
                                    }}
                                    direction="row"
                                    gap="3"
                                    align="center"
                                    wrap="wrap"
                                >
                                    {additionalMetadata.scopes.map((item) => (
                                        <Badge key={item}>{item}</Badge>
                                    ))}{" "}
                                </Flex>
                            </Flex>
                        </Card>
                        <Flex
                            direction="column"
                            style={{
                                justifyContent: "flex-end",
                                alignItems: "end",
                                marginBottom: "20px",
                            }}
                            gap="3"
                        >
                            {additionalMetadata.indexedOn && (
                                <Flex direction={"row"}>
                                    <Text size={"1"}>
                                        Last Updated:{" "}
                                        {additionalMetadata.indexedOn}{" "}
                                    </Text>
                                </Flex>
                            )}
                        </Flex>

                        {loadingState === loadingTypes.loaded && (
                            <Card
                                style={{
                                    padding: "20px",
                                    marginBottom: "20px",
                                }}
                            >
                                <Flex
                                    direction={"row"}
                                    justify={"between"}
                                    align={askAIEnabled ? "center" : "start"}
                                >
                                    <Flex direction={"column"}>
                                        <Text size={"2"}>Ask AI </Text>
                                        <Text size={"1"}>
                                            Enable our Assembly Slack bot to
                                            auto-respond to questions within
                                            Slack.
                                        </Text>
                                    </Flex>
                                    <Flex
                                        direction={"column"}
                                        gap="3"
                                        align={"end"}
                                    >
                                        <Switch
                                            checked={askAIEnabled}
                                            onCheckedChange={(checked) => {
                                                setAskAIEnabled(checked);
                                                saveSettings(
                                                    checked,
                                                    indexCode,
                                                );
                                            }}
                                        />

                                        {askAIEnabled && (
                                            <SlackConfigPage
                                                setOpen={setOpen}
                                                setToastText={setToastText}
                                                setToastSymbol={setToastSymbol}
                                                autoEscalate={autoEscalate}
                                                setAutoEscalate={
                                                    setAutoEscalate
                                                }
                                                previewMessage={previewMessage}
                                                setPreviewMessage={
                                                    setPreviewMessage
                                                }
                                                autoRespond={autoRespond}
                                                setAutoRespond={setAutoRespond}
                                                indexCode={indexCode}
                                                setIndexCode={setIndexCode}
                                                confidenceScore={
                                                    confidenceScore
                                                }
                                                setConfidenceScore={
                                                    setConfidenceScore
                                                }
                                                iconUrl={iconUrl}
                                                setIconUrl={setIconUrl}
                                                channels={asmTicketChannels}
                                                selectedChannels={
                                                    selectedChannels
                                                }
                                                setSelectedChannels={
                                                    setSelectedChannels
                                                }
                                                errDialogOpen={errDialogOpen}
                                                setErrDialogOpen={
                                                    setErrDialogOpen
                                                }
                                                isMain={true}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </Card>
                        )}

                        {loadingStateShortCuts === loadingTypes.loading && (
                            <Skeleton>
                                <Text>
                                    {[...Array(2)].map((_, index) => (
                                        <Text key={null}>{loremIpsum}</Text>
                                    ))}
                                </Text>
                            </Skeleton>
                        )}

                        {loadingStateShortCuts === loadingTypes.loaded && (
                            <Card
                                style={{
                                    paddingRight: "0px",
                                    paddingLeft: "0px",
                                }}
                                mb="5"
                            >
                                <Flex
                                    direction={"column"}
                                    gap="2"
                                    mb="2"
                                    justify={"between"}
                                >
                                    <Flex
                                        direction={"row"}
                                        justify={"between"}
                                        align={"center"}
                                        style={{
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            paddingTop: "5px",
                                            paddingBottom: "5px",
                                        }}
                                    >
                                        <Text size={"2"}>Add Shortcut: </Text>

                                        <Shortcuts
                                            enabledIntegrations={
                                                enabledIntegrations
                                            }
                                            saveSettings={updateShortcuts}
                                            description={`React to the slack message with the integration's emoji and we'll automatically create a ticket in the specified integration.`}
                                            botType="Slack"
                                        />
                                    </Flex>
                                    <Text
                                        style={{
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                        }}
                                        size="1"
                                        color="gray"
                                    >
                                        React to the slack message with the
                                        integration's emoji and we'll
                                        automatically create a ticket in the
                                        specified integration.
                                    </Text>
                                    {connectionsQuery.data?.length !== 0 && (
                                        <Separator
                                            size={"4"}
                                            mt="1"
                                            mb="1"
                                            style={{ width: "100%" }}
                                        />
                                    )}
                                    {connectionsQuery.data?.length !== 0 && (
                                        <Flex
                                            style={{
                                                paddingRight: "20px",
                                                paddingLeft: "20px",
                                            }}
                                            direction="column"
                                            gap="3"
                                            align="start"
                                            wrap="wrap"
                                        >
                                            <DataList.Root>
                                                {connectionsQuery.data?.map(
                                                    (item) => {
                                                        let integration =
                                                            item
                                                                ?.connected_integrations
                                                                ?.connection;
                                                        if (
                                                            item
                                                                .connected_integrations
                                                                .priority &&
                                                            item
                                                                .connected_integrations
                                                                .priority !== ""
                                                        ) {
                                                            integration = `${integration} ${item.connected_integrations.priority} Priority`;
                                                        }
                                                        return (
                                                            <DataList.Item
                                                                align="center"
                                                                key={
                                                                    integration
                                                                }
                                                            >
                                                                <DataList.Label minWidth="88px">
                                                                    {`${integration} ${integrationToEmoji[integration]}`}
                                                                </DataList.Label>
                                                                <DataList.Value>
                                                                    {item?.connected_integrations?.scopes.map(
                                                                        (
                                                                            badgeItem,
                                                                        ) => (
                                                                            <Badge
                                                                                radius="full"
                                                                                key={
                                                                                    badgeItem.key
                                                                                }
                                                                            >
                                                                                {
                                                                                    badgeItem?.name
                                                                                }
                                                                            </Badge>
                                                                        ),
                                                                    )}
                                                                </DataList.Value>
                                                            </DataList.Item>
                                                        );
                                                    },
                                                )}{" "}
                                            </DataList.Root>
                                        </Flex>
                                    )}
                                </Flex>
                            </Card>
                        )}
                    </Flex>
                )}
                <Toast.Provider swipeDirection="right">
                    <Toast.Root
                        className="ToastRoot"
                        open={open}
                        onOpenChange={setOpen}
                    >
                        <Toast.Title className="ToastTitle">
                            <Flex direction={"row"} align={"center"} gap="2">
                                <ToastSymbol color="green" />
                                {toastText}
                            </Flex>
                        </Toast.Title>
                    </Toast.Root>
                    <Toast.Viewport className="ToastViewport" />
                </Toast.Provider>
            </Box>
        </div>
    );
};

export default SlackIntegration;
