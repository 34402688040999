import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/component/shadcn/ui/popover";
import { Button } from "@/component/shadcn/ui/button";
import { Calendar } from "@/component/shadcn/ui/calendar";
import { LineChart as TremorChart } from "@tremor/react";
import { format } from "date-fns";
import { Text, Card, Flex, Skeleton } from "@radix-ui/themes";
import { CalendarIcon } from "@radix-ui/react-icons";
import {
    ResponsiveContainer,
    LineChart,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Line,
    CartesianGrid,
} from "recharts";
import type { DateRange } from "react-day-picker";
import type { ResponseTimeResponse, Teams } from "@/interfaces/serverData";
import type { ChartData } from "../AdminAnalyticsPage";
import FilterDropdown from "@/IssuesTable/FilterDropdown";
import { Filter } from "@/IssuesTable/Filter";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";

interface ResponseAnalyticsProps {
    teamID: string;
    setTeamID: React.Dispatch<React.SetStateAction<string>>;
    teamsQueryStatus: string;
    teamsQueryIsError: boolean;
    teamsQueryData: Teams[] | undefined;
    responseTimePerDay: ChartData[];
    responseChartData: ChartData[];
    responseTimeQueryStatus: string;
    responseTimeQueryIsError: boolean;
    responseTimeQueryData: ResponseTimeResponse | undefined;
    range: DateRange | undefined;
    setRange: React.Dispatch<React.SetStateAction<DateRange | undefined>>;
    playgroundFilters: Map<string, Set<string>>;
    setPlaygroundFilters: React.Dispatch<
        React.SetStateAction<Map<string, Set<string>>>
    >;
    handleFilterSelect: (type: string, value: string) => () => void;
}

const ResponseAnalyticsComponent = ({
    teamID,
    setTeamID,
    teamsQueryData,
    responseTimePerDay,
    responseChartData,
    responseTimeQueryStatus,
    responseTimeQueryIsError,
    responseTimeQueryData,
    range,
    setRange,
    playgroundFilters,
    setPlaygroundFilters,
    handleFilterSelect,
}: ResponseAnalyticsProps) => {
    return (
        <div className="flex flex-col gap-2">
            <Flex direction="row">
                <Popover>
                    <PopoverTrigger asChild>
                        <Button
                            variant="outline"
                            className="flex items-center gap-2 text-muted-foreground text-xs"
                        >
                            <CalendarIcon className="h-3 w-3" />
                            {range?.from ? (
                                format(range.from, "PP")
                            ) : (
                                <span>Oldest</span>
                            )}
                            <div>to</div>
                            {range?.to ? (
                                format(range.to, "PP")
                            ) : (
                                <span>Latest</span>
                            )}
                        </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                        <Calendar
                            mode="range"
                            selected={range}
                            onSelect={setRange}
                        />
                    </PopoverContent>
                </Popover>

                {Array.from(playgroundFilters.entries()).map(
                    ([type, values]) => (
                        <div key={type}>
                            <Filter
                                type={type}
                                values={values}
                                filters={playgroundFilters}
                                setFilters={setPlaygroundFilters}
                                handleItemSelect={handleFilterSelect}
                                topics={[]}
                                users={[]}
                                customerGroups={[]}
                                isSavedViewFilter={false}
                            />
                        </div>
                    ),
                )}
                <FilterDropdown
                    filters={playgroundFilters}
                    activeMenuFilterOptions={["Source"]}
                    handleItemSelect={handleFilterSelect}
                    directSelectFilterOptions={[]}
                    topics={[]}
                    users={[]}
                    customerGroups={[]}
                />

                <Select
                    value={teamID}
                    onValueChange={(value) => setTeamID(value)}
                >
                    <SelectTrigger>
                        <SelectValue placeholder="Select Team" />
                    </SelectTrigger>
                    <SelectContent>
                        {teamsQueryData?.map((team) => (
                            <SelectItem key={team.id} value={team.id}>
                                {team.team_name}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </Select>
            </Flex>

            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        Response Time Per Day Average
                    </Text>
                    {responseTimeQueryStatus === "loading" && <SkeletonText />}
                    {responseTimeQueryIsError && (
                        <Text>Error loading data</Text>
                    )}
                    {responseTimeQueryData && responseTimePerDay.length > 0 && (
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={responseTimePerDay}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    type="category"
                                    tickFormatter={(value) =>
                                        new Date(value).toLocaleDateString()
                                    }
                                />
                                <YAxis />
                                <Tooltip
                                    labelFormatter={(value) =>
                                        new Date(value).toLocaleDateString()
                                    }
                                    formatter={(value: number) => [
                                        `${value.toFixed(2)} seconds`,
                                        "Average Response Time",
                                    ]}
                                />
                                <Legend />
                                <Line
                                    type="monotone"
                                    dataKey="duration"
                                    stroke="#8884d8"
                                    name="Average Response Time"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </Flex>
            </Card>

            <Card>
                <Flex direction="column">
                    <Text size="3" weight="bold">
                        Rolling Average Response Time
                    </Text>
                    {responseTimeQueryStatus === "loading" && <SkeletonText />}
                    {responseTimeQueryIsError && (
                        <Text>Error loading data</Text>
                    )}
                    {responseTimeQueryData && (
                        <>
                            <Text size="2">
                                {responseTimeQueryData?.average.toFixed(2)}{" "}
                                seconds
                            </Text>
                            <TremorChart
                                data={responseChartData}
                                categories={["duration"]}
                                index="date"
                                colors={["blue"]}
                                yAxisWidth={48}
                            />
                        </>
                    )}
                </Flex>
            </Card>
        </div>
    );
};

const SkeletonText = () => (
    <Skeleton>
        <Text size="5" color="gray">
            Loading data...
        </Text>
    </Skeleton>
);

export default ResponseAnalyticsComponent;
