import { loadingTypes } from "@/constant";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    BotMetadata,
    OrgInfoResponse,
    ScopeResponse,
} from "@/interfaces/serverData";
import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import { AssemblyErrorMessage } from "@/reusable_components/loadingStates/ErrorMessage";
import {
    CheckCircledIcon,
    CrossCircledIcon,
    GitHubLogoIcon,
    Pencil1Icon,
    PlusIcon,
    ReloadIcon,
} from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Button,
    Card,
    Flex,
    Separator,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { metadata } from "../IntegrationsManagementPage";
import { readableIntegrationMapping } from "../constant";
import ModeratorCard from "./IntegrationCards/ModeratorCard";
import IntegrationHeader from "./IntegrationHeader";

const GitHubTicketIntegration = () => {
    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const gitHubTicketContinue = () => {
        // const githubLogin =
        //     "https://github.com/login/oauth/authorize?client_id=";

        // const scopes = "write:issues%20write:projects%20read:user";
        // const adminType = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/githubticket?type=user`;
        // const githubUrl = `${githubLogin}${process.env.REACT_APP_GITHUB_TICKETS_CLIENT_ID}&redirect_uri=${adminType}&scope=${scopes}`;
        const url = `https://github.com/apps/${process.env.REACT_APP_GITHUB_TICKET_APP_NAME}/installations/new`;
        window.location.assign(url);
    };

    const [additionalMetadata, setAdditionalMetadata] = useState<metadata>({
        scopes: [],
        indexedOn: "",
    });

    const [loadingState, setLoadingState] = useState<number>(
        loadingTypes.loading,
    );
    const [scopes, setScopes] = useState<ScopeResponse[]>([]);
    const [asmTicketChannels, setAsmTicketChannels] = useState<string[]>([]);
    const api = useApi();

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    const navigate = useNavigate();
    const addMoreRepositories = () => {
        navigate("/admin/integrations/update/githubticket");
    };

    useEffect(() => {
        const requestData = {
            types: ["GitHubTicket"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                const temp: metadata = { scopes: [], indexedOn: "" };

                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;

                        if (orgInfo.GitHubTicket) {
                            setIntegrationEnabled(true);
                        }

                        if (orgInfo.GitHubTicket?.scopes) {
                            for (const repo of orgInfo.GitHubTicket.scopes) {
                                temp.scopes.push(repo.name);
                            }
                        }

                        setAdditionalMetadata(temp);
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    useEffect(() => {
        if (integrationEnabled) {
            const requestData = {
                type: "GitHubTicket",
            };
            api.post(URLS.serverUrl + API.getScopes, requestData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((res) => {
                    const dataItems: ScopeResponse[] = res.data.data;
                    setScopes(dataItems);
                    api.get(
                        `${URLS.serverUrl}${API.getBotSettingsV2}/GitHubTicket`,
                        {
                            headers: {
                                "Content-Type": "application/json",
                            },
                        },
                    )
                        .then((res) => {
                            if (res.status === 200) {
                                const settingsData: BotMetadata = res.data.data;
                                const dataTemp: string[] = [];
                                if (settingsData.asm_ticket_channels) {
                                    for (const repo of settingsData.asm_ticket_channels) {
                                        dataTemp.push(repo.name);
                                    }
                                }
                                setAsmTicketChannels(dataTemp);

                                const spaceOwners: ScopeResponse[] = [];
                                if (settingsData.space_owners) {
                                    for (const space_owner of settingsData.space_owners) {
                                        spaceOwners.push(space_owner);
                                    }
                                }
                                setAdminGitHubUsers(spaceOwners);
                            } else {
                                setLoadingState(2);
                            }
                        })
                        .catch((res) => {
                            setLoadingState(2);
                        });
                })
                .catch((res) => {
                    console.log("Could not find GitHub Ticket Scopes");
                });
        }
    }, [api, integrationEnabled]);

    const [adminGitHubUsers, setAdminGitHubUsers] = useState<ScopeResponse[]>(
        [],
    );

    const [toastText, setToastText] = useState<string>("Updated Settings!");

    const [open, setOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);
    const timerRef = useRef(0);

    function saveBotSettings() {
        const requestData: BotMetadata = {
            bot_type: "GitHubTicket",
            space_owners: adminGitHubUsers,
        };

        api.post(
            `${URLS.serverUrl}${API.saveBotSettingsV2}/githubticket`,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((res) => {
                if (res.status !== 200) {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                } else {
                    setToastText("Updated Settings!");
                    setToastSymbol(CheckCircledIcon);
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setOpen(true);
                }, 100);
            });
    }

    return (
        <div className="bg-gray-500">
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType={
                            readableIntegrationMapping.get("githubticket") ??
                            "GitHub Issues"
                        }
                        description="Stay up to date with issues and automate GitHub issue creation with a “slash - / “ command or emojis"
                        SvgIcon={GitHubLogoIcon}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {loadingState === loadingTypes.loaded &&
                            !integrationEnabled ? (
                            <Button mb={"20px"} onClick={gitHubTicketContinue}>
                                Enable
                            </Button>
                        ) : (
                            <Flex gap="2" direction="column">
                                <Button disabled={true}>Enabled</Button>
                                <Button
                                    size="1"
                                    onClick={gitHubTicketContinue}
                                    mb={"20px"}
                                >
                                    <ReloadIcon /> Refresh Scopes
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                    {loadingState === loadingTypes.loaded &&
                        integrationEnabled && (
                            <>
                                <Card
                                    style={{
                                        paddingRight: "0px",
                                        paddingLeft: "0px",
                                        marginBottom: "20px",
                                        width: "100%",
                                    }}
                                >
                                    <Flex
                                        direction={"column"}
                                        gap="2"
                                        mb="2"
                                        justify={"between"}
                                    >
                                        <Flex
                                            direction={"row"}
                                            justify={"between"}
                                            align={"center"}
                                            style={{
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                            }}
                                        >
                                            <Flex direction="column">
                                                <Text size={"2"}>
                                                    Connected Repositories:{" "}
                                                </Text>
                                                <Text size={"1"} color="gray">
                                                    Repositories that Assembly
                                                    has access to.
                                                </Text>
                                            </Flex>
                                            <Button
                                                variant="outline"
                                                size="1"
                                                onClick={gitHubTicketContinue}
                                            >
                                                <Pencil1Icon
                                                    width="15"
                                                    height="15"
                                                />
                                                Edit Repositories
                                            </Button>
                                        </Flex>
                                        {scopes?.length !== 0 && (
                                            <Separator
                                                size={"4"}
                                                mt="1"
                                                mb="1"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                        {scopes?.length !== 0 && (
                                            <Flex
                                                style={{
                                                    paddingRight: "20px",
                                                    paddingLeft: "20px",
                                                }}
                                                direction="row"
                                                gap="3"
                                                align="center"
                                                wrap="wrap"
                                            >
                                                {scopes.map((item) => (
                                                    <Badge key={item.name}>
                                                        {item.name}
                                                    </Badge>
                                                ))}{" "}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Card>
                                <Card
                                    style={{
                                        paddingRight: "0px",
                                        paddingLeft: "0px",
                                        marginBottom: "20px",
                                        width: "100%",
                                    }}
                                >
                                    <Flex
                                        direction={"column"}
                                        gap="2"
                                        mb="2"
                                        justify={"between"}
                                    >
                                        <Flex
                                            direction={"row"}
                                            justify={"between"}
                                            align={"center"}
                                            style={{
                                                paddingLeft: "20px",
                                                paddingRight: "20px",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                            }}
                                        >
                                            <Flex direction="column">
                                                <Text size={"2"}>
                                                    Repositories Selected:{" "}
                                                </Text>
                                                <Text size={"1"} color="gray">
                                                    Repositories that Assembly will
                                                    listen to and create tickets
                                                    for.
                                                </Text>
                                            </Flex>
                                            <Button
                                                variant="outline"
                                                size="1"
                                                onClick={addMoreRepositories}
                                            >
                                                <PlusIcon
                                                    width="15"
                                                    height="15"
                                                />{" "}
                                                Add More Channels
                                            </Button>
                                        </Flex>
                                        {asmTicketChannels?.length !== 0 && (
                                            <Separator
                                                size={"4"}
                                                mt="1"
                                                mb="1"
                                                style={{ width: "100%" }}
                                            />
                                        )}
                                        {asmTicketChannels?.length !== 0 && (
                                            <Flex
                                                style={{
                                                    paddingRight: "20px",
                                                    paddingLeft: "20px",
                                                }}
                                                direction="row"
                                                gap="3"
                                                align="center"
                                                wrap="wrap"
                                            >
                                                {asmTicketChannels.map(
                                                    (item) => (
                                                        <Badge key={item}>
                                                            {item}
                                                        </Badge>
                                                    ),
                                                )}{" "}
                                            </Flex>
                                        )}
                                    </Flex>
                                </Card>

                                <ModeratorCard
                                    adminUsers={adminGitHubUsers}
                                    setAdminUsers={setAdminGitHubUsers}
                                    saveBotSettings={saveBotSettings}
                                    className="mb-4"
                                    botType="GitHubTicket"
                                    integrationEnabled={integrationEnabled}
                                    getName={(option: ScopeResponse) => `${option.name}`} integrationName={""} />
                            </>
                        )}
                </Flex>
                {loadingState === loadingTypes.loading && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}
                {loadingState === loadingTypes.error && (
                    <AssemblyErrorMessage />
                )}
            </Box>
            <AssemblyToastProvider
                open={open}
                setOpen={setOpen}
                toastText={toastText}
                ToastSymbol={ToastSymbol}
            />
        </div>
    );
};

export default GitHubTicketIntegration;
