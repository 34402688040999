import { Card } from "@/component/shadcn/ui/card";
import { ScrollArea } from "@/component/shadcn/ui/scroll-area";
import type { GetTopicsResponse, Insight, } from "@/interfaces/serverData";
import { memo, useEffect, useState } from "react";

import { FixedSizeList } from "react-window";
import InsightsListCard from "./InsightsListCard";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import FilterBar from "./FilterBar";
import { Dialog } from "@radix-ui/themes";
import { InsightDisplay } from "./InsightDisplay";
import { arraysAreEqual } from "@/utilities/methods";
import { Button } from "@/component/shadcn/ui/button";
import { CaretDownIcon, CaretUpIcon } from "@radix-ui/react-icons";

const areEqual = (
    prevProps: SuggestedInsightsListProps,
    nextProps: SuggestedInsightsListProps,
) => {
    return (
        arraysAreEqual(
            prevProps.suggestedInsights,
            nextProps.suggestedInsights,
        ) &&
        arraysAreEqual(
            prevProps.generatedInsights,
            nextProps.generatedInsights,
        ) &&
        prevProps.issuesCount === nextProps.issuesCount &&
        prevProps.userID === nextProps.userID &&
        prevProps.handleSaveAIGeneratedInsight ===
        nextProps.handleSaveAIGeneratedInsight &&
        prevProps.topicsMap === nextProps.topicsMap &&
        prevProps.isOpen === nextProps.isOpen &&
        prevProps.setIsOpen === nextProps.setIsOpen &&
        prevProps.playgroundIsOpen === nextProps.playgroundIsOpen
    );
};

interface SuggestedInsightsListProps {
    suggestedInsights: Insight[];
    generatedInsights: Insight[];
    issuesCount: number;
    userID: string;
    handleSaveAIGeneratedInsight: (insight: Insight) => Promise<void>;
    topicsMap: Map<string, GetTopicsResponse>;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    playgroundIsOpen: boolean;
}

function SuggestedInsightsList({
    suggestedInsights,
    generatedInsights,
    issuesCount,
    userID,
    handleSaveAIGeneratedInsight,
    topicsMap,
    isOpen,
    setIsOpen,
    playgroundIsOpen,
}: SuggestedInsightsListProps) {
    const itemSize = 65;
    const [filteredInsights, setFilteredInsights] =
        useState<Insight[]>(suggestedInsights);
    useEffect(() => {
        setFilteredInsights(suggestedInsights);
    }, [suggestedInsights]);

    const [listItems, setListItems] = useState<
        { key: string; content: JSX.Element }[]
    >([]);

    const [popUpHeight, setPopUpHeight] = useState<number>(
        window.innerHeight * 0.9,
    );
    // Update the pop up height when the window resizes
    useEffect(() => {
        const handleResize = () => setPopUpHeight(window.innerHeight * 0.9);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const newListItems: { key: string; content: JSX.Element }[] = [];

        for (const insight of filteredInsights) {
            newListItems.push({
                key: insight.id,
                content: (
                    <Dialog.Root>
                        <Dialog.Trigger>
                            <button type="button" className="w-full">
                                <InsightsListCard
                                    insight={insight}
                                    userID={userID}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                    saved={false}
                                    topicsMap={topicsMap}
                                />
                            </button>
                        </Dialog.Trigger>

                        <Dialog.Content
                            style={{ maxHeight: `${popUpHeight}px` }}
                        >
                            <Dialog.Description
                                size="2"
                                mb="4"
                                className="pt-3 z-50 -mb-20"
                            >
                                <InsightDisplay
                                    insight={insight}
                                    userID={userID}
                                    topicsMap={topicsMap}
                                    insightSelectedIsSaved={false}
                                    handleSaveAIGeneratedInsight={
                                        handleSaveAIGeneratedInsight
                                    }
                                />
                            </Dialog.Description>
                        </Dialog.Content>
                    </Dialog.Root>
                ),
            });
        }
        setListItems(newListItems);
    }, [filteredInsights, userID]);

    return (
        <Card className="m-2 rounded-sm w-full flex flex-col relative">
            <div className="flex items-center justify-between">
                <div className="flex flex-col gap-0.5 px-7 py-2.5">
                    <div className="flex items-center gap-1 text-[15px] font-semibold">
                        Suggested AI Insights{" "}
                        <img
                            src={SparklesIcon}
                            alt=""
                            className="h-7 w-7 rounded p-1"
                        />
                    </div>
                    <div className="text-xs text-muted-foreground">
                        The top most recommended AI generated insights based on
                        your recent issues.
                    </div>
                </div>
                {isOpen ? (
                    <Button
                        variant="ghost"
                        className="text-xs p-1 absolute top-2 right-2"
                        onClick={() => setIsOpen(false)}
                    >
                        <CaretUpIcon className="w-5 h-5" />
                    </Button>
                ) : (
                    <Button
                        variant="ghost"
                        className="text-xs p-1 absolute top-2 right-2"
                        onClick={() => setIsOpen(true)}
                    >
                        <CaretDownIcon className="w-5 h-5" />
                    </Button>
                )}
            </div>
            {isOpen && (
                <div>
                    <FilterBar
                        insights={suggestedInsights}
                        filteredInsights={filteredInsights}
                        setFilteredInsights={setFilteredInsights}
                    />
                </div>
            )}
            {isOpen && suggestedInsights.length === 0 &&
                (issuesCount === 0 ?
                    (
                        <div className="flex items-center gap-2 px-7 pb-3 text-xs flex items-center rounded-lg w-full">
                            No recent data available, please create issues or index past issues.
                        </div>
                    ) : (
                        <div className="flex items-center gap-2 px-7 pb-3 text-xs flex items-center rounded-lg w-full">
                            Generating AI Insights{" "}
                            <div className="w-3 h-3 border-2 border-t-4 border-primary border-dashed rounded-full animate-spin" />
                        </div>
                    ))}
            {isOpen && suggestedInsights.length > 0 && (
                <ScrollArea className="h-full w-full">
                    <FixedSizeList
                        width={"100%"}
                        height={
                            playgroundIsOpen
                                ? generatedInsights.length > 0
                                    ? window.innerHeight * 0.27
                                    : window.innerHeight * 0.45
                                : window.innerHeight * 0.56
                        }
                        itemCount={listItems.length}
                        itemSize={itemSize}
                    >
                        {({ index, style }) => {
                            const item = listItems[index];
                            return (
                                <div style={style} key={item.key}>
                                    {item.content}
                                </div>
                            );
                        }}
                    </FixedSizeList>
                </ScrollArea>
            )}
        </Card>
    );
}

export default memo(SuggestedInsightsList, areEqual);
