import { Button } from "@/component/shadcn/ui/button";
import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { PlusCircledIcon } from "@radix-ui/react-icons";

import { memo, useCallback, useMemo, useState } from "react";

import type { CustomerGroup, GetUserResponse } from "@/interfaces/serverData";
import FilterDropdownElement from "./FilterDropdownElement";

interface FilterDropdownProps {
    filters: Map<string, Set<string>>;
    activeMenuFilterOptions: string[]; // Resets the active menu and presents different options (i.e. assignee, tag, etc)
    directSelectFilterOptions: string[]; // Directly adds a filter, sets the value to true (i.e. assembly bot)
    handleItemSelect: (type: string, value: string) => () => void;
    topics: { color: string; label: string; value: string }[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
}

function FilterDropdown({
    filters,
    activeMenuFilterOptions,
    directSelectFilterOptions,
    handleItemSelect,
    topics,
    users,
    customerGroups,
}: FilterDropdownProps) {
    const [activeMenu, setActiveMenu] = useState<string | null>(null);

    const handleMenuClick = useCallback(
        (menu: string) => () => {
            // Reset activeMenu when dropdown is closed
            setActiveMenu(menu);
        },
        [],
    );

    const handleOpenChange = useCallback((open: boolean) => {
        if (!open) {
            setActiveMenu(null);
        }
    }, []);

    const memoizedFilterDropdownElement = useMemo(() => {
        return activeMenuFilterOptions.map(
            (type) =>
                activeMenu === type && (
                    <FilterDropdownElement
                        key={type}
                        type={type}
                        filters={filters}
                        handleItemSelect={handleItemSelect}
                        topics={topics}
                        users={users}
                        customerGroups={customerGroups}
                        isSavedViewFilter={false}
                    />
                ),
        );
    }, [activeMenu, filters, handleItemSelect, topics, users]);

    return (
        <DropdownMenu onOpenChange={handleOpenChange}>
            <DropdownMenuTrigger asChild type="button">
                <Button
                    variant="ghost"
                    size="sm"
                    className="flex items-center gap-2 my-1 py-0 px-1 text-muted-foreground hover:text-gray-950"
                >
                    <PlusCircledIcon className="w-4 h-4 text-gray-700" />
                    {filters.size === 0 && (
                        <div className="text-xs">Filter</div>
                    )}
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
                align="start"
                className="fixed w-[320px] max-h-80 p-0 bg-muted rounded-md shadow-lg"
            >
                {activeMenu === null && (
                    <Command className="rounded-md shadow-md text-xs pb-1">
                        <CommandInput
                            placeholder="Filter..."
                            className="px-1"
                        />
                        <CommandList>
                            <CommandEmpty className="text-xs px-4 py-2">
                                No results found.
                            </CommandEmpty>
                            {activeMenuFilterOptions.map((type) => (
                                <CommandItem className="px-1 py-0.5" key={type}>
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        onClick={handleMenuClick(type)}
                                        className="text-xs rounded-md text-gray-700 hover:text-gray-950 w-full my-0 py-1.5 text-left flex justify-start"
                                    >
                                        <span className="ml-1">{type}</span>
                                    </Button>
                                </CommandItem>
                            ))}
                            {directSelectFilterOptions.map((type) => (
                                <CommandItem className="px-1 py-0.5" key={type}>
                                    <Button
                                        type="button"
                                        variant="ghost"
                                        onClick={handleItemSelect(type, "true")}
                                        className="text-xs rounded-md text-gray-700 hover:text-gray-950 w-full my-0 py-1.5 text-left flex justify-start"
                                    >
                                        <span className="ml-1">{type}</span>
                                    </Button>
                                </CommandItem>
                            ))}
                        </CommandList>
                    </Command>
                )}
                {memoizedFilterDropdownElement}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}

export default memo(FilterDropdown);
