import { Card } from "@/component/shadcn/ui/card";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/component/shadcn/ui/tooltip";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    GetUserResponse,
    HistoryResponse,
    Query,
    Ticket,
} from "@/interfaces/serverData";
import { TriangleDownIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";

interface AssigneeDropdownProps<TData> {
    ticket: Ticket;
    userID: string;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
}

export function AssigneeDropdown<TData extends Query>({
    ticket,
    userID,
    refetchThreadData,
}: AssigneeDropdownProps<TData>) {
    const api = useApi();
    const assignText = "Unassigned...";

    const [user, setUser] = useState<string>(assignText);
    const [enabled, setEnabled] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<GetUserResponse>();
    const [pictureURL, setPictureURL] = useState<string>("");

    function saveAssignee(assignee: GetUserResponse) {
        const requestData = {
            id: ticket.id,
            source: "Web",
            user_id: userID,
            assignee_user_id: assignee.id,
        };
        api.patch(URLS.serverUrl + API.saveTicket, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setUser(`${assignee.first_name} ${assignee.last_name ?? ""}`);
                setPictureURL(assignee.picture_url);
                setUserInfo(assignee);
                refetchThreadData();
                setEnabled(true);
            } else {
                setEnabled(false);
                console.log("Call to update tag failed");
            }
        });
    }

    const [users, setUsers] = useState<GetUserResponse[]>([]);

    useEffect(() => {
        api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.data);
            } else {
                console.log("Call to update tag failed");
            }
        });
    }, [api]);

    useEffect(() => {
        if (ticket.assignee_user_id) {
            const userData = users.find(
                (user) => user.id === ticket.assignee_user_id,
            );
            if (
                userData &&
                userData.first_name !== "" &&
                userData.last_name !== ""
            ) {
                setUser(`${userData.first_name} ${userData.last_name}`);
                setUserInfo(userData);
                setEnabled(true);
            } else {
                setEnabled(false);
            }
        }
    }, [ticket.assignee_user_id, api, users]);

    return (
        <div className="text-xs flex items-center">
            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="medium"
                        variant="outline"
                        className="ring-[0.8px] text-gray-700 ring-[#E0E1E6]"
                    >
                        <div className="lb-root flex flex-row gap-1 rounded-lg lb-comment-header mb-0 lb-comment-details">
                            {enabled ? (
                                <TooltipProvider>
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <div
                                                className={
                                                    "lb-avatar rounded-lg w-5 h-4"
                                                }
                                            >
                                                {pictureURL && (
                                                    <img
                                                        className="lb-avatar-image"
                                                        src={pictureURL}
                                                        alt={user[0]}
                                                    />
                                                )}
                                                <span>{user[0] ?? ""}</span>
                                            </div>
                                        </TooltipTrigger>
                                        <TooltipContent
                                            sideOffset={5}
                                            className="bg-white text-gray11 mb-2 mt-3 flex flex-col opacity-100 mr-3"
                                            asChild
                                        >
                                            <Card className="bg-white text-gray11 p-3 mb-2 flex gap-1 flex-col text-left align-start opacity-100">
                                                <p className="font-bold ">
                                                    {userInfo?.email ?? ""}
                                                </p>
                                            </Card>
                                        </TooltipContent>
                                    </Tooltip>
                                </TooltipProvider>
                            ) : (
                                <div className={"lb-avatar rounded-lg w-6 h-5"}>
                                    {pictureURL && (
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={user[0]}
                                        />
                                    )}
                                    <span>{user[0] ?? ""}</span>
                                </div>
                            )}
                            <div className="lb-comment-author text-xs font-medium">
                                {user}
                            </div>
                            <TriangleDownIcon className="h-3 w-3" />
                        </div>
                    </Badge>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                    side="bottom"
                    align="end"
                    className="w-[200px] p-2"
                >
                    {users.map((user) => (
                        <DropdownMenuItem
                            key={user.id}
                            className="py-1 hover:bg-muted cursor-pointer flex items-center"
                            onSelect={() => saveAssignee(user)}
                        >
                            <div className="lb-root rounded-xl lb-comment-header mb-0 lb-comment-details">
                                <div className={"lb-avatar w-5 h-5"}>
                                    {user.picture_url && (
                                        <img
                                            className="lb-avatar-image"
                                            src={user.picture_url}
                                            alt={`${user.first_name} ${user.last_name}`}
                                        />
                                    )}
                                    <span>
                                        {`${user.first_name} ${user.last_name}`}
                                    </span>
                                </div>
                                <span
                                    className="lb-comment-author text-xs
                                font-normal"
                                >
                                    {`${user.first_name} ${user.last_name}`}
                                </span>
                            </div>
                        </DropdownMenuItem>
                    ))}
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}
