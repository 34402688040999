import { APIWithMethod, URLS } from "@/constant";
import type { View } from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { Skeleton, Text } from "@radix-ui/themes";
import { useQuery } from "@tanstack/react-query";
import type React from "react";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import AdminQueriesPage, { IssueListType } from "../Admin/AdminQueriesPage";

export const ViewViewer: React.FC = () => {
    const { id } = useParams<{ id: string }>();

    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const { data, isLoading, error, refetch } = useQuery<View>({
        queryKey: ["view", id],
        queryFn: async () => {
            const [url, method] = APIWithMethod.getView;
            const response = await fetch(`${URLS.serverUrl}${url}/${id}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const result = await response.json();
            // Manually convert plain object back to Map
            const filters = new Map(
                Object.entries(result.data.filters).map(([key, value]) => [
                    key,
                    new Set(value as string[]),
                ]),
            );

            return { ...result.data, filters };
        },
    });

    useEffect(() => {
        if (id) {
            refetch(); // Trigger a refetch when the id changes/switch to another view
        }
    }, [id, refetch]);

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    return (
        <>
            {isLoading && (
                <div>
                    <Skeleton>
                        <Text>
                            {[...Array(6)].map((_, index) => (
                                // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                <Text key={index}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                </div>
            )}
            {error && <div>Error: {error.message}</div>}
            {data && (
                <AdminQueriesPage
                    // biome-ignore lint/style/noNonNullAssertion: <explanation>
                    userID={authInfo.user?.userId!}
                    listType={IssueListType.Issues}
                    tableView={true}
                    hidePopup={false}
                    filters={data.filters}
                    groups={data.group}
                    name={data.name}
                    description={data.description}
                    viewId={data.id}
                />
            )}
        </>
    );
};
