import { Button } from "@/component/shadcn/ui/button";
import { API, ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { Account, GetUserResponse } from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import {
    CheckCircledIcon,
    CrossCircledIcon,
    InfoCircledIcon,
    OpenInNewWindowIcon,
    Pencil1Icon,
    PlusIcon,
} from "@radix-ui/react-icons";
import {
    Badge,
    Box,
    Callout,
    DropdownMenu,
    Flex,
    Heading,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generateCustomerColumms } from "./CustomersTable/columns";

import AccountPopup from "./AccountPopup";

import { DataTableNew } from "@/pages/WorkspacePreferences/DataTable/data-table-new";

import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuPortal,
    ContextMenuTrigger,
} from "@/component/shadcn/ui/context-menu";

import AssemblyToastProvider from "@/reusable_components/actions/ToastProvider";
import type { Row } from "@tanstack/react-table";

export interface CompaniesProps {
    orgID: string;
}

export const CompaniesPage = ({ orgID }: CompaniesProps) => {
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);
    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const companiesQuery = useQuery<Account[]>({
        queryKey: ["companies", orgID],
        queryFn: async () => {
            const { url, method } = ContactsAPI.listCompanies;
            const res = await fetch(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            const data = await res.json();
            return data.data ?? [];
        },
    });
    const api = useApi();

    const updateData = async () => {
        queryClient.refetchQueries({
            queryKey: ["companies"],
            exact: true,
        });
    };

    const [users, setUsers] = useState<GetUserResponse[]>([]);

    const [toastText, setToastText] = useState<string>("Updated Settings!");

    const [toastOpen, setToastOpen] = useState<boolean>(false);
    const [ToastSymbol, setToastSymbol] =
        useState<React.ElementType>(CheckCircledIcon);

    useEffect(() => {
        api.post(URLS.serverUrl + API.getAllUsers, {
            headers: {
                "Content-Type": "application/json",
            },
        }).then((res) => {
            if (res.status === 200) {
                setUsers(res.data.data);
            } else {
                console.log("Call to grab users failed");
            }
        });
    }, [api]);

    const [rowState, setRowState] = useState<Map<string, Account>>(new Map());

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const handleRowClick = (row: Row<Account>) => {
        navigate(getNavLink(row));
    };

    const getNavLink = (row: Row<Account>) => {
        if (row.original.company_account === "Company") {
            return `/companies/${row.original.id}`;
        }
        return `/companies/individual/${row.original.id}`;
    };

    const handleOpenInNewTab = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        row: Row<Account> | undefined,
    ) => {
        event.stopPropagation();
        if (row) {
            window.open(getNavLink(row), "_blank");
        }
    };

    const timerRef = useRef<number>(0);

    const handleDelete = (id: string | undefined) => {
        if (!id) {
            return;
        }
        api.post(`${URLS.serverUrl}${ContactsAPI.deleteCompany.url}/${id}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    updateData();
                    setToastOpen(true);
                    setToastSymbol(CheckCircledIcon);
                    setToastText("Deleted Company!");
                } else {
                }
            })
            .catch((err) => {
                console.log(err);
                setToastSymbol(CrossCircledIcon);
                setToastText("Failed to delete company");
            })
            .finally(() => {
                setToastOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    setToastOpen(true);
                }, 100);
            });
    };

    const [contextMenuRow, setContextMenuRow] = useState<
        Row<Account> | undefined
    >();

    const handleRowRightClick = (row: Row<Account>) => {
        setContextMenuRow(row);
    };
    return (
        <Flex direction="column" align="center" justify="center">
            <Box mt="7" height="100%" width="98%">
                <Flex
                    align="start"
                    direction="column"
                    justify={"start"}
                    style={{ paddingLeft: "20px", paddingBottom: "20px" }}
                >
                    <Heading size="5" align="left">
                        Accounts
                    </Heading>
                    <div className="flex flex-row gap-2 items-start">
                        <Text mb="12px" size="2">
                            Manage external accounts you stay in touch with
                        </Text>
                        <Badge size="1" color="green">
                            BETA
                        </Badge>
                    </div>
                    <Callout.Root size="1">
                        <Callout.Text className="flex flex-row gap-2 items-center">
                            <InfoCircledIcon />
                            <Text className="text-xs">
                                This feature currently represents new accounts
                                added to Assembly after September 25th, 2024.
                            </Text>
                        </Callout.Text>
                    </Callout.Root>
                </Flex>
                <div className="flex items-center absolute top-4 right-4 my-4">
                    <DropdownMenu.Root>
                        <DropdownMenu.Trigger>
                            <Button
                                className="outline outline-1 outline-slate-200 flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted shadow-sm mb-5"
                                size="sm"
                                variant="outline"
                            >
                                Add
                                <PlusIcon />
                            </Button>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content>
                            <AccountPopup
                                triggerElement={
                                    <DropdownMenu.Item
                                        onSelect={(e) => e.preventDefault()}
                                    >
                                        Company
                                    </DropdownMenu.Item>
                                }
                                editing={false}
                                setToastOpen={setToastOpen}
                                setToastSymbol={setToastSymbol}
                                setToastText={setToastText}
                                type="Company"
                            />
                            <AccountPopup
                                triggerElement={
                                    <DropdownMenu.Item
                                        onSelect={(e) => e.preventDefault()}
                                    >
                                        Account
                                    </DropdownMenu.Item>
                                }
                                editing={false}
                                setToastOpen={setToastOpen}
                                setToastSymbol={setToastSymbol}
                                setToastText={setToastText}
                                type="Account"
                            />
                        </DropdownMenu.Content>
                    </DropdownMenu.Root>
                </div>

                {companiesQuery.isLoading && (
                    <Flex maxWidth="85%" style={{ paddingLeft: "20px" }}>
                        <Text>
                            <Skeleton maxWidth="85%">
                                {[...Array(6)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Skeleton>
                        </Text>
                    </Flex>
                )}
                {companiesQuery.isError && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}

                <AssemblyToastProvider
                    open={toastOpen}
                    ToastSymbol={ToastSymbol}
                    toastText={toastText}
                    setOpen={setToastOpen}
                />

                <ContextMenu>
                    <ContextMenuTrigger className={`[data-state='open']`}>
                        {companiesQuery.data && (
                            <div className="flex flex-col gap-2 px-2">
                                <DataTableNew<Account, string>
                                    columns={generateCustomerColumms(
                                        rowState,
                                        handleRowClick,
                                    )}
                                    data={companiesQuery.data}
                                    handleRowRightClick={handleRowRightClick}
                                />
                            </div>
                        )}
                    </ContextMenuTrigger>
                    <ContextMenuPortal forceMount>
                        <ContextMenuContent className="w-60">
                            <ContextMenuItem
                                inset
                                className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                onClick={(event) => {
                                    handleOpenInNewTab(event, contextMenuRow);
                                }}
                            >
                                <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                                Open in New Tab
                            </ContextMenuItem>
                            <AccountPopup
                                triggerElement={
                                    <ContextMenuItem
                                        inset
                                        onSelect={(e) => e.preventDefault()}
                                        className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                    >
                                        <Pencil1Icon className="w-3.5 h-3.5" />
                                        Edit
                                    </ContextMenuItem>
                                }
                                editing={true}
                                setToastOpen={setToastOpen}
                                setToastSymbol={setToastSymbol}
                                setToastText={setToastText}
                                type={
                                    contextMenuRow?.original.company_account ===
                                    "Company"
                                        ? "Company"
                                        : "Account"
                                }
                                editingObject={contextMenuRow?.original}
                            />
                            {/* <ContextMenuItem
                                inset
                                className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                onClick={(event) => {
                                    handleDelete(contextMenuRow?.original.id);
                                }}
                            >
                                <TrashIcon className="w-3.5 h-3.5" />
                                Delete
                            </ContextMenuItem> */}
                        </ContextMenuContent>
                    </ContextMenuPortal>
                </ContextMenu>
            </Box>
        </Flex>
    );
};
