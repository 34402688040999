import {
    Command,
    CommandEmpty,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/component/shadcn/ui/command";
import type { CustomerGroup, GetUserResponse, ScopeResponse } from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    getColorLight,
    getOptions,
    getPlural,
    getStatusIcon,
} from "@/utilities/methods";
import {
    AvatarIcon,
    CheckIcon,
    ComponentBooleanIcon,
    CrossCircledIcon,
    DotFilledIcon,
    GroupIcon,
    QuestionMarkIcon,
} from "@radix-ui/react-icons";
import { Badge, Button } from "@radix-ui/themes";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import { statusPriorityOrder } from "./constants";

export const ElementDisplay = (
    type: string,
    label: string,
    value: string,
    color: string,
    filters: Map<string, Set<string>>,
    users: GetUserResponse[],
    customerGroups: CustomerGroup[],
) => {
    const isSelected = filters.get(type)?.has(value);
    switch (type) {
        case "Source": {
            const Icon =
                integrationBackEndDataMappingToSvg.get(value) ??
                QuestionMarkIcon;
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Broadcast": {
            const Icon =
                integrationBackEndDataMappingToSvg.get(value) ??
                QuestionMarkIcon;
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Tag":
            return (
                <div className="flex items-center gap-2">
                    <Badge
                        color={"gray"}
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0 rounded-xl py-1 px-2"
                    >
                        <div className="flex flex-row items-center">
                            {value === "AI_MATCH_TAG" ? (
                                <img
                                    src={SparklesIcon}
                                    alt=""
                                    className="h-4 w-4"
                                />
                            ) : (
                                <ComponentBooleanIcon
                                    color={getColorLight(value)}
                                />
                            )}

                            <p className="pl-0.5">{label}</p>
                        </div>
                    </Badge>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        case "Topic":
            return (
                <div className="flex items-center gap-2">
                    <Badge
                        color="gray"
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0 rounded-xl py-1 px-2"
                    >
                        <div className="flex flex-row items-center">
                            {value === "AI_MATCH_TOPIC" ? (
                                <img
                                    src={SparklesIcon}
                                    alt=""
                                    className="h-4 w-4"
                                />
                            ) : value === "NONE" ? (
                                <CrossCircledIcon className="mr-1" />
                            ) : (
                                <DotFilledIcon
                                    color={color !== "" ? color : "#9B9EF0"}
                                    style={{
                                        transform: "scale(1.8)",
                                    }}
                                />
                            )}

                            <p className="pl-0.3">{value}</p>
                        </div>
                    </Badge>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        case "Status": {
            const Icon = getStatusIcon(value ?? "Unknown");
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Assignee": {
            const pictureURL = users.find(
                (user) => user.id === value,
            )?.picture_url;
            return (
                <div className="flex items-center gap-2">
                    <div className="lb-avatar rounded-lg w-5 h-5">
                        {pictureURL ? (
                            <img
                                className="lb-avatar-image"
                                src={pictureURL}
                                alt={label}
                            />
                        ) : (
                            <AvatarIcon className="w-6 h-6" />
                        )}
                    </div>
                    <span
                        className="lb-comment-author text-xs font-normal font-destructive"
                    >
                        {label}
                    </span>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        case "Customer Group": {
            return (
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-2">
                        <GroupIcon />
                        {label}
                    </div>
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
        }
        default:
            return (
                <div className="flex items-center gap-2">
                    {label}
                    {isSelected && <CheckIcon className="h-4 w-4" />}
                </div>
            );
    }
};

interface FilterDropdownElementProps {
    type: string;
    filters: Map<string, Set<string>>;
    handleItemSelect: (type: string, value: string) => () => void;
    topics: { color: string; label: string; value: string }[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    isSavedViewFilter: boolean;
    extraOptions?: { color: string; label: string; value: string }[];
    channels?: Map<string, ScopeResponse[]>;
    key?: string;
}

function FilterDropdownElement({
    type,
    filters,
    handleItemSelect,
    topics,
    users,
    customerGroups,
    isSavedViewFilter,
    extraOptions,
    channels,
    key,
}: FilterDropdownElementProps) {
    const options: { label: string; value: string; color: string }[] =
        // TODO: the last key is new, check it didn't break anything
        getOptions(
            type,
            topics,
            users,
            customerGroups,
            // biome-ignore lint/style/noNonNullAssertion: <explanation>
            channels?.get(key!),
            channels ? Array.from(channels.keys()) : undefined,
        ) ?? [];

    return (
        <Command className="rounded-md shadow-md text-xs pb-1" aria-disabled>
            <CommandInput
                placeholder={`Filter ${type.toLowerCase()}...`}
                className="px-1"
            />
            <CommandList className="space-y-1">
                <CommandEmpty className="text-xs px-4 py-2">
                    {`No ${getPlural(type)} found`}
                </CommandEmpty>
                {extraOptions?.map((extraOption) => (
                    <CommandItem
                        key={extraOption.value}
                        className="px-1 py-0.5"
                    >
                        <Button
                            type="button"
                            variant="ghost"
                            onClick={handleItemSelect(type, extraOption.value)}
                            className="flex items-center justify-between text-xs rounded-md text-gray-700 hover:text-gray-950 w-full px-3 py-1"
                        >
                            {ElementDisplay(
                                type,
                                extraOption.label,
                                extraOption.value,
                                extraOption.color,
                                filters,
                                users,
                                customerGroups,
                            )}
                        </Button>
                    </CommandItem>
                ))}
                {options
                    .sort((a, b) => {
                        // Statuses has a specific priority order
                        if (type === "Status") {
                            return (
                                statusPriorityOrder.indexOf(a.value) - statusPriorityOrder.indexOf(b.value)
                            );
                        }
                        const prioritizeLabel = "No Assignee";
                        if (
                            a.label === prioritizeLabel &&
                            b.label !== prioritizeLabel
                        ) {
                            return -1;
                        }
                        if (
                            b.label === prioritizeLabel &&
                            a.label !== prioritizeLabel
                        ) {
                            return 1;
                        }
                        // Default sorting by label if neither is prioritized
                        return a.label.localeCompare(b.label);
                    })
                    .map((option) => (
                        <CommandItem key={option.value} className="px-1 py-0.5">
                            <Button
                                type="button"
                                variant="ghost"
                                onClick={isSavedViewFilter ? undefined : handleItemSelect(type, option.value)}
                                className="flex items-center justify-between text-xs rounded-md text-gray-700 hover:text-gray-950 w-full px-3 py-1"
                            >
                                {ElementDisplay(
                                    type,
                                    option.label,
                                    option.value,
                                    option.color,
                                    filters,
                                    users,
                                    customerGroups
                                )}
                            </Button>
                        </CommandItem>
                    ))}
            </CommandList>
        </Command>
    );
}

export default FilterDropdownElement;
