import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import type { MembersRowState } from "./constants";
interface DataTableRowActionsProps<GetUserResponse> {
    id: string;
    rowState: MembersRowState | undefined;
    updateRowState: (id: string, newState: Partial<MembersRowState>) => void;
    deleteRowState?: (id: string) => void;
    isDelete?: boolean;
}

export function DataTableRowActions<TData>({
    id,
    rowState,
    updateRowState,
    deleteRowState = () => {},
    isDelete = false,
}: DataTableRowActionsProps<TData>) {
    const api = useApi();
    const roles = ["Member", "Admin", "Owner"];

    function changeUserRole(role: string) {
        const requestData = {
            user_id: rowState?.id,
            new_role: role,
        };
        api.post(URLS.serverUrl + API.changeUserRole, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    updateRowState(id, {
                        role: role,
                    });
                }
            })
            .catch((res) => {
                console.log("failed to change user role");
            });
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="ghost"
                    className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                >
                    <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end" className="w-[160px]">
                {roles
                    .filter((role) => role !== rowState?.role)
                    .map((role) => (
                        <DropdownMenuItem
                            onClick={() => changeUserRole(role)}
                            className="cursor-pointer hover:bg-muted"
                            key={role}
                        >
                            Make {role}
                        </DropdownMenuItem>
                    ))}
                {isDelete && (
                    <DropdownMenuItem
                        onClick={() => deleteRowState(id)}
                        className="cursor-pointer hover:bg-muted"
                    >
                        Delete
                    </DropdownMenuItem>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
