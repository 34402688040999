import { API, ContactsAPI, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type {
    Customer,
    CustomerGroup,
    GetTopicsResponse,
    GetUserResponse,
    QueriesWithPaginationResponse,
} from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import { GlobeIcon, ImageIcon } from "@radix-ui/react-icons";
import { Badge, Box, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import IssuesList from "@/IssuesTable/IssuesList";

import { useInfiniteQuery } from "@tanstack/react-query";
import { IssueListType } from "../AdminQueriesPage";

export interface CustomerProps {
    orgID: string;
    userID: string;
}

export const CustomerPage = ({ orgID, userID }: CustomerProps) => {
    const authInfo = useAuthInfo();
    const authInfoRef = useRef(authInfo);

    const queryClient = useQueryClient();

    const { customer_id } = useParams<{ customer_id: string }>();

    const api = useApi();

    const [customer, setCustomer] = useState<Customer>();

    const [loadingState, setLoadingState] = useState<number>(0);

    useEffect(() => {
        const { url } = ContactsAPI.getContact;
        api.get(`${URLS.serverUrl}${url}/${customer_id}`, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setCustomer(res.data.data);
                }
            })
            .catch((res) => {
                console.log("System is down.");
            });
    }, [api]);

    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesByAccount,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: {
                        customer_id: customer_id,
                        offset: pageParam,
                    },
                },
            );
            if (response.status === 200) {
                return response.data.data;
            }
            setLoadingState(2);
            return { data: [], has_next_page: false, next_cursor: 0 };
        } catch (error) {
            console.error("Error fetching queries:", error);
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ["customer_tickets"],
        queryFn: fetchQueries,
        getNextPageParam: (lastPage) => {
            if (lastPage?.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });
    const combinedData =
        data && Array.isArray(data.pages)
            ? data.pages
                  .filter((page) => page !== null && page !== undefined)
                  .flatMap((page) =>
                      Array.isArray(page.data)
                          ? page.data.filter(
                                (item) => item !== null && item !== undefined,
                            )
                          : [],
                  )
            : [];

    // Fetch all the data
    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    const updateData = async () => {
        queryClient.refetchQueries({
            queryKey: ["customer_tickets"],
            exact: true,
        });
    };

    useEffect(() => {
        if (data) {
            setLoadingState(1);
        }
    }, [data]);

    const [topics, setTopics] = useState<
        { color: string; label: string; value: string }[]
    >([]);
    const [topicsMap, setTopicsMap] = useState(new Map());

    const usersQuery = useQuery<GetUserResponse[]>({
        queryKey: ["users"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getAllUsers, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            return data.data;
        },
    });

    const [loadingTopicsState, setLoadingTopicsState] = useState<number>(0);

    useEffect(() => {
        api.get(URLS.serverUrl + API.getTopics, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    const topics: GetTopicsResponse[] = res.data.data;

                    const newTopics: {
                        color: string;
                        label: string;
                        value: string;
                    }[] = [];

                    const map = new Map<string, GetTopicsResponse>();

                    for (const topic of topics) {
                        newTopics.push({
                            color: topic.color ?? "#9B9EF0",
                            label: topic.topic_name,
                            value: topic.topic_name,
                        });
                        map.set(topic.topic_name, topic);
                    }
                    setTopicsMap(map);
                    setTopics(newTopics);
                    setLoadingTopicsState(1);
                }
            })
            .catch(() => {
                console.log("Did not grab topics from db successfully");
            });
    }, [api]);

    const customerGroupsQuery = useQuery<CustomerGroup[]>({
        queryKey: ["customers"],
        queryFn: async () => {
            const res = await fetch(URLS.serverUrl + API.getCustomerGroups, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authInfoRef.current.accessToken}`,
                },
            });

            const data = await res.json();
            const customerGroups: CustomerGroup[] = data.data;
            return customerGroups;
        },
    });

    const loremIpsum =
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    return (
        <Flex direction="column" align="center" justify="center">
            <Box mt="7" height="100%" width="98%">
                <div className="flex flex-row items-center gap-3 pb-4 ml-2">
                    <ImageIcon className="w-6 h-6 ml-2" />

                    <div className="flex flex-col gap-1">
                        <div className="flex items-center gap-1 pl-1 text-[15px] font-semibold">
                            {customer?.name}
                        </div>
                        {customer?.domain !== "" && (
                            <Badge
                                color="blue"
                                size="2"
                                radius="full"
                                variant="outline"
                                className="text-xs"
                            >
                                <GlobeIcon />
                                {customer?.domain}
                            </Badge>
                        )}
                    </div>
                </div>
                {loadingState === 0 && (
                    <Flex maxWidth="85%" style={{ paddingLeft: "20px" }}>
                        <Text>
                            <Skeleton maxWidth="85%">
                                {[...Array(6)].map((_, index) => (
                                    // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                    <Text key={index}>{loremIpsum}</Text>
                                ))}
                            </Skeleton>
                        </Text>
                    </Flex>
                )}
                {loadingState === 2 && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
                {loadingState === 1 && combinedData.length !== 0 && (
                    <div className="flex flex-col gap-2 px-2">
                        <IssuesList
                            issues={combinedData}
                            topics={topics}
                            topicsMap={topicsMap}
                            userID={userID}
                            usersQuery={usersQuery}
                            customerGroupsQuery={customerGroupsQuery}
                            listType={IssueListType.Issues}
                        />
                    </div>
                )}

                {loadingState === 1 && combinedData.length === 0 && (
                    <div className="flex w-full h-[50%] justify-center items-center">
                        <p className="text-md text-gray12">No tickets yet.</p>
                    </div>
                )}
            </Box>
        </Flex>
    );
};
