import { Button } from "@/component/shadcn/ui/button";
import { Card, CardContent } from "@/component/shadcn/ui/card";
import { getStatusFullIcon } from "@/utilities/methods";
import {
    CaretDownIcon,
    CaretUpIcon,
    QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import { HeaderType } from "./constants";

import { statuses } from "./constants";

interface IssuesListHeaderCardProps {
    title: string;
    value: string;
    header_type: HeaderType;
    isExpanded: Map<string, boolean>;
    updateExpanded: (type: string, value: boolean) => void;
}

export function IssuesListHeaderCard({
    title: label,
    value,
    header_type,
    isExpanded,
    updateExpanded,
}: IssuesListHeaderCardProps) {
    // TODO: make this based on the header_type, not just the status
    const Icon = getStatusFullIcon(value);
    return (
        <Card className="py-1 mb-4 h-15 px-6 shadow-none border-none bg-[#fafafa]">
            <CardContent className="flex items-center gap-2 text-[13px] p-0">
                <div
                    className={`${header_type === HeaderType.Status ? "" : "hidden"}`}
                >
                    {Icon ? (
                        <Icon className="ml-0.5 w-3.5 h-3.5" />
                    ) : (
                        <QuestionMarkCircledIcon className="ml-0.5 w-3.5 h-3.5" />
                    )}
                </div>
                {statuses.find((status) => status.value === label)?.label ??
                    label}
                <Button
                    type="submit"
                    variant="ghost"
                    onClick={() =>
                        updateExpanded(value, !isExpanded.get(value))
                    }
                    className="p-1 m-0 hover:bg-transparent hover:text-current"
                >
                    {isExpanded.get(value) ? (
                        <CaretDownIcon className="w-3 h-3 -mx-1.5" />
                    ) : (
                        <CaretUpIcon className="w-3 h-3 -mx-1.5" />
                    )}
                </Button>
            </CardContent>
        </Card>
    );
}
