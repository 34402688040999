import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { OrgInfoResponse } from "@/interfaces/serverData";
import { Box, Button, Callout, Flex, Skeleton, Text } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { ReactComponent as HubSpotSvg } from "../../../images/integrations/hubspot.svg";
import IntegrationHeader from "./IntegrationHeader";

export const HubSpotIntegration = () => {
    const loremIpsum =
        "Lorem ipsum sit, consectetur adipiscing elit. Pellentesque felis tellus, efficitur id convallis a, viverra eget libero. Nam magna erat, fringilla sed commodo sed, aliquet nec magna.";

    const [integrationEnabled, setIntegrationEnabled] =
        useState<boolean>(false);

    const [error, setError] = useState<string | undefined>(undefined);

    const hubspotContinue = () => {
        const client_id = process.env.REACT_APP_HUBSPOT_CLIENT_ID;
        const redirect_url = `${process.env.REACT_APP_CALLBACK_URL}admin/integrations/hubspot`;
        const hubspot_url = `https://app.hubspot.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_url}&scope=crm.schemas.deals.read%20crm.objects.subscriptions.read%20crm.schemas.subscriptions.read%20crm.schemas.commercepayments.read%20oauth%20crm.objects.commercepayments.read%20crm.objects.invoices.read%20crm.schemas.invoices.read%20crm.schemas.custom.read%20crm.objects.custom.read%20crm.objects.companies.read%20crm.objects.deals.read%20crm.schemas.companies.read`;
        window.open(hubspot_url, "_self");
    };

    const api = useApi();

    const [loadingState, setLoadingState] = useState<number>(0);

    useEffect(() => {
        const requestData = {
            types: ["HubSpot"],
        };
        api.post(URLS.serverUrl + API.getItemsByOrgID, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.data) {
                        const orgInfo: OrgInfoResponse = res.data.data;
                        if (orgInfo.HubSpot) {
                            setIntegrationEnabled(true);
                        }
                        setLoadingState(1);
                    }
                } else {
                    console.log("failed to get results");
                    setLoadingState(2);
                }
            })
            .catch((res) => {
                console.log("failed to get repository");
                setLoadingState(2);
            });
    }, [api]);

    return (
        <div className="bg-gray-500">
            <Box mt={"5%"} ml={"28%"} mr={"28%"}>
                <Flex direction={"column"} align={"start"} gap="6">
                    <IntegrationHeader
                        integrationType="HubSpot"
                        description="Connect your HubSpot account to see customer data in Assembly."
                        SvgIcon={HubSpotSvg}
                    />
                    <Flex style={{ width: "100%", justifyContent: "flex-end" }}>
                        {!integrationEnabled ? (
                            <Button onClick={hubspotContinue}>Enable</Button>
                        ) : (
                            <Button disabled={true}>Enabled</Button>
                        )}
                    </Flex>
                </Flex>
                {loadingState === 0 && (
                    <Skeleton>
                        <Text>
                            {[...Array(2)].map((_, index) => (
                                <Text key={null}>{loremIpsum}</Text>
                            ))}
                        </Text>
                    </Skeleton>
                )}

                {(loadingState === 2 || error) && (
                    <Callout.Root size="1" variant="outline" color="red">
                        <Callout.Text>
                            Sorry, something's wrong! Please notify us at
                            support@askassembly.app.
                        </Callout.Text>
                    </Callout.Root>
                )}
            </Box>
        </div>
    );
};
