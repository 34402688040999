export type integration = {
    integrationType: string;
    integrationCode: string;
};

export type integrationInfo = {
    title: string;
    description: string;
    buttonInfo: string;
};

export const managementIntegrationList = [
    "Jira",
    "Confluence",
    "Linear",
    "Google",
    "Slack",
    "PullRequest",
    "Code",
    "Notion",
    "Web",
    "Discord",
    "GitHubTicket",
    "ChatWidget",
    "CommunitySlack",
    // "HubSpot",
    "Intercom",
];

export const integrations = {
    Jira: "Jira",
    Linear: "Linear",
    Slack: "Slack",
    SlackBot: "SlackBot",
    PullRequest: "PullRequest",
    Code: "code",
    Gmail: "Gmail",
    Google: "Google",
    Notion: "Notion",
    Web: "Web",
    Discord: "Discord",
    GitHubTicket: "GitHubTicket",
    ChatWidget: "ChatWidget",
    Confluence: "Confluence",
    CommunitySlack: "CommunitySlack",
    HubSpot: "HubSpot",
    Intercom: "Intercom",
};

export const readableIntegrationMapping = new Map<string, string>([
    [integrations.Jira, "Jira"],
    [integrations.Linear, "Linear"],
    ["Code", "Code"],
    [integrations.PullRequest, "Pull Requests"],
    [integrations.Slack, "Slack"],
    [integrations.Google, "Google"],
    [integrations.Notion, "Notion"],
    [integrations.Web, "Web Documentation"],
    [integrations.Discord, "Discord"],
    [integrations.GitHubTicket, "GitHub Issues"],
    [integrations.ChatWidget, "Chat Widget"],
    [integrations.Confluence, "Confluence"],
    [integrations.CommunitySlack, "Community Slack"],
]);

export const integrationMappingToRequest = new Map<string, string>([
    [integrations.Jira, "Jira"],
    [integrations.Linear, "Linear"],
    ["Code", integrations.Code],
    ["Pull Requests", integrations.PullRequest],
    [integrations.Slack, "Slack"],
    [integrations.Google, "Google"],
    [integrations.Notion, "Notion"],
    ["Web Documentation", "Web"],
    [integrations.Discord, integrations.Discord],
    ["GitHub Issues", integrations.GitHubTicket],
    ["Chat Widget", integrations.ChatWidget],
    [integrations.Confluence, "Confluence"],
    ["CommunitySlack", integrations.CommunitySlack],
]);

export const integrationsList = new Map<string, integration>([
    [
        "/admin/integrations/code",
        {
            integrationType: integrations.Code,
            integrationCode: "",
        },
    ],
    [
        "/admin/integrations/jira",
        {
            integrationType: integrations.Jira,
            integrationCode: "ui-assembly-jira",
        },
    ],
    [
        "/admin/integrations/confluence",
        {
            integrationType: integrations.Confluence,
            integrationCode: "ui-assembly-confluence",
        },
    ],
    [
        "/admin/integrations/linear",
        {
            integrationType: integrations.Linear,
            integrationCode: "r9501-assembly-pwd",
        },
    ],
    [
        "admin/integrations/google",
        {
            integrationType: integrations.Google,
            integrationCode: "",
        },
    ],
    [
        "/admin/integrations/pullrequest",
        {
            integrationType: integrations.PullRequest,
            integrationCode: "",
        },
    ],
    [
        "/admin/integrations/slack",
        {
            integrationType: integrations.Slack,
            integrationCode: "slack-assembly-19156",
        },
    ],
    [
        "/admin/integrations/slackbot",
        {
            integrationType: integrations.SlackBot,
            integrationCode: "slack-assembly-19156",
        },
    ],
    [
        "/admin/integrations/notion",
        {
            integrationType: integrations.Notion,
            integrationCode: "",
        },
    ],
    [
        "/admin/integrations/web",
        {
            integrationType: integrations.Web,
            integrationCode: "",
        },
    ],
    [
        "/admin/integrations/discord",
        {
            integrationType: integrations.Discord,
            integrationCode: "",
        },
    ],
    [
        "/admin/integrations/githubticket",
        {
            integrationType: integrations.GitHubTicket,
            integrationCode: "",
        },
    ],
    [
        "/admin/integrations/google",
        {
            integrationType: integrations.Google,
            integrationCode: "state_parameter_passthrough_value",
        },
    ],
    [
        "/admin/integrations/communityslack",
        {
            integrationType: integrations.CommunitySlack,
            integrationCode: "slack-assembly-19156",
        },
    ],
    [
        "/admin/integrations/hubspot",
        {
            integrationType: integrations.HubSpot,
            integrationCode: "",
        },
    ],
    [
        "/admin/integrations/intercom",
        {
            integrationType: integrations.Intercom,
            integrationCode: "ui-assembly-admin-intercom1234",
        },
    ],
    // [
    //     "/admin/integrations/zendesk",
    //     {
    //         integrationType: integrations.Zendesk,
    //         integrationCode: "zdg-assembly",
    //     },
    // ],
]);

// adds new repositories, orgs, channels as needed
export const integrationsUpdate = new Map<string, string>([
    ["/admin/integrations/add/slack", integrations.Slack],
    ["/admin/integrations/add/communityslack", integrations.CommunitySlack],
    ["/admin/integrations/add/code", integrations.Code],
    ["/admin/integrations/add/linear", integrations.Linear],
    ["/admin/integrations/add/jira", integrations.Jira],
    ["/admin/integrations/add/confluence", integrations.Confluence],
]);

export const integrationsUpdateScopes = new Map<string, string>([
    ["/admin/integrations/update/discord", integrations.Discord],
    ["/admin/integrations/update/slack", integrations.Slack],
    ["/admin/integrations/update/communityslack", integrations.CommunitySlack],
    ["/admin/integrations/update/githubticket", integrations.GitHubTicket],
]);
