import "@blocknote/mantine/style.css";
import "@blocknote/mantine/style.css";
import "@blocknote/mantine/style.css";
import { Liveblocks } from "@liveblocks/node";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useLayoutEffect } from "react";
import { useState } from "react";
import {
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import AdminLoginPage from "./pages/Admin/AdminLoginPage";
import NotFound from "./pages/NotFound";
import SearchPage from "./pages/SearchPage";
import { GlobalStyle, assemblyTheme } from "./theme";

import { LiveblocksProvider } from "@liveblocks/react";
import {
    RedirectToLogin,
    type WithAuthInfoProps,
    useAuthInfo,
    useRedirectFunctions,
    withRequiredAuthInfo,
} from "@propelauth/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NAVBAR_ITEMS } from "./component/SideBar/SideBarComponent";
import { NAVBAR_PREFERENCES_ITEMS } from "./component/SideBar/WorkspaceSideBarComponent";
import UnauthorizedDialog from "./component/UnauthorizedDialog";
import { loadingTypes } from "./constant";
import AdminAnalyticsPage from "./pages/Admin/AdminAnalyticsPage";
import AdminInsightsPage from "./pages/Admin/AdminInsightsPage";
import AdminQueriesPage, {
    IssueListType,
} from "./pages/Admin/AdminQueriesPage";
import AdminTicketPage from "./pages/Admin/AdminTicketPage";
import AnnouncementsPage from "./pages/Admin/AnnouncementsPage";
import BotPage from "./pages/Admin/BotPage";
import { CompaniesPage } from "./pages/Admin/CRM/Companies";
import { CompanyPage } from "./pages/Admin/CRM/CompanyPage";
import GitHubIndexingPage from "./pages/Admin/GitHubIndexingPage";
import ChatWidgetIntegration from "./pages/Admin/Integrations/ChatWidgetIntegration";
import CommunitySlackIntegration from "./pages/Admin/Integrations/CommunitySlackIntegration";
import ConfluenceIntegration from "./pages/Admin/Integrations/ConfluenceIntegration";
import DiscordIntegration from "./pages/Admin/Integrations/DiscordIntegration";
import GitHubIntegration from "./pages/Admin/Integrations/GitHubIntegration";
import GitHubTicketIntegration from "./pages/Admin/Integrations/GitHubTicketIntegration";
import GoogleIntegration from "./pages/Admin/Integrations/GoogleIntegration";
import JiraIntegration from "./pages/Admin/Integrations/JiraIntegration";
import LinearIntegration from "./pages/Admin/Integrations/LinearIntegration";
import NotionIntegration from "./pages/Admin/Integrations/NotionIntegration";
import PRIntegration from "./pages/Admin/Integrations/PRIntegration";
import SlackIntegration from "./pages/Admin/Integrations/SlackIntegration";

import InsightDisplayPage from "./pages/Admin/InsightDisplayPage";
import WebIntegration from "./pages/Admin/Integrations/WebIntegration";
import AddScopesIndexingPage from "./pages/Admin/IntegrationsIndexing/AddScopesIndexingPage";
import IntegrationsIndexingPage from "./pages/Admin/IntegrationsIndexing/IntegrationsIndexingPage";
import PRIndexingPage from "./pages/Admin/IntegrationsIndexing/PRIndexingPage";
import ReIndexingPage from "./pages/Admin/IntegrationsIndexing/ReindexPage";
import TokenPage from "./pages/Admin/IntegrationsIndexing/TokenPage";
import IntegrationsManagementPage from "./pages/Admin/IntegrationsManagementPage";
import UpdateScopesPage from "./pages/Admin/UpdateScopesListeningPage";
import { AnnouncementsEditingPage } from "./pages/Announcements/AnnouncementsEditingPage";
import HomePage from "./pages/HomePage";
import { SearchProvider } from "./pages/SearchContext";
import { NewView } from "./pages/User/NewView";
import { ViewViewer } from "./pages/User/ViewViewer";
import NotificationPreferences from "./pages/WorkspacePreferences/Alerts";
import CustomerGroups from "./pages/WorkspacePreferences/CustomerGroups";
import Members from "./pages/WorkspacePreferences/Members";
import Profile from "./pages/WorkspacePreferences/Profile";
import SLAs from "./pages/WorkspacePreferences/SLAs";
import Tags from "./pages/WorkspacePreferences/Tags";
import WorkflowManagement from "./pages/WorkspacePreferences/Workflows";

import { CustomerPage } from "./pages/Admin/CRM/CustomerPage";
import { HubSpotIntegration } from "./pages/Admin/Integrations/HubSpotIntegration";
import IntercomIntegration from "./pages/Admin/Integrations/IntercomIntegration";
import NewTeam from "./pages/WorkspacePreferences/NewTeam";
import Team from "./pages/WorkspacePreferences/Team";
import TeamSettings from "./pages/WorkspacePreferences/TeamSettings";

const AdminIndexingRoutes = () => (
    <Routes>
        <Route path="pullrequest" element={<PRIndexingPage />} />
        <Route path="web" element={<PRIndexingPage />} />
        <Route path="add/*" element={<AddScopesIndexingPage />} />
        <Route path="reindex" element={<ReIndexingPage />} />
        <Route path="linear" element={<TokenPage />} />
        <Route path="jira" element={<TokenPage />} />
        <Route path="confluence" element={<TokenPage />} />
        <Route path="slack" element={<TokenPage />} />
        <Route path="google" element={<TokenPage />} />
        <Route path="*" element={<IntegrationsIndexingPage />} />
        <Route path="githubticket" element={<GitHubIndexingPage />} />
        <Route path="code" element={<GitHubIndexingPage />} />
        <Route path="discord" element={<BotPage />} />
        <Route path="hubspot" element={<TokenPage />} />
        <Route path="intercom" element={<TokenPage />} />
        <Route path="update/*" element={<UpdateScopesPage />} />
    </Routes>
);

const AnnouncementsRoutes = () => (
    <Routes>
        <Route path="" element={<AnnouncementsPage />} />
        <Route path="new/:id" element={<AnnouncementsEditingPage />} />
        <Route path="/:id" element={<AnnouncementsEditingPage />} />
    </Routes>
);

interface RouteToViewPageProps {
    orgName: string;
    isAdmin: boolean;
}

const RouteToViewPage: React.FC<RouteToViewPageProps> = ({
    orgName,
    isAdmin,
}) => {
    const { id } = useParams<{ id: string }>();

    // Handle case where `id` is not available
    if (!id) {
        return <Navigate to="/issues" />;
    }
    return (
        <HomePage
            organizationName={orgName}
            isAdmin={isAdmin}
            navBarState={NAVBAR_ITEMS.savedView}
            withScroll={false}
            viewID={id}
        >
            <ViewViewer />
        </HomePage>
    );
};

interface RouteToTeamPageProps {
    orgName: string;
    isAdmin: boolean;
    userID: string;
}

const RouteToTeamPage: React.FC<RouteToTeamPageProps> = ({
    orgName,
    isAdmin,
    userID,
}) => {
    const { id } = useParams<{ id: string }>();

    // Handle case where `id` is not available
    if (!id) {
        return <Navigate to="/issues" />;
    }
    return (
        <SearchProvider>
            <HomePage
                organizationName={orgName}
                isAdmin={isAdmin}
                navBarState={NAVBAR_ITEMS.queries}
                withScroll={false}
                activeTeam={id}
            >
                <AdminQueriesPage
                    userID={userID}
                    listType={IssueListType.Team}
                    tableView={true}
                    hidePopup={false}
                    tempTeamId={id}
                />
            </HomePage>
        </SearchProvider>
    );
};

interface CRMRoutesProps {
    org_id: string;
    userID: string;
}

// /companies/*
const CRMRoutes = ({ org_id, userID }: CRMRoutesProps) => (
    <Routes>
        <Route
            path="/:id"
            element={<CompanyPage userID={userID} orgID={org_id} />}
        />
        <Route
            path="/:company_id/:customer_id"
            element={<CustomerPage userID={userID} orgID={org_id} />}
        />
        <Route
            path="/individual/:customer_id"
            element={<CustomerPage userID={userID} orgID={org_id} />}
        />
        <Route path="" element={<CompaniesPage orgID={org_id} />} />
    </Routes>
);

const AdminRoutes = () => (
    <Routes>
        <Route path="code" element={<GitHubIntegration />} />
        <Route path="web" element={<WebIntegration />} />
        <Route path="jira" element={<JiraIntegration />} />
        <Route path="confluence" element={<ConfluenceIntegration />} />
        <Route path="google" element={<GoogleIntegration />} />
        <Route path="slack" element={<SlackIntegration />} />
        <Route path="communityslack" element={<CommunitySlackIntegration />} />
        <Route path="linear" element={<LinearIntegration />} />
        <Route path="pullrequest" element={<PRIntegration />} />
        <Route path="notion" element={<NotionIntegration />} />
        <Route path="discord" element={<DiscordIntegration />} />
        <Route path="chatwidget" element={<ChatWidgetIntegration />} />
        <Route path="githubticket" element={<GitHubTicketIntegration />} />
        <Route path="hubspot" element={<HubSpotIntegration />} />
        <Route path="intercom" element={<IntercomIntegration />} />
    </Routes>
);

const PreferencesRoutes = () => (
    <Routes>
        <Route path="teams/new" element={<NewTeam />} />
        <Route path="teams/:id" element={<Team />} />
        <Route path="teams/:id/settings" element={<TeamSettings />} />
    </Routes>
);

interface RouteToTicketPageProps {
    orgName: string;
    isAdmin: boolean;
    userID: string;
}

const RouteToTicketPage: React.FC<RouteToTicketPageProps> = ({
    orgName,
    isAdmin,
    userID,
}) => {
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const from =
        location.state?.from || sessionStorage.getItem("from") || "issues";
    console.log("from", from);

    // Handle case where `id` is not available
    if (!id) {
        return <Navigate to={`/${from}`} />;
    }

    return (
        <HomePage
            organizationName={orgName}
            isAdmin={isAdmin}
            navBarState={
                from === "inbox"
                    ? IssueListType.Inbox
                    : from === "issues"
                        ? IssueListType.Issues
                        : IssueListType.Team
            }
        >
            <AdminTicketPage
                identifier={id}
                userID={userID}
                listType={
                    from === "inbox"
                        ? IssueListType.Inbox
                        : from === "issues"
                            ? IssueListType.Issues
                            : IssueListType.Team
                }
            />
        </HomePage>
    );
};

interface RouteToInsightPageProps {
    orgName: string;
    isAdmin: boolean;
    userID: string;
}

const RouteToInsightPage: React.FC<RouteToInsightPageProps> = ({
    orgName,
    isAdmin,
    userID,
}) => {
    const { id } = useParams<{ id: string }>();
    // Handle case where `id` is not available
    if (!id) {
        return <Navigate to={"/insights"} />;
    }

    return (
        <HomePage
            organizationName={orgName}
            isAdmin={isAdmin}
            navBarState={NAVBAR_ITEMS.insights}
            withScroll={false}
        >
            <InsightDisplayPage id={id} userID={userID} />
        </HomePage>
    );
};

const queryClient = new QueryClient();

const App = withRequiredAuthInfo((props: WithAuthInfoProps) => {
    const navigate = useNavigate();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [orgName, setOrgName] = useState<string>("");
    const [orgID, setOrgID] = useState<string>("");
    const [userID, setUserID] = useState<string>("");

    const { loading, isLoggedIn, user, orgHelper } = useAuthInfo();

    const { redirectToLoginPage } = useRedirectFunctions();
    const [appLoading, setLoading] = useState<number>(loadingTypes.loading);
    // only run and check once someone has logged in to our system
    const liveblocks = new Liveblocks({
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        secret: process.env.REACT_APP_LIVEBLOCKS_SECRET_KEY!,
    });

    useLayoutEffect(() => {
        if (!props.isLoggedIn) {
            return;
        }
        if (props.accessHelper === undefined || props.orgHelper === undefined) {
            navigate("/*");
            return;
        }

        const orgs = props.orgHelper.getOrgs();
        if (orgs === undefined || orgs.length !== 1) {
            navigate("/*");
            return;
        }
        const orgId = orgs[0].orgId;
        setOrgID(orgId);
        const name = orgs[0].orgName;
        setOrgName(name);

        const isAdminTemp = props.accessHelper.isAtLeastRole(orgId, "Admin");
        const isOwnerTemp = props.accessHelper.isAtLeastRole(orgId, "Owner");

        setIsAdmin(isAdminTemp || isOwnerTemp);
        setUserID(props.user.userId);
        setLoading(loadingTypes.loaded);
    }, [
        props.accessHelper,
        props.orgHelper,
        props.isLoggedIn,
        props.user,
        navigate,
    ]);

    useEffect(() => {
        if (!props.isLoggedIn) {
            const options = {
                postLoginRedirectUrl: `${process.env.REACT_APP_CALLBACK_URL}admin/inbox`,
            };
            redirectToLoginPage(options);
        }
        setLoading(loadingTypes.loaded);
    }, [props, redirectToLoginPage]);

    if (props.isLoggedIn) {
        return (
            <LiveblocksProvider
                authEndpoint={async (room) => {
                    // Start an auth session inside your endpoint
                    if (loading === false && isLoggedIn) {
                        const session = liveblocks.prepareSession(
                            user.userId,
                            {
                                userInfo: {
                                    name: user.firstName,
                                    picture: user.pictureUrl,
                                },
                            }, // Optional
                        );
                        const orgs = orgHelper.getOrgs();
                        if (orgs === undefined || orgs.length !== 1) {
                            return {
                                error: "forbidden",
                                reason: "Invalid organization or user not part of exactly one organization.",
                            };
                        }
                        const orgId = orgs[0].orgId;

                        session.allow(
                            `${orgId}:announcements:*`,
                            session.FULL_ACCESS,
                        );

                        // Authorize the user and return the result
                        const { status, body: token } =
                            await session.authorize();

                        return new Response(token, { status }).json();
                    } else {
                        return {
                            error: "forbidden",
                            reason: "User is not logged in or loading is not complete.",
                        };
                    }
                }}
            >
                <QueryClientProvider client={queryClient}>
                    <ThemeProvider theme={assemblyTheme}>
                        <GlobalStyle>
                            <UnauthorizedDialog />
                            {appLoading === loadingTypes.loaded && (
                                <Routes>
                                    <Route
                                        path=""
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.inbox
                                                    }
                                                    withScroll={false}
                                                >
                                                    <AdminQueriesPage
                                                        userID={userID}
                                                        listType={
                                                            IssueListType.Inbox
                                                        }
                                                        tableView={true}
                                                        hidePopup={false}
                                                    />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />
                                    <Route
                                        path="/admin_login"
                                        element={<AdminLoginPage />}
                                    />
                                    <Route
                                        path="/admin/integrations/*"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.integrations
                                                    }
                                                >
                                                    <AdminIndexingRoutes />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />

                                    <Route
                                        path="/admin/manage_integrations/*"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.integrations
                                                    }
                                                >
                                                    <AdminRoutes />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />
                                    <Route
                                        path="/admin/manage_integrations"
                                        element={
                                            !isAdmin ? (
                                                <Navigate to="/inbox" />
                                            ) : (
                                                <SearchProvider>
                                                    <HomePage
                                                        organizationName={
                                                            orgName
                                                        }
                                                        isAdmin={isAdmin}
                                                        navBarState={
                                                            NAVBAR_ITEMS.integrations
                                                        }
                                                    >
                                                        <IntegrationsManagementPage />
                                                    </HomePage>
                                                </SearchProvider>
                                            )
                                        }
                                    />
                                    <Route
                                        path="/views/:id"
                                        element={
                                            <SearchProvider>
                                                <RouteToViewPage
                                                    orgName={orgName}
                                                    isAdmin={isAdmin}
                                                />
                                            </SearchProvider>
                                        }
                                    />
                                    <Route
                                        path="/views/create"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.queries
                                                    }
                                                    withScroll={false}
                                                >
                                                    <NewView />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />
                                    <Route
                                        path="/announcements/*"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.announcements
                                                    }
                                                >
                                                    <AnnouncementsRoutes />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />
                                    <Route
                                        path="/analytics"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.analytics
                                                    }
                                                >
                                                    <AdminAnalyticsPage />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />

                                    <Route
                                        path="/companies/*"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.crm
                                                    }
                                                >
                                                    <CRMRoutes
                                                        org_id={orgID}
                                                        userID={userID}
                                                    />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />

                                    <Route
                                        path="/insights"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.insights
                                                    }
                                                    withScroll={false}
                                                >
                                                    <AdminInsightsPage
                                                        userID={userID}
                                                    />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />
                                    <Route
                                        path="/insight/:id"
                                        element={
                                            <SearchProvider>
                                                <RouteToInsightPage
                                                    orgName={orgName}
                                                    isAdmin={isAdmin}
                                                    userID={userID}
                                                />
                                            </SearchProvider>
                                        }
                                    />

                                    <Route
                                        path="/issues"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.queries
                                                    }
                                                    withScroll={false}
                                                >
                                                    <AdminQueriesPage
                                                        userID={userID}
                                                        listType={
                                                            IssueListType.Issues
                                                        }
                                                        tableView={true}
                                                        hidePopup={false}
                                                    />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />
                                    <Route
                                        path="/teams/:id/issues"
                                        element={
                                            <RouteToTeamPage
                                                orgName={orgName}
                                                isAdmin={isAdmin}
                                                userID={userID}
                                            />
                                        }
                                    />
                                    <Route
                                        path="/inbox"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.inbox
                                                    }
                                                    withScroll={false}
                                                >
                                                    <AdminQueriesPage
                                                        userID={userID}
                                                        listType={
                                                            IssueListType.Inbox
                                                        }
                                                        tableView={true}
                                                        hidePopup={false}
                                                    />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />
                                    <Route
                                        path="/issue/:id"
                                        element={
                                            <SearchProvider>
                                                <RouteToTicketPage
                                                    orgName={orgName}
                                                    isAdmin={isAdmin}
                                                    userID={userID}
                                                />
                                            </SearchProvider>
                                        }
                                    />
                                    <Route
                                        path="/search"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_ITEMS.search
                                                    }
                                                >
                                                    <SearchPage
                                                        isAdmin={isAdmin}
                                                    />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />

                                    <Route
                                        path="/preferences/*"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.profile
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <PreferencesRoutes />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />

                                    <Route
                                        path="/preferences/profile"
                                        element={
                                            <SearchProvider>
                                                <HomePage
                                                    organizationName={orgName}
                                                    isAdmin={isAdmin}
                                                    navBarState={
                                                        NAVBAR_PREFERENCES_ITEMS.profile
                                                    }
                                                    orgID={orgID}
                                                    isWorkspace={true}
                                                >
                                                    <Profile />
                                                </HomePage>
                                            </SearchProvider>
                                        }
                                    />

                                    <Route
                                        path="/preferences/tags"
                                        element={
                                            !isAdmin ? (
                                                <Navigate to="/preferences/profile" />
                                            ) : (
                                                <SearchProvider>
                                                    <HomePage
                                                        organizationName={
                                                            orgName
                                                        }
                                                        isAdmin={isAdmin}
                                                        navBarState={
                                                            NAVBAR_PREFERENCES_ITEMS.profile
                                                        }
                                                        orgID={orgID}
                                                        isWorkspace={true}
                                                    >
                                                        <Tags />
                                                    </HomePage>
                                                </SearchProvider>
                                            )
                                        }
                                    />

                                    <Route
                                        path="/preferences/customergroups"
                                        element={
                                            !isAdmin ? (
                                                <Navigate to="/preferences/customergroups" />
                                            ) : (
                                                <SearchProvider>
                                                    <HomePage
                                                        organizationName={
                                                            orgName
                                                        }
                                                        isAdmin={isAdmin}
                                                        navBarState={
                                                            NAVBAR_PREFERENCES_ITEMS.customergroups
                                                        }
                                                        orgID={orgID}
                                                        isWorkspace={true}
                                                    >
                                                        <CustomerGroups />
                                                    </HomePage>
                                                </SearchProvider>
                                            )
                                        }
                                    />

                                    <Route
                                        path="/preferences/alerts"
                                        element={
                                            !isAdmin ? (
                                                <Navigate to="/preferences/profile" />
                                            ) : (
                                                <SearchProvider>
                                                    <HomePage
                                                        organizationName={
                                                            orgName
                                                        }
                                                        isAdmin={isAdmin}
                                                        navBarState={
                                                            NAVBAR_PREFERENCES_ITEMS.profile
                                                        }
                                                        orgID={orgID}
                                                        isWorkspace={true}
                                                    >
                                                        <NotificationPreferences />
                                                    </HomePage>
                                                </SearchProvider>
                                            )
                                        }
                                    />

                                    <Route
                                        path="/preferences/members"
                                        element={
                                            !isAdmin ? (
                                                <Navigate to="/preferences/profile" />
                                            ) : (
                                                <SearchProvider>
                                                    <HomePage
                                                        organizationName={
                                                            orgName
                                                        }
                                                        isAdmin={isAdmin}
                                                        navBarState={
                                                            NAVBAR_PREFERENCES_ITEMS.members
                                                        }
                                                        orgID={orgID}
                                                        isWorkspace={true}
                                                    >
                                                        <Members org_id={orgID} />
                                                    </HomePage>
                                                </SearchProvider>
                                            )
                                        }
                                    />

                                    <Route
                                        path="/preferences/sla"
                                        element={
                                            !isAdmin ? (
                                                <Navigate to="/preferences/profile" />
                                            ) : (
                                                <SearchProvider>
                                                    <HomePage
                                                        organizationName={
                                                            orgName
                                                        }
                                                        isAdmin={isAdmin}
                                                        navBarState={
                                                            NAVBAR_PREFERENCES_ITEMS.sla
                                                        }
                                                        orgID={orgID}
                                                        isWorkspace={true}
                                                    >
                                                        <SLAs />
                                                    </HomePage>
                                                </SearchProvider>
                                            )
                                        }
                                    />

                                    <Route
                                        path="/preferences/workflows"
                                        element={
                                            !isAdmin ? (
                                                <Navigate to="/preferences/profile" />
                                            ) : (
                                                <SearchProvider>
                                                    <HomePage
                                                        organizationName={
                                                            orgName
                                                        }
                                                        isAdmin={isAdmin}
                                                        navBarState={
                                                            NAVBAR_PREFERENCES_ITEMS.sla
                                                        }
                                                        orgID={orgID}
                                                        isWorkspace={true}
                                                    >
                                                        <WorkflowManagement />
                                                    </HomePage>
                                                </SearchProvider>
                                            )
                                        }
                                    />

                                    <Route path="*" element={<NotFound />} />
                                </Routes>
                            )}
                        </GlobalStyle>
                    </ThemeProvider>
                </QueryClientProvider>
            </LiveblocksProvider>
        );
    } else {
        return (
            <RedirectToLogin
                postLoginRedirectUrl={`${process.env.REACT_APP_CALLBACK_URL}search`}
            />
        );
    }
});

export default App;
