import type * as React from "react";
import type { ScopeResponse } from "../../interfaces/serverData";
import { Button } from "../shadcn/ui/button";

import { Separator } from "../shadcn/ui/separator";

interface FilterProps {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    items: Map<any, boolean>;
    name: string;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    handleToggle: (type: string, value: any) => () => void;
    selected: boolean;
    svg: React.ReactNode;
}

export default function FilterComponent(props: FilterProps) {
    return (
        <div className="w-full">
            <div className="w-full pl-3">
                <Button
                    variant="ghost"
                    size="sm"
                    onClick={props.handleToggle(props.name, props.name)}
                    className={`flex justify-start items-center text-left w-full box-border ${props.selected
                            ? "bg-secondary text-gray-950"
                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                        }`}
                    aria-pressed={props.selected}
                >
                    <div style={{ minWidth: "4px" }}>{props.svg}</div>
                    <div className="ml-2">{props.name}</div>
                </Button>
            </div>

            {props.items.size > 0 && (
                <div className="flex">
                    <div className="flex">
                        <Separator
                            orientation="vertical"
                            className="w-px bg-sidebarBorder ml-9"
                        />
                    </div>
                    <div className="flex flex-col gap-y-1 w-full pt-1 pl-1">
                        {Array.from(props.items.entries()).map(
                            ([itemName, enabled]: [
                                // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                                any,
                                boolean,
                            ]) => {
                                if (
                                    props.name === "Slack" ||
                                    props.name === "CommunitySlack" ||
                                    props.name === "Pull Requests" ||
                                    props.name === "Code"
                                ) {
                                    const item: ScopeResponse = itemName;
                                    return (
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            onClick={props.handleToggle(
                                                props.name,
                                                item,
                                            )}
                                            aria-pressed={enabled}
                                            className={`flex justify-start items-center text-left w-full box-border pl-2 ${enabled
                                                    ? "bg-secondary text-gray-950"
                                                    : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                }`}
                                        >
                                            <div className="ml-2">
                                                {item.name}
                                            </div>
                                        </Button>
                                    );
                                } else {
                                    return (
                                        <Button
                                            variant="ghost"
                                            size="sm"
                                            onClick={props.handleToggle(
                                                props.name,
                                                itemName,
                                            )}
                                            aria-pressed={enabled}
                                            className={`flex justify-start items-center text-left w-full box-border pl-2 ${enabled
                                                    ? "bg-secondary text-gray-950"
                                                    : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                }`}
                                        >
                                            <div className="ml-2">
                                                {itemName}
                                            </div>
                                        </Button>
                                    );
                                }
                            },
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
