import type { CustomerGroup, GetUserResponse } from "@/interfaces/serverData";
import { Filter } from "./Filter";
import FilterDropdown from "./FilterDropdown";

interface FilterItemsProps {
    filters: Map<string, Set<string>>;
    setFilters: (filters: Map<string, Set<string>>) => void;
    topics: { color: string; label: string; value: string }[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    savedViewFilters: Map<string, Set<string>>;
}

export const FilterItems: React.FC<FilterItemsProps> = ({
    filters,
    setFilters,
    topics,
    users,
    customerGroups,
    savedViewFilters,
}) => {
    const directSelectFilterOptions = ["Assembly Bot"];
    const activeMenuFilterOptions = [
        "Assignee",
        "Customer Group",
        "Status",
        "Source",
        "Tag",
        "Topic",
    ];
    const handleFilterSelect = (type: string, value: string) => {
        return () => {
            const newFilters = new Map(filters); // Create a shallow copy of the filters Map
            if (newFilters.has(type)) {
                const currValues = new Set(newFilters.get(type)); // Shallow copy of the Set
                if (currValues.has(value)) {
                    currValues.delete(value); // Remove the selected value
                    if (currValues.size === 0) {
                        newFilters.delete(type); // Remove filter if no values remain
                    } else {
                        newFilters.set(type, currValues); // Update the type with new values
                    }
                } else {
                    currValues.add(value); // Add new value to the filter
                    newFilters.set(type, currValues); // Update the type with the new value set
                }
            } else {
                newFilters.set(type, new Set([value])); // Add new filter type if it doesn't exist
            }
            setFilters(newFilters); // Ensure you're updating the state properly
        };
    };

    return (
        <>
            {Array.from(filters.entries()).map(([type, values]) => {
                return (
                    <div key={type}>
                        <Filter
                            type={type}
                            values={values} // Convert Set to array
                            filters={filters}
                            setFilters={setFilters}
                            handleItemSelect={handleFilterSelect}
                            topics={topics}
                            users={users}
                            customerGroups={customerGroups}
                            isSavedViewFilter={savedViewFilters.has(type)}
                        />
                    </div>
                );
            })}
            <FilterDropdown
                filters={filters}
                activeMenuFilterOptions={activeMenuFilterOptions.filter(
                    (type) => !savedViewFilters.has(type),
                )}
                directSelectFilterOptions={directSelectFilterOptions.filter(
                    (type) => !savedViewFilters.has(type),
                )}
                handleItemSelect={handleFilterSelect}
                topics={topics}
                users={users}
                customerGroups={customerGroups}
            />
        </>
    );
};
