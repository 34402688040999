import { Badge as ScnBadge } from "@/component/shadcn/ui/badge";
import { Button } from "@/component/shadcn/ui/button";
import {
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Separator } from "@/component/shadcn/ui/separator";

import type { CustomerGroup, GetUserResponse, IconEntry } from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import {
    getColorLight,
    getOptions,
    getPlural,
    getStatusIcon,
} from "@/utilities/methods";

import { DropdownMenu } from "@radix-ui/react-dropdown-menu";

import {
    AvatarIcon,
    ComponentBooleanIcon,
    Cross2Icon,
    DotFilledIcon,
    GroupIcon,
    QuestionMarkIcon,
} from "@radix-ui/react-icons";

import { Badge as RuiBadge } from "@radix-ui/themes";
import React from "react";
import FilterDropdownElement from "./FilterDropdownElement";

interface SingleValueFilterDisplayProps {
    type: string;
    valList: string[];
    options: { label: string; value: string; color?: string }[];
    users: GetUserResponse[];
}
const SingleValueFilterDisplay: React.FC<SingleValueFilterDisplayProps> =
    React.memo(({ type, valList, options, users }) => {
        const label = options.find((item) => item.value === valList[0])?.label;
        switch (type.toLowerCase()) {
            case "source": {
                const Icon =
                    integrationBackEndDataMappingToSvg.get(valList[0]) ??
                    QuestionMarkIcon;
                return (
                    <div className="flex items-center gap-1">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                );
            }
            case "tag": {
                return (
                    <RuiBadge
                        color={"gray"}
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0.5"
                    >
                        <div className="flex flex-row items-center">
                            <ComponentBooleanIcon
                                color={getColorLight(valList[0])}
                            />
                            <p className="pl-0.5">{label}</p>
                        </div>
                    </RuiBadge>
                );
            }
            case "topic": {
                const color = options.find(option => option.label === valList[0])?.color
                return (
                    <RuiBadge
                        color="gray"
                        size="2"
                        radius="full"
                        variant="outline"
                        className="m-0.5"
                        key={valList[0]}
                    >
                        <div className="flex flex-row items-center">
                            <DotFilledIcon
                                color={color && color !== "" ? color : "#9B9EF0"}
                                style={{ transform: "scale(1.8)" }}
                            />
                            <p className="pl-0.3">{label}</p>
                        </div>
                    </RuiBadge>
                );
            }
            case "status": {
                const Icon = getStatusIcon(valList[0] ?? "Unknown");
                return (
                    <div className="flex items-center gap-1">
                        <Icon className="w-4 h-4" />
                        {label}
                    </div>
                );
            }
            case "assignee": {
                const pictureURL = users.find(
                    (user) => user.id === valList[0],
                )?.picture_url;
                return (
                    <div className="flex items-center gap-2">
                        <div className="lb-avatar rounded-lg w-5 h-5">
                            {pictureURL ? (
                                <img
                                    className="lb-avatar-image"
                                    src={pictureURL}
                                    alt={label}
                                />
                            ) : (
                                <AvatarIcon className="w-6 h-6" />
                            )}
                        </div>
                        <span className="lb-comment-author text-xs font-normal font-destructive">
                            {label}
                        </span>
                    </div>
                );
            }
            case "customer group": {
                return (
                    <div className="flex items-center gap-1">
                        <GroupIcon />
                        {label}
                    </div>
                );
            }
            case "assembly bot": {
                return (
                    <div className="flex items-center gap-1.5 py-1.5 px-0.5">
                        <img src="https://avatars.slack-edge.com/2024-03-13/6794077921701_9cf81e90135145d4ed52_192.jpg" alt="Assembly" className="w-4 h-4" />
                        Assembly Bot
                    </div>
                )
            }
            default: {
                return <div>{valList}</div>;
            }
        }
    });

interface MultipleValuesFilterDisplayProps {
    type: string;
    valList: string[];
    users: GetUserResponse[];
    options: { label: string; value: string; color?: string }[];
}

const MultipleValuesFilterDisplay: React.FC<MultipleValuesFilterDisplayProps> =
    React.memo(({ type, valList, users, options }) => {
        switch (type) {
            case "Source": {
                const valIcons: IconEntry[] = valList.map((val) => {
                    const IconComponent =
                        integrationBackEndDataMappingToSvg.get(val) ??
                        QuestionMarkIcon;
                    return {
                        Component: IconComponent,
                        props: {
                            width: 15,
                            height: 15,
                            style: { marginLeft: "-2" },
                        },
                    };
                });
                return (
                    <div className="flex items-center ml-0.5">
                        {valIcons.map((icon, index) =>
                            React.createElement(icon.Component, {
                                ...icon.props,
                                key: index,
                            }),
                        )}
                    </div>
                );
            }
            case "Tag": {
                return (
                    <div className="flex items-center">
                        {valList.map((val) => (
                            <ComponentBooleanIcon
                                color={getColorLight(val)}
                                key={val}
                                className="-mx-0.5"
                            />
                        ))}
                    </div>
                );
            }
            case "Topic": {
                return (
                    <div className="flex items-center">
                        {valList.map((val) => {
                            const color = options.find(option => option.label === val)?.color
                            return (
                                <DotFilledIcon
                                    color={color && color !== "" ? color : "#9B9EF0"}
                                    style={{ transform: "scale(1.8)" }}
                                    key={val}
                                    className="-mx-1"
                                />
                            )
                        })}
                    </div>
                );
            }
            case "Status": {
                const valIcons: IconEntry[] = valList.map((val) => {
                    const IconComponent = getStatusIcon(val ?? "Unknown");
                    return {
                        Component: IconComponent,
                        props: {
                            width: 15,
                            height: 15,
                            style: { marginLeft: "-2" },
                        },
                    };
                });
                return (
                    <div className="flex items-center ml-0.5">
                        {valIcons.map((icon, index) =>
                            React.createElement(icon.Component, {
                                ...icon.props,
                                key: index,
                            }),
                        )}
                    </div>
                );
            }
            case "Assignee": {
                return (
                    <div className="flex items-center gap-2 mr-2.5">
                        {valList.map((val) => {
                            const user = users.find((user) => user.id === val);
                            const pictureURL = user?.picture_url;
                            return (
                                <div
                                    className="lb-avatar rounded-lg w-5 h-5 -mr-2.5"
                                    key={val}
                                >
                                    {pictureURL ? (
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={val}
                                        />
                                    ) : (
                                        <AvatarIcon className="w-6 h-6" />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                );
            }
            case "Customer Group": {
                return <GroupIcon />
            }
            default: {
                return <div>{valList.join(", ")}</div>;
            }
        }
    });

interface FilterProps {
    type: string;
    values: Set<string>;
    filters: Map<string, Set<string>>;
    setFilters: (filters: Map<string, Set<string>>) => void;
    handleItemSelect: (type: string, value: string) => () => void;
    topics: { color: string; label: string; value: string }[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    isSavedViewFilter: boolean; // Filter stays locked in, cannot change that filter type
}

export function Filter({
    type,
    values,
    filters,
    setFilters,
    handleItemSelect,
    topics,
    users,
    customerGroups,
    isSavedViewFilter
}: FilterProps) {
    const supportedDirectSelectFilterOptions = ["Assembly Bot"]
    const options = React.useMemo(
        () => getOptions(type, topics, users, customerGroups) ?? [],
        [type, topics, users],
    );
    const valList = React.useMemo(() => Array.from(values), [values]);

    const deleteFilter = (type: string) => () => {
        const newFilters = new Map(filters);
        newFilters.delete(type);
        setFilters(newFilters);
    };

    return (
        <div className="flex items-center bg-background mx-0.5">
            {supportedDirectSelectFilterOptions.includes(type) ?
                <ScnBadge
                    variant="outline"
                    className="flex items-center gap-1 py-0.5 px-2 font-normal"
                >
                    <SingleValueFilterDisplay
                        type={type}
                        valList={valList}
                        options={options}
                        users={users}
                    />
                    <Separator
                        orientation="vertical"
                        className="bg-sidebarBorder w-[2px] h-4 mx-1"
                    />
                    <div>
                        <Button
                            type="button"
                            variant="ghost"
                            onClick={deleteFilter(type)}
                            className="flex items-center hover:bg-muted text-xs rounded-md text-gray-700 hover:text-gray-950 h-6 w-6 p-0 m-0"
                        >
                            <Cross2Icon className="w-4 h-4" />
                        </Button>
                    </div>
                </ScnBadge>
                :
                <ScnBadge
                    variant="outline"
                    className="flex items-center gap-1 py-1 px-2 font-normal"
                >
                    {type}
                    <Separator
                        orientation="vertical"
                        className="bg-sidebarBorder w-[2px] h-4 mx-1"
                    />
                    {values.size === 1 ? (
                        <div className="flex items-center gap-1 font-normal">
                            is
                            <Separator
                                orientation="vertical"
                                className="bg-sidebarBorder w-[2px] h-4 mx-1.5"
                            />
                            <DropdownMenu key={type}>
                                <DropdownMenuTrigger asChild type="button">
                                    <div className="flex items-center gap-1.5">
                                        <SingleValueFilterDisplay
                                            type={type}
                                            valList={valList}
                                            options={options}
                                            users={users}
                                        />
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="start"
                                    className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                >
                                    <FilterDropdownElement
                                        type={type}
                                        filters={filters}
                                        handleItemSelect={handleItemSelect}
                                        topics={topics}
                                        users={users}
                                        customerGroups={customerGroups}
                                        isSavedViewFilter={isSavedViewFilter} />
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    ) : (
                        <div className="flex items-center gap-1 font-normal">
                            is any of
                            <Separator
                                orientation="vertical"
                                className="bg-sidebarBorder w-[2px] h-4 mx-1.5"
                            />
                            <DropdownMenu key={type}>
                                <DropdownMenuTrigger asChild type="button">
                                    <div className="flex items-center gap-1.5">
                                        <MultipleValuesFilterDisplay
                                            type={type}
                                            valList={valList}
                                            users={users}
                                            options={options}
                                        />
                                        {values.size} {getPlural(type)}
                                    </div>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="start"
                                    className="fixed w-[200px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                >
                                    <FilterDropdownElement
                                        type={type}
                                        filters={filters}
                                        handleItemSelect={handleItemSelect}
                                        topics={topics}
                                        users={users}
                                        customerGroups={customerGroups}
                                        isSavedViewFilter={isSavedViewFilter}
                                    />
                                </DropdownMenuContent>
                            </DropdownMenu>
                        </div>
                    )}
                    <Separator
                        orientation="vertical"
                        className="bg-sidebarBorder w-[2px] h-4 mx-1"
                    />
                    <div>
                        <Button
                            type="button"
                            variant="ghost"
                            onClick={deleteFilter(type)}
                            className="flex items-center hover:bg-muted text-xs rounded-md text-gray-700 hover:text-gray-950 h-6 w-6 p-0 m-0"
                        >
                            <Cross2Icon className="w-4 h-4" />
                        </Button>
                    </div>
                </ScnBadge>}
        </div>
    );
}