import Clock from "@/component/Timer";
import type {
    HistoryResponse,
    Ticket,
    UserResponse,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { DataList } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { memo, useMemo } from "react";
import { ReactComponent as SlackSvg } from "../images/integrations/slack.svg";
import { AssigneeDropdown } from "./AssigneeDropdown";
import CustomerProfile from "./CustomerProfile";
import { ExternalIssuesSection } from "./ExternalIssuesSection";
import InsightsSection from "./InsightsSection";
import InternalNotes from "./InternalNote";
import { TicketStatusDropdown } from "./TicketStatusDropdown";
import { TicketTopicsDropdown } from "./TicketTopicsDropdown";
interface SidebarCardContentProps {
    type: string;
    analytics: Ticket;
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    userID: string;
    customerUserInfo: UserResponse | null;
}

function SidebarCardContent({
    type,
    analytics,
    refetchTicketData,
    refetchThreadData,
    userID,
    customerUserInfo,
}: SidebarCardContentProps) {
    const source: string = analytics.source ?? "";
    const SourceSvgImage = useMemo(
        () => integrationBackEndDataMappingToSvg.get(source) ?? SlackSvg,
        [source],
    );

    switch (type) {
        case "Attributes": {
            const minDataListWidth = "80px";
            return (
                <DataList.Root size="3" trim={"both"} className="my-2 mb-4">
                    {analytics.breaching !== "" &&
                        analytics.breaching !== null &&
                        analytics.breaching !== undefined && (
                            <DataList.Item align="center">
                                <DataList.Label
                                    minWidth={`${minDataListWidth}`}
                                    className="text-xs"
                                >
                                    SLA
                                </DataList.Label>
                                <DataList.Value>
                                    <Clock
                                        targetTime={analytics.breaching}
                                        variant="outline"
                                    />
                                </DataList.Value>
                            </DataList.Item>
                        )}
                    <DataList.Item align="center">
                        <DataList.Label
                            minWidth={`${minDataListWidth}`}
                            className="text-xs"
                        >
                            Owner
                        </DataList.Label>
                        <DataList.Value>
                            <AssigneeDropdown
                                ticket={analytics}
                                userID={userID}
                                refetchThreadData={refetchThreadData}
                            />
                        </DataList.Value>
                    </DataList.Item>
                    <DataList.Item align="center">
                        <DataList.Label
                            minWidth={`${minDataListWidth}`}
                            className="text-xs"
                        >
                            Status
                        </DataList.Label>
                        <DataList.Value>
                            <TicketStatusDropdown
                                key={analytics.ticket_status}
                                ticket={analytics}
                                userID={userID}
                                refetchThreadData={refetchThreadData}
                            />
                        </DataList.Value>
                    </DataList.Item>
                </DataList.Root>
            );
        }
        case "Labels": {
            return (
                <div className="flex flex-row flex-wrap">
                    <TicketTopicsDropdown
                        ticket={analytics}
                        userID={userID}
                        refetchThreadData={refetchThreadData}
                    />
                </div>
            );
        }
        case "Customer Profile": {
            return (
                <div className="mb-2">
                    <CustomerProfile
                        userInfo={customerUserInfo}
                        source={source}
                    />
                </div>
            );
        }
        case "External Issues": {
            return (
                <div className="-mt-1">
                    <ExternalIssuesSection ticket={analytics} userID={userID} />
                </div>
            );
        }
        case "Insights": {
            return (
                <div className="-mt-1">
                    <InsightsSection
                        issue={analytics}
                        refetchTicketData={refetchTicketData}
                        userID={userID}
                    />
                </div>
            );
        }
        case "Internal Notes": {
            return (
                <div className="mb-2">
                    <InternalNotes
                        ticketId={analytics.id}
                        internalNote={analytics.internal_note}
                        source={source}
                        userId={userID}
                    />
                </div>
            );
        }
        default:
            return <div />;
    }
}

export default memo(SidebarCardContent);
