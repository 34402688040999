import {
    ChatBubbleIcon,
    CheckCircledIcon,
    CrossCircledIcon,
    ExclamationTriangleIcon,
    InfoCircledIcon,
    LightningBoltIcon,
    QuestionMarkCircledIcon,
} from "@radix-ui/react-icons";
import {
    Box,
    Card,
    Flex,
    Grid,
    Heading,
    Skeleton,
    Text,
} from "@radix-ui/themes";
import { useCallback, useEffect, useState } from "react";
import {
    CartesianGrid,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { URLS, API, TeamsAPI } from "../../constant";
import { useApi } from "../../interfaces/api";
import type {
    AnalyticsResponse,
    QueriesWithPaginationResponse,
    ResolutionTime,
    ResolutionTimeResponse,
    ResponseTime,
    ResponseTimeResponse,
    SLAResponse,
    Teams,
} from "../../interfaces/serverData";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import type { DateRange } from "react-day-picker";
import ResponseAnalyticsComponent from "./Analytics/ResponseAnalyticsPage";
import { Tabs } from "@radix-ui/themes";
import ResolutionAnalyticsComponent from "./Analytics/ResolutionAnalyticsPage";
import SLAAnalyticsComponent from "./Analytics/SLAAnalyticsPage";
import { LoaderIcon } from "lucide-react";

type BadgeColor = "gray" | "green" | "blue" | "red";

export interface ChartData {
    date: string;
    duration: number;
}

const AdminAnalyticsPage = () => {
    const api = useApi();
    const [incomingData, setIncomingData] = useState<AnalyticsResponse | null>(
        null,
    );
    const [chartData, setChartData] = useState<GroupedDataItem[]>([]);
    const [loadingState, setLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error
    const [chartLoadingState, setChartLoadingState] = useState<number>(0); // 0: loading, 1: loaded, 2: error

    const [responseChartData, setResponseChartData] = useState<ChartData[]>([]); // For the rolling average chart

    interface GroupedDataItem {
        date: string;
        count: number;
    }

    function getColor(input: string): BadgeColor {
        switch (input) {
            case "Query":
                return "green";
            case "Feature":
                return "blue";
            case "Bug":
                return "red";
            default:
                return "gray";
        }
    }

    function getIcon(input: string) {
        switch (input) {
            case "Query":
                return (
                    <QuestionMarkCircledIcon
                        style={iconStyle(getColor(input))}
                    />
                );
            case "Feature":
                return <LightningBoltIcon style={iconStyle(getColor(input))} />;
            case "Bug":
                return (
                    <ExclamationTriangleIcon
                        style={iconStyle(getColor(input))}
                    />
                );
            default:
                return <InfoCircledIcon style={iconStyle(getColor(input))} />;
        }
    }

    const iconStyle = (color: BadgeColor) => ({
        marginTop: "5px",
        marginLeft: "5px",
        transform: "scale(1.4)",
        color,
    });

    useEffect(() => {
        api.get(URLS.serverUrl + API.analytics, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setIncomingData(res.data.data);
                    setLoadingState(1);
                } else {
                    setLoadingState(2);
                }
            })
            .catch(() => setLoadingState(2));
    }, [api]);

    const convertTimestampToDate = (timestamp: string): string => {
        const date = new Date(timestamp);
        return date.toISOString().split("T")[0];
    };

    const fetchQueries = async ({
        pageParam = 0,
    }: { pageParam?: number }): Promise<QueriesWithPaginationResponse> => {
        try {
            const response = await api.get(
                URLS.serverUrl + API.queriesWithPagination,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    params: { limit: 1000, offset: pageParam },
                },
            );
            return response.status === 200
                ? response.data.data
                : { data: [], has_next_page: false, next_cursor: 0 };
        } catch {
            return { data: [], has_next_page: false, next_cursor: 0 };
        }
    };

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status,
    } = useInfiniteQuery({
        queryKey: ["queries"],
        queryFn: fetchQueries,
        getNextPageParam: (lastPage) => {
            if (lastPage.has_next_page) {
                return lastPage.next_cursor;
            }
            return undefined; // No more pages
        },
        initialPageParam: 0,
        refetchInterval: 30000,
        refetchOnWindowFocus: true,
    });

    const combinedData = data?.pages.flatMap((page) => page?.data || []) || [];

    useEffect(() => {
        if (hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

    useEffect(() => {
        const calculateChartData = () => {
            const parsedData = combinedData.map((item) => ({
                date: convertTimestampToDate(item.created_at),
            }));

            const groupedData = parsedData.reduce(
                (acc: { [key: string]: GroupedDataItem }, { date }) => {
                    acc[date] = acc[date] || { date, count: 0 };
                    acc[date].count += 1;
                    return acc;
                },
                {},
            );

            setChartData(Object.values(groupedData));
            setChartLoadingState(1);
        };

        if (status === "success") {
            calculateChartData();
        } else {
            setChartLoadingState(2);
        }
    }, [combinedData, status]);

    const [range, setRange] = useState<DateRange>();
    const [playgroundFilters, setPlaygroundFilters] = useState<
        Map<string, Set<string>>
    >(new Map());
    const [teamID, setTeamID] = useState<string>("");

    const responseTimeQuery = useQuery<ResponseTimeResponse>({
        queryKey: [
            "response_time",
            teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );

            // Convert Set to comma-separated string
            const sourceString = sourceFilter.join("&");
            const resp = await api.get(
                `${URLS.serverUrl}${API.getResponseTime}`,
                {
                    params: {
                        source: sourceString,
                        teamID:
                            teamID.length > 0 && teamID !== "*all"
                                ? teamID
                                : null,
                        start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                        end: range?.to?.toJSON() ?? new Date().toJSON(),
                    },
                    headers: { "Content-Type": "application/json" },
                },
            );
            return resp.status === 200 ? resp.data.data : null;
        },
        refetchOnWindowFocus: false, // Avoid unnecessary refetch
        refetchInterval: false,
    });

    const mapResponseTimeToChartData = (data: ResponseTime[]): ChartData[] => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // Iterate over response time data
        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            // Extract the date from the `start` field
            const date = item.start.split("T")[0]; // Group by day (YYYY-MM-DD)

            // Initialize if the date doesn't exist yet
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }

            // Sum the durations and count occurrences for the given day
            groupedData[date].totalDuration += item.duration;
            groupedData[date].count += 1;
        });

        // Calculate the average duration for each day and map to chart data
        return Object.entries(groupedData).map(
            ([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count, // Calculate the average response time
            }),
        );
    };

    const [responseTimePerDay, setResponseTimePerDay] = useState<ChartData[]>(
        [],
    );

    const calculateResponseTimePerDay = useCallback((data: ResponseTime[]) => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            const date = item.start.split("T")[0];
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }
            groupedData[date].totalDuration = item.duration;
            groupedData[date].count = 1;
        });

        return Object.entries(groupedData)
            .map(([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count,
            }))
            .sort((a, b) => a.date.localeCompare(b.date));
    }, []);

    useEffect(() => {
        if (responseTimeQuery.isSuccess && responseTimeQuery.data?.results) {
            const perDayData = calculateResponseTimePerDay(
                responseTimeQuery.data.results,
            );
            setResponseTimePerDay(perDayData);
        }
    }, [
        responseTimeQuery.data,
        responseTimeQuery.isSuccess,
        calculateResponseTimePerDay,
    ]);

    useEffect(() => {
        if (responseTimeQuery.isSuccess) {
            const chartData = mapResponseTimeToChartData(
                responseTimeQuery.data?.results || [],
            );
            setResponseChartData(chartData); // Grouped data by day
        }
    }, [responseTimeQuery.data, responseTimeQuery.isSuccess]);

    const handleFilterSelect = useCallback(
        (type: string, value: string) => () => {
            const newFilters = new Map(playgroundFilters);
            if (newFilters.has(type)) {
                const currValues = new Set(newFilters.get(type));
                if (currValues?.has(value)) {
                    currValues.delete(value);
                    if (currValues.size === 0) {
                        newFilters.delete(type);
                    } else {
                        newFilters.set(type, currValues);
                    }
                } else {
                    currValues.add(value);
                    newFilters.set(type, currValues);
                }
            } else {
                newFilters.set(type, new Set([value]));
            }
            setPlaygroundFilters(newFilters);
        },
        [playgroundFilters],
    );

    // Add new state for resolution time chart data and per day data
    const [resolutionChartData, setResolutionChartData] = useState<ChartData[]>(
        [],
    );
    const [resolutionTimePerDay, setResolutionTimePerDay] = useState<
        ChartData[]
    >([]);

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams", teamID],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listTeams;
            const res = await api.get(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                },
            });

            const teams: Teams[] = res.data.data;
            return teams;
        },
    });

    const resolutionTimeQuery = useQuery<ResolutionTimeResponse>({
        queryKey: [
            "resolution_time",
            teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );
            const sourceString = sourceFilter.join("&");
            const resp = await api.get(
                `${URLS.serverUrl}${API.getResolutionTime}`,
                {
                    params: {
                        source: sourceString,
                        teamID:
                            teamID.length > 0 && teamID !== "*all"
                                ? teamID
                                : null,
                        start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                        end: range?.to?.toJSON() ?? new Date().toJSON(),
                    },
                    headers: { "Content-Type": "application/json" },
                },
            );
            return resp.status === 200 ? resp.data.data : null;
        },
        refetchOnWindowFocus: false,
        refetchInterval: false,
    });

    // Function to map resolution time data to chart data
    const mapResolutionTimeToChartData = (
        data: ResolutionTime[],
    ): ChartData[] => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            const date = item.start.split("T")[0];
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }
            groupedData[date].totalDuration += item.duration;
            groupedData[date].count += 1;
        });

        return Object.entries(groupedData).map(
            ([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count,
            }),
        );
    };

    // Function to calculate resolution time per day
    const calculateResolutionTimePerDay = useCallback(
        (data: ResolutionTime[]) => {
            const groupedData: {
                [key: string]: { totalDuration: number; count: number };
            } = {};

            // biome-ignore lint/complexity/noForEach: <explanation>
            data.forEach((item) => {
                const date = item.start.split("T")[0];
                if (!groupedData[date]) {
                    groupedData[date] = { totalDuration: 0, count: 0 };
                }
                groupedData[date].totalDuration += item.duration;
                groupedData[date].count += 1;
            });

            return Object.entries(groupedData)
                .map(([date, { totalDuration, count }]) => ({
                    date,
                    duration: totalDuration / count,
                }))
                .sort((a, b) => a.date.localeCompare(b.date));
        },
        [],
    );

    // Set resolution time per day and chart data
    useEffect(() => {
        if (
            resolutionTimeQuery.isSuccess &&
            resolutionTimeQuery.data?.results
        ) {
            const perDayData = calculateResolutionTimePerDay(
                resolutionTimeQuery.data.results,
            );
            setResolutionTimePerDay(perDayData);
        }
    }, [
        resolutionTimeQuery.data,
        resolutionTimeQuery.isSuccess,
        calculateResolutionTimePerDay,
    ]);

    useEffect(() => {
        if (resolutionTimeQuery.isSuccess) {
            const chartData = mapResolutionTimeToChartData(
                resolutionTimeQuery.data?.results || [],
            );
            setResolutionChartData(chartData);
        }
    }, [resolutionTimeQuery.data, resolutionTimeQuery.isSuccess]);

    const slaTimeQuery = useQuery<SLAResponse>({
        queryKey: [
            "sla",
            teamID,
            Array.from(playgroundFilters.get("Source") ?? []),
            range?.from?.toISOString(),
            range?.to?.toISOString(),
        ],
        queryFn: async () => {
            const sourceFilter = Array.from(
                playgroundFilters.get("Source") ?? [],
            );

            // Convert Set to comma-separated string
            const sourceString = sourceFilter.join("&");
            const resp = await api.get(`${URLS.serverUrl}${API.getSLATime}`, {
                params: {
                    source: sourceString,
                    teamID:
                        teamID.length > 0 && teamID !== "*all" ? teamID : null,
                    start: range?.from?.toJSON() ?? new Date(0).toJSON(),
                    end: range?.to?.toJSON() ?? new Date().toJSON(),
                },
                headers: { "Content-Type": "application/json" },
            });
            return resp.status === 200 ? resp.data.data : null;
        },
        refetchOnWindowFocus: false, // Avoid unnecessary refetch
        refetchInterval: false,
    });

    // Add new state for SLA analytics data and per day data
    const [slaChartData, setSlaChartData] = useState<ChartData[]>([]);
    const [slaTimePerDay, setSlaTimePerDay] = useState<ChartData[]>([]);

    // Function to map SLA time data to chart data
    const mapSlaTimeToChartData = (data: ResolutionTime[]): ChartData[] => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            const date = item.start.split("T")[0];
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }
            groupedData[date].totalDuration += item.duration;
            groupedData[date].count += 1;
        });

        return Object.entries(groupedData).map(
            ([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count,
            }),
        );
    };

    // Function to calculate SLA time per day
    const calculateSlaTimePerDay = useCallback((data: ResolutionTime[]) => {
        const groupedData: {
            [key: string]: { totalDuration: number; count: number };
        } = {};

        // biome-ignore lint/complexity/noForEach: <explanation>
        data.forEach((item) => {
            const date = item.start.split("T")[0];
            if (!groupedData[date]) {
                groupedData[date] = { totalDuration: 0, count: 0 };
            }
            groupedData[date].totalDuration += item.duration;
            groupedData[date].count += 1;
        });

        return Object.entries(groupedData)
            .map(([date, { totalDuration, count }]) => ({
                date,
                duration: totalDuration / count,
            }))
            .sort((a, b) => a.date.localeCompare(b.date));
    }, []);

    // Set SLA time per day and chart data
    useEffect(() => {
        if (slaTimeQuery.isSuccess && slaTimeQuery.data?.results) {
            const perDayData = calculateSlaTimePerDay(
                slaTimeQuery.data.results,
            );
            setSlaTimePerDay(perDayData);
        }
    }, [slaTimeQuery.data, slaTimeQuery.isSuccess, calculateSlaTimePerDay]);

    useEffect(() => {
        if (slaTimeQuery.isSuccess) {
            const chartData = mapSlaTimeToChartData(
                slaTimeQuery.data?.results || [],
            );
            setSlaChartData(chartData);
        }
    }, [slaTimeQuery.data, slaTimeQuery.isSuccess]);

    const [activeTab, setActiveTab] = useState("response");

    return (
        <div className="bg-gray-500">
            <Box mt={"5%"} ml={"5%"} mr={"2%"}>
                <Flex direction="column" gap="9">
                    <Card style={{ backgroundColor: "#EFF0F3", width: "100%" }}>
                        <Flex direction="column" gap="6">
                            <Heading size="5">Analytics</Heading>
                            <Grid columns="6" gap="9">
                                <StatCard
                                    icon={
                                        <ChatBubbleIcon
                                            style={iconStyle("gray")}
                                        />
                                    }
                                    label="Interactions"
                                    value={incomingData?.count}
                                    isLoading={loadingState === 0}
                                />
                                <StatCard
                                    icon={
                                        <CheckCircledIcon
                                            style={iconStyle("green")}
                                        />
                                    }
                                    label="Resolved"
                                    value={incomingData?.resolved}
                                    isLoading={loadingState === 0}
                                />
                                <StatCard
                                    icon={
                                        <CrossCircledIcon
                                            style={iconStyle("red")}
                                        />
                                    }
                                    label="Escalated"
                                    value={incomingData?.escalated}
                                    isLoading={loadingState === 0}
                                />
                                {!incomingData && (
                                    <>
                                        <StatCard
                                            key={0}
                                            icon={<LoaderIcon />}
                                            label=""
                                            value={0}
                                            isLoading={true}
                                        />
                                        <StatCard
                                            key={1}
                                            icon={<LoaderIcon />}
                                            label=""
                                            value={0}
                                            isLoading={true}
                                        />
                                        <StatCard
                                            key={2}
                                            icon={<LoaderIcon />}
                                            label=""
                                            value={0}
                                            isLoading={true}
                                        />
                                    </>
                                )}
                                {incomingData?.categories &&
                                    Object.entries(incomingData.categories).map(
                                        ([key, val]) => (
                                            <StatCard
                                                key={key}
                                                icon={getIcon(key)}
                                                label={key}
                                                value={val}
                                                isLoading={loadingState === 0}
                                            />
                                        ),
                                    )}
                            </Grid>
                        </Flex>
                    </Card>

                    <Tabs.Root defaultValue="chat">
                        <Tabs.List>
                            <Tabs.Trigger value="chat">
                                Chat Messages
                            </Tabs.Trigger>
                            <Tabs.Trigger value="response">
                                Response Times
                            </Tabs.Trigger>
                            <Tabs.Trigger value="resolution">
                                Resolution Times
                            </Tabs.Trigger>
                            <Tabs.Trigger value="sla">SLA Times</Tabs.Trigger>
                        </Tabs.List>
                        <Tabs.Content value="chat">
                            <Card>
                                <Flex direction="column">
                                    <Text size="3" weight="bold">
                                        Chat Messages Submitted
                                    </Text>
                                    <Text size="3" color="gray">
                                        View messages submitted over time.
                                    </Text>
                                    {chartLoadingState === 0 && (
                                        <SkeletonText />
                                    )}
                                    {chartLoadingState === 1 && (
                                        <ResponsiveContainer height={400}>
                                            <LineChart data={chartData}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="date"
                                                    tickFormatter={(date) => {
                                                        const d = new Date(
                                                            date,
                                                        );
                                                        return `${d.getMonth() + 1}/${d.getDate()}`;
                                                    }}
                                                />
                                                <YAxis />
                                                <Tooltip />
                                                <Legend />
                                                <Line
                                                    type="monotone"
                                                    dataKey="count"
                                                    stroke="#5B5BD6"
                                                    activeDot={{ r: 8 }}
                                                />
                                            </LineChart>
                                        </ResponsiveContainer>
                                    )}
                                </Flex>
                            </Card>
                        </Tabs.Content>
                        <Tabs.Content value="response">
                            <ResponseAnalyticsComponent
                                teamID={teamID}
                                setTeamID={setTeamID}
                                teamsQueryStatus={teamsQuery.status}
                                teamsQueryIsError={teamsQuery.isError}
                                teamsQueryData={teamsQuery.data}
                                responseTimePerDay={responseTimePerDay}
                                responseChartData={responseChartData}
                                responseTimeQueryStatus={
                                    responseTimeQuery.status
                                }
                                responseTimeQueryIsError={
                                    responseTimeQuery.isError
                                }
                                responseTimeQueryData={responseTimeQuery.data}
                                range={range}
                                setRange={setRange}
                                playgroundFilters={playgroundFilters}
                                setPlaygroundFilters={setPlaygroundFilters}
                                handleFilterSelect={handleFilterSelect}
                            />
                        </Tabs.Content>
                        <Tabs.Content value="resolution">
                            <ResolutionAnalyticsComponent
                                teamID={teamID}
                                setTeamID={setTeamID}
                                teamsQueryStatus={teamsQuery.status}
                                teamsQueryIsError={teamsQuery.isError}
                                teamsQueryData={teamsQuery.data}
                                resolutionTimePerDay={resolutionTimePerDay}
                                resolutionChartData={resolutionChartData}
                                resolutionTimeQueryStatus={
                                    resolutionTimeQuery.status
                                }
                                resolutionTimeQueryIsError={
                                    resolutionTimeQuery.isError
                                }
                                resolutionTimeQueryData={
                                    resolutionTimeQuery.data
                                }
                                range={range}
                                setRange={setRange}
                                playgroundFilters={playgroundFilters}
                                setPlaygroundFilters={setPlaygroundFilters}
                                handleFilterSelect={handleFilterSelect}
                            />
                        </Tabs.Content>
                        <Tabs.Content value="sla">
                            <SLAAnalyticsComponent
                                teamID={teamID}
                                setTeamID={setTeamID}
                                teamsQueryStatus={teamsQuery.status}
                                teamsQueryIsError={teamsQuery.isError}
                                teamsQueryData={teamsQuery.data}
                                slaTimePerDay={slaTimePerDay}
                                slaChartData={slaChartData}
                                slaTimeQueryStatus={slaTimeQuery.status}
                                slaTimeQueryIsError={slaTimeQuery.isError}
                                slaTimeQueryData={slaTimeQuery.data}
                                range={range}
                                setRange={setRange}
                                playgroundFilters={playgroundFilters}
                                setPlaygroundFilters={setPlaygroundFilters}
                                handleFilterSelect={handleFilterSelect}
                            />
                        </Tabs.Content>
                    </Tabs.Root>
                    <div className="mb-8" />
                </Flex>
            </Box>
        </div>
    );
};

// Helper Component: StatCard
interface StatCardProps {
    icon: React.ReactNode; // JSX.Element for the icon
    label: string; // Label for the stat
    value?: number; // Optional value for the stat
    isLoading: boolean; // Loading state for skeleton
}

const StatCard = ({ icon, label, value, isLoading }: StatCardProps) => (
    <Card>
        <Flex direction="row" align="start" gap="2" justify="between">
            {icon}
            <Flex direction="column" gap="1" align="end">
                {isLoading ? (
                    <Skeleton>
                        <Text size="5" color="gray" weight="bold">
                            000
                        </Text>
                    </Skeleton>
                ) : (
                    <Text size="5" color="gray" weight="bold">
                        {value ?? 0}
                    </Text>
                )}
                <Text size="3" color="gray">
                    {label}
                </Text>
            </Flex>
        </Flex>
    </Card>
);

// Helper Component: SkeletonText
const SkeletonText = () => (
    <Skeleton>
        <Text size="5" color="gray">
            Loading data...
        </Text>
    </Skeleton>
);

export default AdminAnalyticsPage;
