import { Card, CardContent } from "@/component/shadcn/ui/card";
import type {
    Customer,
    GetUserResponse,
    IconEntry,
} from "@/interfaces/serverData";
import { CrossCircledIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import React, { useCallback, useEffect, useRef } from "react";

import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import debounce from "lodash/debounce";
import { integrationBackEndDataMappingToSvg } from "../../Integrations/constant";
import { CustomerState } from "./constants";

interface CustomersListCardProps {
    customer: Customer;
    userID: string;
    users: GetUserResponse[];
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setToastText: React.Dispatch<React.SetStateAction<string>>;
    setToastSymbol: React.Dispatch<React.SetStateAction<React.ElementType>>;
    forceUpdate: () => void;
}

export function CustomersListCard({
    customer,
    userID,
    users,
    setOpen,
    setToastText,
    setToastSymbol,
    forceUpdate,
}: CustomersListCardProps) {
    const navigate = useNavigate();

    const [customerState, setCustomerState] = useState<Customer>(customer);

    const badgeLength = (customer?.name?.length ?? 3) * 0.8;

    const [limitCount, setLimitCount] = useState(
        window.innerWidth * 0.08 - badgeLength,
    );

    const date: string = useMemo(() => {
        let updatedDate = new Date(customerState.updated_at);

        if (Number.isNaN(updatedDate.getTime()) || !customerState.updated_at) {
            updatedDate = new Date();
        }

        const today = new Date();

        const isToday =
            updatedDate.getDate() === today.getDate() &&
            updatedDate.getMonth() === today.getMonth() &&
            updatedDate.getFullYear() === today.getFullYear();

        if (isToday) {
            const userLocale = navigator.language || "en-US";
            return updatedDate.toLocaleTimeString(userLocale, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
            });
        }

        // Otherwise, return the standard date format
        const userLocale = navigator.language || "en-US";
        return updatedDate.toLocaleDateString(userLocale, {
            month: "short",
            day: "numeric",
        });
    }, [customerState.updated_at]);

    const timerRef = useRef(0);
    const api = useApi();
    const handleDelete = async () => {
        const status = CustomerState.delete;
        const requestData = {
            status: status,
            id: customer.id,
        };

        api.post(URLS.serverUrl + API.announcement, requestData, {
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    setToastText("Announcement deleted!");
                    forceUpdate(); // forces an customer update
                } else {
                    setToastText(
                        "Oops! Something's wrong. Please try again at a later time.",
                    );
                    setToastSymbol(CrossCircledIcon);
                    console.log("could not create a draft customer");
                }
            })
            .catch((res) => {
                setToastText(
                    "Oops! Something's wrong. Please try again at a later time.",
                );
                setToastSymbol(CrossCircledIcon);
            })
            .finally(() => {
                setOpen(false);
                window.clearTimeout(timerRef.current);
                timerRef.current = window.setTimeout(() => {
                    console.log("setting open to true");
                    setOpen(true);
                }, 100);
            });
    };

    const [serviceNames, setServiceNames] = useState<string[]>([]);

    useEffect(() => {
        if (!customerState.metadata) {
            return;
        }

        // try {
        //     const data = JSON.parse(customerState.metadata);
        //     const keys = Object.keys(data);
        //     setServiceNames(keys);
        // } catch (error) {
        //     console.error("Error parsing JSON:", error);
        // }
    }, [customerState.metadata]);

    const serviceIcons = useMemo(() => {
        const icons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        serviceNames.forEach((serviceName) => {
            const IconComponent =
                integrationBackEndDataMappingToSvg.get(serviceName);
            if (IconComponent) {
                icons.add({
                    Component: IconComponent,
                    props: {
                        width: 20,
                        height: 20,
                        style: { marginLeft: "2px", marginRight: "2px" },
                    },
                });
            }
        });
        return icons;
    }, [serviceNames]);

    const location = useLocation();

    const handleRowClick = (id: string) => {
        const newPath = `${location.pathname}/${id}`;
        navigate(newPath, { replace: true });
    };

    const [titleMaxWidth, setTitleMaxWidth] = useState(
        window.innerWidth * 0.75,
    );

    const updateDimensions = useCallback(() => {
        setTitleMaxWidth(window.innerWidth * 0.75);
        setLimitCount(window.innerWidth * 0.08 - badgeLength);
    }, []);

    const debouncedUpdateDimensions = useMemo(
        () => debounce(updateDimensions, 200),
        [updateDimensions],
    );

    // Directly update dimensions when issue or topics change
    useEffect(() => {
        updateDimensions();
    }, [customerState]);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);

    useEffect(() => {
        // Handle window resize event with debounce
        debouncedUpdateDimensions();
        window.addEventListener("resize", debouncedUpdateDimensions);
        return () =>
            window.removeEventListener("resize", debouncedUpdateDimensions);
    }, [debouncedUpdateDimensions]);

    return (
        <Card
            className="py-2.5 pl-8 pr-6 border-l-transparent border-r-transparent border-b-transparent hover:bg-muted rounded w-full"
            onClick={() => handleRowClick(`${customerState.id}`)}
        >
            <CardContent className="p-0">
                <button
                    className="text-xs bg-transparent border-none p-0 cursor-pointer w-full"
                    type="button"
                >
                    <div className="flex items-center justify-between w-full gap-2">
                        <div
                            className="flex items-center gap-2"
                            style={{ maxWidth: titleMaxWidth }}
                        >
                            {customerState.name !== "" &&
                                customerState.name !== undefined && (
                                    <p className="text-xs m-0 pl-2">
                                        {customerState.name}
                                    </p>
                                )}

                            {customerState.name !== "" &&
                                customerState.name !== undefined && (
                                    <Badge
                                        color="iris"
                                        size="1"
                                        radius="small"
                                        variant="outline"
                                        className="px-2 py-1 rounded-xl text-xs m-0"
                                    >
                                        {customerState.domain}
                                    </Badge>
                                )}
                        </div>

                        <div className="flex items-center justify-end">
                            <div className="mx-1.5 text-[12px]">{date}</div>

                            {Array.from(serviceIcons).map((icon) =>
                                React.createElement(icon.Component, icon.props),
                            )}
                            {/* {customerState.user_id &&
                            customerState.user_id !== "noAssignee" ? (
                                <div className="lb-avatar rounded-lg w-6 h-6 mx-1">
                                    {pictureURL && (
                                        <img
                                            className="lb-avatar-image"
                                            src={pictureURL}
                                            alt={userName}
                                        />
                                    )}
                                    <span>{userName ?? ""}</span>
                                </div>
                            ) : (
                                <AvatarIcon className="w-6 h-6 mx-1" />
                            )} */}

                            {/* <DropdownMenu>
                                <DropdownMenuTrigger asChild>
                                    <Button
                                        variant="ghost"
                                        className="ml-1 flex h-8 w-8 p-0 data-[state=open]:bg-muted"
                                    >
                                        <DotsHorizontalIcon className="h-4 w-4" />
                                    </Button>
                                </DropdownMenuTrigger>
                                <DropdownMenuContent
                                    align="end"
                                    className="w-[150px] max-h-60 p-0 rounded-md overflow-y-auto"
                                    onClick={(event) => event.stopPropagation()}
                                >
                                    <DropdownMenuItem asChild>
                                        <AlertDialog
                                            open={deleteDialogOpen}
                                            onOpenChange={setDeleteDialogOpen}
                                        >
                                            <AlertDialogTrigger asChild>
                                                <div className="h-7 px-2 m-1 hover:bg-muted cursor-pointer text-xs flex flex-row items-center">
                                                    <p className="flex-grow">
                                                        Delete
                                                    </p>
                                                    <TrashIcon className="justify-self-end" />
                                                </div>
                                            </AlertDialogTrigger>
                                            <AlertDialogContent className="fixed inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-50">
                                                <div className="bg-white shadow-lg rounded-md py-3 px-7 overflow-auto relative">
                                                    <div>
                                                        <AlertDialogHeader className="pt-1 justify-left text-left items-left pb-7">
                                                            <AlertDialogTitle>
                                                                Are you
                                                                absolutely sure?
                                                            </AlertDialogTitle>
                                                            <AlertDialogDescription>
                                                                This action
                                                                cannot be
                                                                undone. This
                                                                will permanently
                                                                delete this
                                                                Announcement.
                                                            </AlertDialogDescription>
                                                        </AlertDialogHeader>
                                                    </div>
                                                    <AlertDialogFooter className="justify-end items-end pb-5 flex flex-row gap-4 ">
                                                        <AlertDialogCancel>
                                                            Cancel
                                                        </AlertDialogCancel>
                                                        <AlertDialogAction
                                                            onClick={
                                                                handleDelete
                                                            }
                                                        >
                                                            Delete
                                                        </AlertDialogAction>
                                                        <AlertDialogCancel className="shadow-none absolute top-0 right-2 px-2 py-2 ">
                                                            <Cross2Icon />
                                                        </AlertDialogCancel>
                                                    </AlertDialogFooter>
                                                </div>
                                            </AlertDialogContent>
                                        </AlertDialog>
                                    </DropdownMenuItem>
                                </DropdownMenuContent>
                            </DropdownMenu> */}
                        </div>
                    </div>
                </button>
            </CardContent>
        </Card>
    );
}
