import { Button } from "@/component/shadcn/ui/button";
import { Card } from "@/component/shadcn/ui/card";
import type {
    HistoryResponse,
    Ticket,
    UserResponse,
} from "@/interfaces/serverData";
import { CaretDownIcon, CaretUpIcon } from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import type {
    QueryObserverResult,
    RefetchOptions,
} from "@tanstack/react-query";
import { memo } from "react";
import SidebarCardContent from "./SidebarCardContent";

interface SidebarCardProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    sidebarLabels: string;
    type: string;
    analytics: Ticket;
    refetchTicketData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<Ticket | null, Error>>;
    refetchThreadData: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<HistoryResponse[], Error>>;
    userID: string;
    customerUserInfo: UserResponse | null;
    alertNum?: number;
}

function SidebarCard({
    isOpen,
    setIsOpen,
    sidebarLabels,
    type,
    analytics,
    refetchTicketData,
    refetchThreadData,
    userID,
    customerUserInfo,
    alertNum,
}: SidebarCardProps) {
    return (
        <Card className="rounded-lg  hover:outline-0.5 hover:outline-offset-0 flex flex-col px-3 py-2 shadow-sm border relative bg-white">
            <div
                className={`flex items-center justify-between ${isOpen && "mb-3"}`}
            >
                <div className={`flex items-center gap-1.5 ${sidebarLabels}`}>
                    {type}
                    {alertNum !== undefined && alertNum > 0 && (
                        <Badge
                            variant="outline"
                            color="gray"
                            className="text-[9px] p-1 py-0"
                        >
                            {alertNum}
                        </Badge>
                    )}
                </div>
                {isOpen ? (
                    <Button
                        type="button"
                        variant="ghost"
                        className="text-xs p-0.5"
                        onClick={() => setIsOpen(false)}
                    >
                        <CaretUpIcon className="w-4 h-4" />
                    </Button>
                ) : (
                    <Button
                        type="button"
                        variant="ghost"
                        className="text-xs p-0.5"
                        onClick={() => setIsOpen(true)}
                    >
                        <CaretDownIcon className="w-4 h-4" />
                    </Button>
                )}
            </div>
            {isOpen && (
                <SidebarCardContent
                    type={type}
                    analytics={analytics}
                    refetchTicketData={refetchTicketData}
                    refetchThreadData={refetchThreadData}
                    userID={userID}
                    customerUserInfo={customerUserInfo}
                />
            )}
        </Card>
    );
}

export default memo(SidebarCard);
