import FilterDropdownElement from "@/IssuesTable/FilterDropdownElement";
import { FancyMultiSelect } from "@/component/MultiSelect";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/component/shadcn/ui/alert";
import { Button } from "@/component/shadcn/ui/button";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/component/shadcn/ui/dialog";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/component/shadcn/ui/dropdown-menu";
import { Input } from "@/component/shadcn/ui/input";
import { Label } from "@/component/shadcn/ui/label";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/component/shadcn/ui/select";
import type { GetUserResponse, ScopeResponse } from "@/interfaces/serverData";
import {
    Cross2Icon,
    DotFilledIcon,
    DoubleArrowRightIcon,
    ExclamationTriangleIcon,
    PersonIcon,
    PlusIcon,
} from "@radix-ui/react-icons";
import { Badge, ChevronDownIcon, Flex, Separator } from "@radix-ui/themes";
import type { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import type { AxiosResponse } from "axios";
import type React from "react";
import { useEffect, useState } from "react";
import type { Workflow } from "./Workflows";

const DropdownTrigger = (
    type: string,
    filters: Map<string, Set<string>>,
    topics: { color: string; label: string; value: string }[],
    selectedTopic: string | undefined,
    selectedSource: string | undefined,
) => {
    switch (type) {
        case "Source": {
            return (
                <Badge
                    color="gray"
                    size="2"
                    radius="full"
                    variant="outline"
                    className="px-2 py-1 gap-2 text-xs rounded-xl flex items-center justify-between min-w-[100px]"
                >
                    {selectedSource === undefined ? (
                        <>Source...</>
                    ) : (
                        <p>
                            Issue Source: <b>{selectedSource}</b>
                        </p>
                    )}
                    <ChevronDownIcon className="ml-auto" />
                </Badge>
            );
        }
        case "Topic": {
            const color = topics.find(
                (topic) => topic.value === selectedTopic,
            )?.color;
            return (
                <Badge
                    color="gray"
                    size="2"
                    radius="full"
                    variant="outline"
                    className="px-2 py-1 gap-2 text-xs rounded-xl flex flex-row items-center justify-between min-w-[100px]"
                >
                    {selectedTopic === undefined ? (
                        <>Topic...</>
                    ) : (
                        <div className="flex flex-row items-center">
                            <p className="mr-1">Topic:</p>
                            <DotFilledIcon
                                color={color ?? "#9B9EF0"}
                                style={{ transform: "scale(1.8)" }}
                            />
                            <b>{selectedTopic}</b>
                        </div>
                    )}
                    <ChevronDownIcon className="ml-auto" />
                </Badge>
            );
        }
    }
};

interface WorkflowPopupProps {
    workflow: Workflow | undefined;
    topics: { color: string; label: string; value: string }[];
    selectedSource: string | undefined;
    setSelectedSource: React.Dispatch<React.SetStateAction<string | undefined>>;
    options: Map<string, ScopeResponse[]>;
    supportedWhen: string[];
    setSupportedWhen: React.Dispatch<React.SetStateAction<string[]>>;
    selectedChannels: ScopeResponse[];
    setSelectedChannels: React.Dispatch<React.SetStateAction<ScopeResponse[]>>;
    supportedFields: string[];
    setSupportedFields: React.Dispatch<React.SetStateAction<string[]>>;
    supportedThen: string[];
    setSupportedThen: React.Dispatch<React.SetStateAction<string[]>>;
    supportedThenValues: UseQueryResult<{ users: GetUserResponse[] }, Error>;
    selectedTopic: string | undefined;
    setSelectedTopic: React.Dispatch<React.SetStateAction<string | undefined>>;
    addWorkflow: UseMutationResult<
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        AxiosResponse<any, any>,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        any,
        Workflow,
        unknown
    >;
    updateWorkflow: UseMutationResult<
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        AxiosResponse<any, any>,
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        any,
        Workflow,
        unknown
    >;
    icon?: React.ElementType;
    triggerElement: React.ReactNode;
    editing: boolean;
    clearWorkflowDetails: () => void;
}

const WorkflowPopup: React.FC<WorkflowPopupProps> = ({
    workflow,
    selectedSource,
    setSelectedSource,
    options,
    selectedChannels,
    setSelectedChannels,
    supportedFields,
    setSupportedFields,
    selectedTopic,
    setSelectedTopic,
    addWorkflow,
    updateWorkflow,
    icon,
    triggerElement,
    editing,
    supportedWhen,
    setSupportedWhen,
    supportedThen,
    setSupportedThen,
    supportedThenValues,
    clearWorkflowDetails,
    topics,
}) => {
    useEffect(() => {
        if (workflow) {
            setSelectedSource(workflow.if.source === "*" ? "All" : workflow.if.source);
            setSelectedTopic(workflow.if.topic === "*" ? "All" : workflow.if.topic);
            setSelectedChannels(workflow.if.channels);
        }
    }, [setSelectedChannels, setSelectedSource, setSelectedTopic, workflow]);

    const dropdownTypes = ["Source", "Topic"];

    // const { searchBarFilters, loading } = useSearch(); // using this for checking if slack integration is active
    const [open, setOpen] = useState<boolean>(false);

    const filters = new Map<string, Set<string>>();
    filters.set("Source", new Set([selectedSource ?? ""]));
    filters.set("Topic", new Set([selectedTopic ?? ""]));

    const [selectedWhen, setSelectedWhen] = useState<string | undefined>(
        workflow?.when || "",
    ); // Prepopulate with workflow "when"
    const [selectedThen, setSelectedThen] = useState<string | undefined>(
        workflow?.then[0]?.action || "",
    ); // Prepopulate "then" action
    const [selectedUser, setSelectedUser] = useState<string | undefined>(() => {
        if (workflow) {
            const objMap = new Map(Object.entries(workflow.then[0]?.metadata));
            return objMap.get("user");
        } else {
            return undefined;
        }
    }); // Prepopulate user (if applicable)

    const [workflowTitle, setWorkflowTitle] = useState<string>("New Workflow");
    const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
    const [validationErr, setValidationErr] = useState<boolean>(false);

    const validation = async () => {
        setValidationErr(false);
        setErrorMsg(undefined);
        if (selectedWhen === undefined) {
            setErrorMsg("Please select a trigger");
            setValidationErr(true);
        }
        if (selectedTopic === undefined) {
            setErrorMsg("Please select a topic");
            setValidationErr(true);
        }
        if (selectedThen === undefined) {
            setErrorMsg("Please select an action");
            setValidationErr(true);
        }
        if (selectedThen === "Assign Issue To" && selectedUser === undefined) {
            setErrorMsg("Please select a user");
            setValidationErr(true);
        }
        if (selectedSource === undefined) {
            setErrorMsg("Please select a source");
            setValidationErr(true);
        }
        if (validationErr === false) {
            const source = selectedSource === "All" ? "*" : selectedSource;
            const topic = selectedTopic === "All" ? "*" : selectedTopic;
            if (selectedThen === "Assign Issue To") {
                const map = new Map<string, string>();
                map.set("user", selectedUser ?? "");
                const newWorkflow: Workflow = {
                    name: workflowTitle,
                    // biome-ignore lint/style/noNonNullAssertion: already checked null above
                    when: selectedWhen!,
                    if: {
                        // biome-ignore lint/style/noNonNullAssertion: already checked null above
                        source: source!,
                        // biome-ignore lint/style/noNonNullAssertion: already checked null above
                        topic: topic!,
                        channels: selectedChannels,
                    },
                    // biome-ignore lint/suspicious/noThenProperty: inapplicable
                    then: [
                        {
                            action: "Assign Issue To",
                            metadata: Object.fromEntries(map),
                        },
                    ],
                };
                if (editing) {
                    newWorkflow.id = workflow?.id;
                    updateWorkflow.mutate(newWorkflow);
                    clearWorkflowDetails();
                } else {
                    addWorkflow.mutate(newWorkflow);
                    clearWorkflowDetails();
                }
                setOpen(false);
            }
        }
    };

    const handleItemSelect = (type: string, value: string) => () => {
        switch (type) {
            case "Topic": {
                setSelectedTopic(value);
                filters.set("Topic", new Set([value]));
                return;
            }
            case "Source": {
                setSelectedSource(value);
                filters.set("Source", new Set([value]));
                return;
            }
            default:
                console.log("handleItemSelect does not support type ", type);
        }
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild className="outline-none">{triggerElement}</DialogTrigger>
            <DialogContent className="fixed inset-0 z-50 flex flex-col items-center justify-center p-5 bg-black bg-opacity-50">
                <div className="bg-white shadow-lg rounded-md p-7 w-1/2 overflow-auto relative">
                    <div className="ml-10 mr-10">
                        <DialogHeader className="justify-left text-left items-left pb-5">
                            <DialogTitle>{(editing ? "Edit Workflow" : "New Workflow")}</DialogTitle>
                            <DialogDescription>
                                Workflows allow you to define a set of rules
                                that will be applied to your data.
                            </DialogDescription>
                        </DialogHeader>
                        <div className="flex flex-col ">
                            <div className="flex flex-col gap-4">
                                <Label>TITLE</Label>
                                <Input value={workflowTitle} onChange={(e) => {
                                    setWorkflowTitle(e.target.value);
                                }} className="w-full rounded-full mb-5" />
                            </div>
                            <div className="flex flex-row gap-3">
                                <div className="flex flex-col items-center">
                                    <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                        <DoubleArrowRightIcon className="text-iris9" />
                                    </div>
                                    <Separator
                                        orientation="vertical"
                                        className="bg-[#D8D8D8] w-[1px] h-16"
                                    />
                                </div>
                                <div className="flex flex-col pt-1 gap-2">
                                    <Label>WHEN...</Label>
                                    <div className="flex items-center border border-[#D8D8D8] rounded-md px-2 shadow-sm bg-white pl-2">
                                        <Select
                                            value={selectedWhen}
                                            onValueChange={setSelectedWhen}
                                        >
                                            <SelectTrigger className="w=full">
                                                <SelectValue placeholder="Select a trigger" />
                                            </SelectTrigger>
                                            <SelectContent className="w-full">
                                                {supportedWhen.map((when) => (
                                                    <SelectItem
                                                        key={when}
                                                        value={when}
                                                        className="w-full place-items-start"
                                                    >
                                                        <div className="flex flex-row gap-2 items-center w-full">
                                                            <div className="flex items-center justify-center rounded-lg p-1 bg-mint3 border border-mint4 shadow-sm">
                                                                <PlusIcon className="text-mint11" />
                                                            </div>
                                                            <span className="flex-1">
                                                                {when}
                                                            </span>
                                                        </div>
                                                    </SelectItem>
                                                ))}
                                            </SelectContent>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-row gap-3">
                                <div className="flex flex-col items-center">
                                    <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                        <DoubleArrowRightIcon className="text-iris9" />
                                    </div>
                                    <Separator
                                        orientation="vertical"
                                        className={`bg-[#D8D8D8] w-[1px] ${["Discord", "Slack"].includes(selectedSource ?? "") ? "h-24" : "h-16"}`}
                                    />
                                </div>

                                <div className="flex flex-col pt-1 gap-2">
                                    <Label>IF...</Label>
                                    <div className="flex items-center px-2 py-1 bg-white pl-2">
                                        <Flex className="flex flex-col gap-5">
                                            <div className="flex flex-row items-center gap-3">
                                                {dropdownTypes.map((type) => {
                                                    if (
                                                        type === "Channel" &&
                                                        ![
                                                            "Slack",
                                                            "CommunitySlack",
                                                            "Discord",
                                                        ].includes(
                                                            selectedSource ??
                                                            "",
                                                        )
                                                    ) {
                                                        return <></>;
                                                    }
                                                    return (
                                                        <>
                                                            <DropdownMenu
                                                                key={type}
                                                            >
                                                                <DropdownMenuTrigger
                                                                    asChild
                                                                    type="button"
                                                                >
                                                                    {DropdownTrigger(
                                                                        type,
                                                                        filters,
                                                                        topics,
                                                                        selectedTopic,
                                                                        selectedSource,
                                                                    )}
                                                                </DropdownMenuTrigger>
                                                                <DropdownMenuContent
                                                                    align="start"
                                                                    className="fixed w-[300px] max-h-60 p-0 bg-muted rounded-md shadow-lg overflow-y-auto"
                                                                >
                                                                    <FilterDropdownElement
                                                                        type={type}
                                                                        filters={
                                                                            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                                                                            new Map<string, Set<any>>(
                                                                                Array
                                                                                    .from(options.keys())
                                                                                    .map((key) =>
                                                                                        [key, new Set()]
                                                                                    )
                                                                            )
                                                                        }
                                                                        handleItemSelect={handleItemSelect}
                                                                        topics={topics}
                                                                        users={[]}
                                                                        extraOptions={[
                                                                            {
                                                                                label: "All",
                                                                                value: "All",
                                                                                color: "gray",
                                                                            },
                                                                        ]}
                                                                        channels={options}
                                                                        key={selectedSource}
                                                                    />
                                                                </DropdownMenuContent>
                                                            </DropdownMenu>
                                                        </>
                                                    );
                                                })}
                                            </div>
                                            {selectedSource === "Slack" && (
                                                <FancyMultiSelect
                                                    setSelectedChannels={
                                                        setSelectedChannels
                                                    }
                                                    selectedChannels={
                                                        selectedChannels
                                                    }
                                                    options={
                                                        options.get("Slack") ??
                                                        []
                                                    }
                                                    placeholder="Issue Source subchannels..."
                                                />
                                            )}
                                            {selectedSource === "CommunitySlack" && (
                                                <FancyMultiSelect
                                                    setSelectedChannels={
                                                        setSelectedChannels
                                                    }
                                                    selectedChannels={
                                                        selectedChannels
                                                    }
                                                    options={
                                                        options.get("CommunitySlack") ??
                                                        []
                                                    }
                                                    placeholder="Issue Source subchannels..."
                                                />
                                            )}
                                            {selectedSource === "Discord" && (
                                                <FancyMultiSelect
                                                    setSelectedChannels={
                                                        setSelectedChannels
                                                    }
                                                    selectedChannels={
                                                        selectedChannels
                                                    }
                                                    options={
                                                        options.get(
                                                            "Discord",
                                                        ) ?? []
                                                    }
                                                    placeholder="Issue Source subchannels..."
                                                />
                                            )}
                                        </Flex>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-row gap-3">
                                <div className="flex flex-col items-center">
                                    <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                        <DoubleArrowRightIcon className="text-iris9" />
                                    </div>
                                    <Separator
                                        orientation="vertical"
                                        className="bg-[#D8D8D8] w-[1px]  h-16"
                                    />
                                </div>

                                <div className="flex flex-col pt-1 gap-2">
                                    <Label>THEN...</Label>
                                    <div className="flex items-center px-2 py-1 bg-white pl-2">
                                        <Flex>
                                            <Select
                                                value={selectedThen}
                                                onValueChange={setSelectedThen}
                                            >
                                                <SelectTrigger className="w-[180px]">
                                                    <SelectValue placeholder="Select an action" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {supportedThen.map(
                                                        (then) => (
                                                            <SelectItem
                                                                key={then}
                                                                value={then}
                                                            >
                                                                <div className="flex flex-row gap-2 items-center w-full">
                                                                    <div className="flex items-center justify-center rounded-lg p-1 bg-mint3 border border-mint4 shadow-sm">
                                                                        <PersonIcon className="text-mint12" />
                                                                    </div>
                                                                    <span className="flex-1">
                                                                        {then}
                                                                    </span>
                                                                </div>
                                                            </SelectItem>
                                                        ),
                                                    )}
                                                </SelectContent>
                                            </Select>
                                            {selectedThen ===
                                                "Assign Issue To" ? (
                                                supportedThenValues.error ? (
                                                    "ERROR"
                                                ) : supportedThenValues.isLoading ? (
                                                    "LOADING"
                                                ) : (
                                                    <Select
                                                        value={selectedUser}
                                                        onValueChange={
                                                            setSelectedUser
                                                        }
                                                    >
                                                        <SelectTrigger className="w-[180px]">
                                                            <SelectValue placeholder="Select a user" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            {supportedThenValues.data?.users.map(
                                                                (user) => (
                                                                    <SelectItem
                                                                        key={
                                                                            user.id
                                                                        }
                                                                        value={
                                                                            user.id
                                                                        }
                                                                    >
                                                                        {user.username ??
                                                                            ""}
                                                                    </SelectItem>
                                                                ),
                                                            )}
                                                        </SelectContent>
                                                    </Select>
                                                )
                                            ) : (
                                                <></>
                                            )}
                                        </Flex>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <DialogFooter className="flex flex-col">
                            <div className="pt-4 justify-end text-end items-end">
                                <Button
                                    className="bg-iris9"
                                    type="submit"
                                    onClick={validation}
                                >
                                    Save
                                </Button>
                            </div>
                            {validationErr && (
                                <Alert variant="destructive" className="mt-4">
                                    <div className="flex flex-row gap-3 items-center">
                                        <ExclamationTriangleIcon className="h-4 w-4" />
                                        <div>
                                            <AlertTitle>Error</AlertTitle>
                                            <AlertDescription>
                                                {errorMsg ?? ""}
                                            </AlertDescription>
                                        </div>
                                    </div>
                                </Alert>
                            )}
                            <DialogClose asChild>
                                <Button
                                    type="button"
                                    variant="ghost"
                                    className="absolute top-4 right-4"
                                    onClick={() => {
                                        setValidationErr(false);
                                        if (!editing) {
                                            clearWorkflowDetails();
                                        }
                                    }}
                                >
                                    <Cross2Icon />
                                </Button>
                            </DialogClose>
                        </DialogFooter>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default WorkflowPopup;
