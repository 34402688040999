import { API, URLS } from "@/constant";
import { useApi } from "@/interfaces/api";
import type { HistoryResponse, UserResponse } from "@/interfaces/serverData.js";
import { Timestamp } from "@liveblocks/react-ui/primitives";
import {
    BookmarkIcon,
    CircleIcon,
    DotFilledIcon,
    DoubleArrowRightIcon,
    LightningBoltIcon,
    Link2Icon,
    UpdateIcon,
} from "@radix-ui/react-icons";
import { Strong, Text } from "@radix-ui/themes";
import clsx from "clsx";
import { useEffect, useState } from "react";
import type { ComponentProps } from "react";

/**
 * Custom comment component.
 */

interface CommentProps extends ComponentProps<"div"> {
    comment: HistoryResponse;
}

export function Action({ comment, className, ...props }: CommentProps) {
    const api = useApi();

    const getTopicsMetadata = (metadataJson: string): string => {
        interface TopicMetadata {
            addedTopics?: string[];
            deletedTopics?: string[];
        }

        const parseMetadata = (jsonString: string): TopicMetadata => {
            try {
                return JSON.parse(jsonString);
            } catch (error) {
                console.error("Failed to parse metadata JSON:", error);
                return {}; // Return an empty object on failure
            }
        };

        const metadata = parseMetadata(metadataJson);

        const addedTopics = Array.isArray(metadata.addedTopics)
            ? metadata.addedTopics.join(", ")
            : "";
        const deletedTopics = Array.isArray(metadata.deletedTopics)
            ? metadata.deletedTopics.join(", ")
            : "";

        let message = "";

        if (addedTopics.length > 0) {
            message += `${addedTopics}`;
        }
        if (deletedTopics.length > 0) {
            message += `${deletedTopics}`;
        }

        return message ? `: ${message}` : "";
    };

    const getAssigneeMetadata = async (
        metadataJson: string,
    ): Promise<string> => {
        interface AssigneeMetadata {
            assignedUserId?: string;
        }

        const parseMetadata = (jsonString: string): AssigneeMetadata => {
            try {
                return JSON.parse(jsonString);
            } catch (error) {
                console.error("Failed to parse metadata JSON:", error);
                return {}; // Return an empty object on failure
            }
        };

        const metadata = parseMetadata(metadataJson);

        const userId = metadata.assignedUserId;
        try {
            if (userId !== undefined && userId !== "") {
                const response = await api.post(URLS.serverUrl + API.getUser, {
                    user_id: userId,
                });
                if (
                    response.status === 200 &&
                    response.data &&
                    response.data.data
                ) {
                    const finalData = response.data.data;
                    return `${finalData.first_name} ${finalData.last_name}`;
                }
            }
            return "";
        } catch (error) {
            return "";
        }
    };

    const getInsightMetadata = (metadataJson: string): string => {
        interface InsightMetadata {
            id?: string;
            title?: string;
        }

        const parseMetadata = (jsonString: string): InsightMetadata => {
            try {
                return JSON.parse(jsonString);
            } catch (error) {
                console.error("Failed to parse metadata JSON:", error);
                return {}; // Return an empty object on failure
            }
        };

        const metadata = parseMetadata(metadataJson);
        return metadata.title ?? "";
    };

    const getIconComponent = (code: string) => {
        const parts = code.split("_");
        if (parts[0] === "TICKET") {
            if (parts[1] === "TAG") {
                return <BookmarkIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />;
            } else if (parts[1] === "STATUS") {
                return <UpdateIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />;
            } else if (parts[1] === "TOPICS") {
                return (
                    <LightningBoltIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />
                );
            } else if (parts[1] === "ASSIGNEE") {
                return (
                    <DoubleArrowRightIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />
                );
            } else if (parts[1] === "INSIGHT") {
                return <Link2Icon className="w-2.5 h-2.5 text-[#5e6ad2]" />;
            }
        } else if (
            parts[0] === "FEEDBACK" ||
            parts[1] === "REACTION" ||
            parts[1] === "COMMAND" ||
            parts[1] === "ISSUE"
        ) {
            return (
                <DoubleArrowRightIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />
            );
        } else {
        }

        // Default icon
        return <CircleIcon className="w-2.5 h-2.5 text-[#5e6ad2]" />;
    };

    const [description, setDescription] = useState<string>("");
    useEffect(() => {
        const statusMap = new Map<string, string>([
            ["NEEDSRESPONSE", "needs response"],
        ]);
        const loadDescription = async () => {
            const code = comment.content;
            const metadata = comment.metadata;

            const parts = code.split("_");
            if (parts[0] === "TICKET") {
                if (parts[1] === "TAG") {
                    setDescription(
                        ` has updated the tag to ${parts
                            .slice(2)
                            .join(" ")
                            .toLowerCase()}`,
                    );
                } else if (parts[1] === "STATUS") {
                    let statusVal = parts.slice(2).join(" ").toLowerCase();
                    statusVal = statusMap.get(parts[2]) || statusVal;
                    setDescription(` has updated the status to ${statusVal}`);
                } else if (parts[1] === "TOPICS") {
                    // example: TICKET_TOPICS_ADDED
                    const actionVal = parts[2].toLowerCase();
                    const topics = getTopicsMetadata(metadata);
                    setDescription(` has ${actionVal} the topic${topics}`);
                } else if (parts[1] === "NOTE") {
                    setDescription(" has added a note");
                } else if (parts[1] === "ASSIGNEE") {
                    const assigneeName = await getAssigneeMetadata(metadata);
                    setDescription(
                        ` has assigned the ticket to: ${assigneeName}`,
                    );
                } else if (parts[1] === "INSIGHT") {
                    // example: TICKET_INSIGHT_LINKED
                    const insightTitle = getInsightMetadata(metadata);
                    const actionVal = parts[2].toLowerCase();
                    setDescription(
                        ` has ${actionVal} the insight: ${insightTitle}`,
                    );
                }
            } else if (parts[0] === "FEEDBACK") {
                // example: FEEDBACK_REACTED_POSITIVE
                const sourceVal = parts[0].toLowerCase();
                const actionVal = parts[1].toLowerCase();
                const itemVal = parts[2].toLowerCase();
                setDescription(
                    ` has ${actionVal} with ${itemVal} ${sourceVal}`,
                );
            } else if (
                parts[1] === "REACTION" ||
                parts[1] === "COMMAND" ||
                parts[1] === "ISSUE"
            ) {
                // example: GITHUB_REACTION_ADDED
                // example: LINEAR_COMMAND_INVOKED
                // example: "JIRA_ISSUE_CREATED"
                const sourceVal = parts[0].toLowerCase();
                const sourceValCapitalized =
                    sourceVal.charAt(0).toUpperCase() + sourceVal.slice(1);
                const itemVal = parts[1].toLowerCase();
                const actionVal = parts[2].toLowerCase();
                setDescription(
                    ` has ${actionVal} a ${sourceValCapitalized} ${itemVal}`,
                );
            } else if (parts[2] === "GITHUB") {
                // example: ISSUE_CLOSED_GITHUB
                const actionVal = parts[1].toLowerCase();
                const sourceVal = parts[2].toLowerCase();
                const sourceValCapitalized =
                    sourceVal.charAt(0).toUpperCase() + sourceVal.slice(1);
                const itemVal = parts[0].toLowerCase();
                setDescription(
                    ` has ${actionVal} the ${sourceValCapitalized} ${itemVal}`,
                );
            } else if (parts[0] === "SLA") {
                // example: SLA_BREACH_START/END
                const action = parts[2].toLowerCase();
                setDescription(`SLA Breach ${action}ed`);
            } else {
                setDescription("Unknown ticket code.");
            }
        };
        loadDescription();
    }, [comment]);

    if (!comment.content) {
        return null;
    }

    function getUserName(user: UserResponse) {
        return user.name ?? user.username ?? "Anonymous";
    }

    const IconComponent = getIconComponent(comment.content);

    const commentDate = (comment: HistoryResponse): string | Date => {
        if (comment.timestamp) {
            try {
                const date = new Date(comment.timestamp);
                if (Number.isNaN(date.getTime())) {
                    throw new Error("Invalid date");
                }
                return date;
            } catch (err) {
                console.log(
                    `Could not convert comment's timestamp ${comment.timestamp} to a valid date, so using the original timestamp format. Error: ${err}`,
                );
                return new Date();
            }
        }
        return new Date();
    };
    const timestamp = commentDate(comment);

    return (
        <div
            className={clsx(
                className,
                "py-2 px-4 flex gap-0.5 flex-row align-middle items-center text-xs",
            )}
            {...props}
        >
            <span className="absolute flex items-center justify-center w-6 h-6 bg-[#eceefb] rounded-full -start-3 ring-8 ring-white ">
                {IconComponent}
            </span>
            <Text as="p" className="text-[#5c5c5e]">
                {" "}
                <Strong className="text-black">
                    {getUserName(comment.user_data)}
                </Strong>{" "}
                {description}
            </Text>
            <DotFilledIcon style={{ transform: "scale(0.8)" }} />
            <Timestamp date={timestamp} className="truncate font-semibold" />
        </div>
    );
}
