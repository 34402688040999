import {
    BoldIcon,
    CodeIcon,
    ImageIcon,
    ItalicIcon,
    ListIcon,
    ListOrderedIcon,
    SmileIcon,
    StrikethroughIcon,
} from "lucide-react";

import { $createTextNode, $insertNodes, type NodeKey } from "lexical";

import { useSyncExternalStore } from "react";

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "../shadcn/ui/dropdown-menu";

import "@liveblocks/react-ui/styles.css";
import "@liveblocks/react-lexical/styles.css";
import "../../pages/Announcements/globals.css";
import { $isLinkNode } from "@lexical/link";
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    type ListType,
    REMOVE_LIST_COMMAND,
} from "@lexical/list";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode } from "@lexical/rich-text";
import { $isTableNode } from "@lexical/table";
import {
    $findMatchingParent,
    $getNearestNodeOfType,
    $isEditorIsNestedEditor,
    mergeRegister,
} from "@lexical/utils";
import EmojiPicker, { type EmojiClickData } from "emoji-picker-react";
import {
    $getSelection,
    $isRangeSelection,
    $isRootOrShadowRoot,
    COMMAND_PRIORITY_CRITICAL,
    FORMAT_TEXT_COMMAND,
    SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useState } from "react";

import { $isAtNodeEnd } from "@lexical/selection";

import type { ElementNode, RangeSelection, TextNode } from "lexical";
import { InsertImageDialog } from "./ImagesPlugin";
// REFERENCE:
// https://github.com/facebook/lexical/blob/main/packages/lexical-playground/src/plugins/ToolbarPlugin/index.tsx#L101

export function getSelectedNode(
    selection: RangeSelection,
): TextNode | ElementNode {
    const anchor = selection.anchor;
    const focus = selection.focus;
    const anchorNode = selection.anchor.getNode();
    const focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    const isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
    }
}

export function FillURL(): string {
    const srcfile = prompt("Enter the URL of the image:", "");

    return srcfile ?? "";
}

const blockTypeToBlockName = {
    bullet: "Bulleted List",
    code: "Code Block",
    number: "Numbered List",
    paragraph: "Normal",
    quote: "Quote",
};

const rootTypeToRootName = {
    root: "Root",
    table: "Table",
};

interface ToolbarProps {
    handleFileUpload: (src: string, altText: string) => void
    source: string
}

export default function Toolbar({ handleFileUpload, source }: ToolbarProps) {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const activeBlock = useActiveBlock();

    const [isOpen, setIsOpen] = useState(false);
    const [blockType, setBlockType] =
        useState<keyof typeof blockTypeToBlockName>("paragraph");
    const [rootType, setRootType] =
        useState<keyof typeof rootTypeToRootName>("root");
    const [selectedElementKey, setSelectedElementKey] =
        useState<NodeKey | null>(null);
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isCode, setIsCode] = useState(false);
    const [isImageCaption, setIsImageCaption] = useState(false);

    const [isEditable, setIsEditable] = useState(() => editor.isEditable());

    const [imageOpen, setImageOpen] = useState<boolean>(false);
    const [imageMode, setImageMode] = useState<string>("file");

    const mapListTypeToBlockType = (
        listType: ListType,
    ): "number" | "bullet" | "code" | "paragraph" | "quote" => {
        switch (listType) {
            case "number":
            case "bullet":
                return listType; // if it's "number" or "bullet", return as-is
            default:
                return "paragraph"; // for unsupported types like "check", return a fallback
        }
    };

    const $updateToolbar = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            if (
                activeEditor !== editor &&
                $isEditorIsNestedEditor(activeEditor)
            ) {
                const rootElement = activeEditor.getRootElement();
                setIsImageCaption(
                    !!rootElement?.parentElement?.classList.contains(
                        "image-caption-container",
                    ),
                );
            } else {
                setIsImageCaption(false);
            }

            const anchorNode = selection.anchor.getNode();
            let element =
                anchorNode.getKey() === "root"
                    ? anchorNode
                    : $findMatchingParent(anchorNode, (e) => {
                        const parent = e.getParent();
                        return parent !== null && $isRootOrShadowRoot(parent);
                    });

            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }

            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);

            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsCode(selection.hasFormat("code"));

            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            const tableNode = $findMatchingParent(node, $isTableNode);
            if ($isTableNode(tableNode)) {
                setRootType("table");
            } else {
                setRootType("root");
            }

            if (elementDOM !== null) {
                setSelectedElementKey(elementKey);
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType<ListNode>(
                        anchorNode,
                        ListNode,
                    );
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(mapListTypeToBlockType(type));
                } else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type as keyof typeof blockTypeToBlockName);
                    }
                }
            }
        }
    }, [activeEditor, editor]);

    useEffect(() => {
        return editor.registerCommand(
            SELECTION_CHANGE_COMMAND,
            (_payload, newEditor) => {
                setActiveEditor(newEditor);
                $updateToolbar();
                return false;
            },
            COMMAND_PRIORITY_CRITICAL,
        );
    }, [editor, $updateToolbar]);

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener((editable) => {
                setIsEditable(editable);
            }),
            activeEditor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    $updateToolbar();
                });
            }),
        );
    }, [$updateToolbar, activeEditor, editor]);

    useEffect(() => {
        activeEditor.getEditorState().read(() => {
            $updateToolbar();
        });
    }, [activeEditor, $updateToolbar]);
    return (
        <div>
            <div className="flex items-center gap-2 px-3 py-2 border-b">
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
        ${isBold ? "bg-gray-100" : ""}`} // Fixed this line
                    type="button"
                >
                    <BoldIcon strokeWidth={1.5} size={16} />
                </button>
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${isItalic ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <ItalicIcon strokeWidth={1.5} size={16} />
                </button>
                {/* underline seems to not exist on slack?? */}
                <button
                    onClick={() => {
                        editor.dispatchCommand(
                            FORMAT_TEXT_COMMAND,
                            "strikethrough",
                        );
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${isStrikethrough ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <StrikethroughIcon strokeWidth={1.5} size={16} />
                </button>
                {/* <button
                    onClick={() => editor.update(() => toggleBlock("h3"))}
                    data-active={activeBlock === "h3" ? "" : undefined}
                    className="w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 data-[active]:bg-gray-200"
                    type="button"
                >
                    <LinkIcon strokeWidth={1.5} size={16} />
                </button> */}
                <button
                    onClick={() => {
                        editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code");
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${isCode ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <CodeIcon strokeWidth={1.5} size={16} />
                </button>
                <button
                    onClick={() => {
                        if (blockType === "bullet") {
                            editor.dispatchCommand(
                                REMOVE_LIST_COMMAND,
                                undefined,
                            );
                        } else {
                            editor.dispatchCommand(
                                INSERT_UNORDERED_LIST_COMMAND,
                                undefined,
                            );
                        }
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${blockType === "bullet" ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <ListIcon strokeWidth={1.5} size={16} />
                </button>
                <button
                    onClick={() => {
                        if (blockType === "number") {
                            editor.dispatchCommand(
                                REMOVE_LIST_COMMAND,
                                undefined,
                            );
                        } else {
                            editor.dispatchCommand(
                                INSERT_ORDERED_LIST_COMMAND,
                                undefined,
                            );
                        }
                    }}
                    className={`w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100 popup-item spaced 
                        ${blockType === "number" ? "bg-gray-100" : ""}`}
                    type="button"
                >
                    <ListOrderedIcon strokeWidth={1.5} size={16} />
                </button>
                {(source === "Slack" || source === "CommunitySlack" || source === "Discord") && <button
                    className="w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100"
                    type="button"
                >
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <ImageIcon strokeWidth={1.5} size={16} />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                            {/* Only support uploading file currently, just add link otherwise
                            <DropdownMenuItem
                                onClick={() => {
                                    setImageMode("url");
                                    setImageOpen(true);
                                }}
                                className="py-1 hover:bg-muted cursor-pointer px-3 text-xs"
                            >
                                URL
                            </DropdownMenuItem> */}
                            <DropdownMenuItem
                                onClick={() => {
                                    setImageMode("file");
                                    setImageOpen(true);
                                }}
                                className="py-1 hover:bg-muted cursor-pointer px-3 text-xs"
                            >
                                File
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                    <InsertImageDialog
                        open={imageOpen}
                        setOpen={setImageOpen}
                        activeEditor={activeEditor}
                        mode={imageMode}
                        handleFileUpload={handleFileUpload}
                    />
                </button>}
                <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
                    <DropdownMenuTrigger asChild>
                        <button
                            onClick={() => { }}
                            className="w-8 h-8 flex items-center justify-center border rounded hover:bg-gray-100"
                            type="button"
                        >
                            <SmileIcon strokeWidth={1.5} size={16} />
                        </button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="w-64">
                        <EmojiPicker
                            onEmojiClick={(
                                emojiData: EmojiClickData,
                                event: MouseEvent,
                            ) => {
                                editor.update(() => {
                                    $insertNodes([
                                        $createTextNode(emojiData.emoji),
                                    ]);
                                });
                                setIsOpen(false);
                            }}
                        />
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </div>
    );
}

function useActiveBlock() {
    const [editor] = useLexicalComposerContext();

    const subscribe = useCallback((onStoreChange: () => void) => { }, [editor]);

    const getSnapshot = useCallback(() => {
        return editor.getEditorState().read(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection)) return null;

            const anchor = selection.anchor.getNode();
            let element =
                anchor.getKey() === "root"
                    ? anchor
                    : $findMatchingParent(anchor, (e) => {
                        const parent = e.getParent();
                        return parent !== null && $isRootOrShadowRoot(parent);
                    });

            if (element === null) {
                element = anchor.getTopLevelElementOrThrow();
            }

            if ($isHeadingNode(element)) {
                return element.getTag();
            }

            return element.getType();
        });
    }, [editor]);

    return useSyncExternalStore(subscribe, getSnapshot, getSnapshot);
}
