import { TeamsAPI, URLS, pages } from "@/constant";
import {
    DrawingPinIcon,
    FrameIcon,
    MagicWandIcon,
    MagnifyingGlassIcon,
    RocketIcon,
    SpeakerLoudIcon,
    TriangleDownIcon,
    TriangleRightIcon,
} from "@radix-ui/react-icons";
import { NavigationMenu } from "@radix-ui/react-navigation-menu";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../shadcn/ui/button";
import {
    NavigationMenuItem,
    NavigationMenuList,
} from "../shadcn/ui/navigation-menu";
import BallPopover from "./BallPopover";
import MenuDropdownComponent from "./MenuDropdownComponent";

import type { Teams } from "@/interfaces/serverData";
import { integrationMappingToSvg } from "@/pages/Admin/Integrations/constant";
import { SvgIcon } from "@mui/material";
import { useAuthInfo } from "@propelauth/react";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { BuildingIcon, HouseIcon, MailboxIcon, UsersIcon } from "lucide-react";
import FilterComponent from "./FilterComponent";
import { DropdownSection, DropdownSectionButton } from "./utils";

export type FilterMetadata = {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    granularFilters: Map<any, boolean>;
    enabled: boolean;
};

export const NAVBAR_ITEMS = {
    integrations: "integrations",
    search: "search",
    queries: "issues",
    inbox: "inbox",
    views: "views",
    analytics: "analytics",
    insights: "insights",
    announcements: "announcements",
    savedView: "savedView",
    crm: "companies",
};

interface SideBarProps {
    organizationName: string;
    filters?: Map<string, FilterMetadata>;
    activeButton: string;
    setActiveButton: (newTerm: string) => void;
    setActiveTeam?: (newTerm: string) => void;
    activeTeam?: string;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    handleToggle?: (type: string, value: any) => () => void;
    isAdmin: boolean;
    width: number;
}

export const drawerWidth = "250px";

export default function SideBarComponent(props: SideBarProps) {
    const sidebarRef = useRef<HTMLDivElement | null>(null);
    const [selectedItem, setSelectedItem] = useState<string>(
        props.activeButton,
    );
    const [searchDropdownOpen, setSearchDropdownOpen] = useState(
        props.activeButton === NAVBAR_ITEMS.search,
    );

    const [collapsed, setCollapsed] = useState(props.width < 11);

    function setDropdowns(buttonName: string) {
        if (buttonName === NAVBAR_ITEMS.queries) {
            setSearchDropdownOpen(false);
        } else if (buttonName === NAVBAR_ITEMS.search) {
            setSearchDropdownOpen(!searchDropdownOpen);
        } else {
            setSearchDropdownOpen(false);
        }
    }

    const handleMenuClick = (
        buttonName: string,
        viewID?: string,
        teamID?: string,
    ) => {
        // You can call the respective function here
        console.log("buttonName", buttonName);
        console.log("viewID", viewID);
        console.log("teamID", teamID);
        if (buttonName === NAVBAR_ITEMS.integrations) {
            adminIntegrationsPage();
        } else if (buttonName === NAVBAR_ITEMS.queries) {
            if (teamID) {
                navigate(`/teams/${teamID}/issues`);
            } else {
                navigate("/issues");
            }
        } else if (buttonName === NAVBAR_ITEMS.inbox) {
            adminInboxPage();
        } else if (buttonName === NAVBAR_ITEMS.analytics) {
            adminAnalyticsPage();
        } else if (buttonName === NAVBAR_ITEMS.search) {
            searchPage();
        } else if (buttonName === NAVBAR_ITEMS.insights) {
            AdminInsightsPage();
        } else if (buttonName === NAVBAR_ITEMS.views) {
            navigate("/views");
        } else if (buttonName === NAVBAR_ITEMS.announcements) {
            navigate("/announcements");
        } else if (buttonName === NAVBAR_ITEMS.savedView) {
            if (viewID) {
                navigate(`/views/${viewID}`);
            } else {
                navigate("/views");
            }
        } else if (buttonName === NAVBAR_ITEMS.crm) {
            navigate("/companies");
        }
        props.setActiveButton(buttonName);
        if (viewID && viewID !== "") {
            setSelectedItem(`savedView_${viewID}`);
        } else if (teamID && teamID !== "") {
            console.log("teamID", teamID);
            setSelectedItem(`${buttonName}_${teamID}`);
        } else {
            console.log("setting buttonName", buttonName);
            setSelectedItem(buttonName);
        }
        setDropdowns(buttonName);
    };

    const authInfo = useAuthInfo();

    const [orgId, setOrgId] = useState<string>("");

    const [expandedTeamId, setExpandedTeamId] = useState<string>(
        props.activeTeam ?? "",
    );

    useEffect(() => {
        console.log(props.activeTeam);
        if (props.activeTeam) {
            setExpandedTeamId(props.activeTeam);
        } else {
            setExpandedTeamId(orgId);
        }
    }, [orgId, props.activeTeam]);

    useEffect(() => {
        console.log("reached ");
        console.log(expandedTeamId);
    }, [expandedTeamId]);

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listTeams;
            const response = await fetch(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            const d = await response.json();
            return d.data;
        },
    });

    useEffect(() => {
        const orgs = authInfo.orgHelper?.getOrgs();
        if (orgs === undefined || orgs.length !== 1) {
            return;
        }

        console.log(orgs[0]);

        if (orgs[0].orgMetadata?.orgID !== undefined) {
            setOrgId(orgs[0].orgMetadata?.orgID);
        } else {
            setOrgId(orgs[0].orgId);
        }
    }, [authInfo.orgHelper]);

    useEffect(() => {
        setCollapsed(props.width < 190);
    }, [props.width]);

    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes("/inbox")) {
            setSelectedItem(NAVBAR_ITEMS.inbox);
        }
    }, [window.location.pathname]);

    const authInfoRef = useRef(authInfo);
    // const viewsQuery = useQuery<View[]>({
    //     queryKey: ["views"],
    //     queryFn: async () => {
    //         const [url, method] = APIWithMethod.listViews;
    //         const response = await fetch(`${URLS.serverUrl}${url}`, {
    //             method: method,
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
    //             },
    //         });
    //         const d = await response.json();
    //         return d.data;
    //     },
    // });

    const navigate = useNavigate();

    const adminIntegrationsPage = () => {
        navigate("/admin/manage_integrations");
    };

    const adminInboxPage = () => {
        navigate("/inbox");
    };

    const adminAnalyticsPage = () => {
        navigate("/analytics");
    };

    const AdminInsightsPage = () => {
        navigate("/insights");
    };

    const searchPage = () => {
        navigate("/search");
    };

    const toggleTeamExpanded = (teamId: string) => {
        setExpandedTeamId((prevId) => (prevId === teamId ? "" : teamId));
    };

    useEffect(() => {
        toggleTeamExpanded(props.activeTeam ?? "");
    }, [props.activeTeam]);

    // const deleteViewsMutation = useMutation({
    //     mutationFn: async (id: string) => {
    //         const [url, method] = APIWithMethod.deleteView;
    //         const response = await fetch(`${URLS.serverUrl}${url}/${id}`, {
    //             method: method,
    //             headers: {
    //                 Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
    //             },
    //         });
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    //         const result = await response.json();
    //         return result.data;
    //     },
    //     onSuccess: (data) => {
    //         viewsQuery.refetch();
    //         const currentViewId = selectedItem.split("_")[1];
    //         if (currentViewId === data) {
    //             setSelectedItem(NAVBAR_ITEMS.queries);
    //             navigate("/issues");
    //         }
    //     },
    // });

    const [personalExpanded, setPersonalExpanded] = useState(true);
    const [knowledgeSearchExpanded, setKnowledgeSearchExpanded] = useState(
        props.activeButton === NAVBAR_ITEMS.search,
    );
    const [teamspacesExpanded, setTeamspacesExpanded] = useState(
        props.activeButton !== NAVBAR_ITEMS.search,
    );

    useEffect(() => {
        if (props.activeButton === NAVBAR_ITEMS.search) {
            setKnowledgeSearchExpanded(true);
            setTeamspacesExpanded(false);
        } else {
            setKnowledgeSearchExpanded(false);
            setTeamspacesExpanded(true);
        }
    }, [props.activeButton]);

    // Helper function for dropdown sections

    return (
        <div
            className="relative transition-all duration-300 overflow-y-auto"
            style={{
                minWidth: "40px",
                width: `${props.width}`,
                height: "100vh",
            }}
            ref={sidebarRef}
        >
            <div className="flex flex-col gap-2 pt-3 pb-6 px-3 w-full">
                <MenuDropdownComponent
                    organizationName={props.organizationName}
                    state={pages.search}
                    collapsed={collapsed}
                />
                {/* <DropdownMenu> // add back when ready
                    <DropdownMenuTrigger>
                        <Button
                            className="ml-2 shadow-sm outline outline-1 px-2 py-1 text-xs outline-slate-300 flex flex-row gap-2 justify-start data-[state=open]:bg-muted"
                            size="icon"
                            variant="outline"
                        >
                            <SquarePenIcon className="w-3 h-3" />
                            <div
                                className={`${collapsed ? "hidden" : "block"}`}
                            >
                                Create
                            </div>
                        </Button>
                    </DropdownMenuTrigger>

                    <DropdownMenuContent side="bottom" className="w-40 ml-4 ">
                        <DropdownMenuItem
                            onClick={() => {
                                navigate("/views/create");
                                setSelectedItem("");
                            }}
                            className="py-1 hover:bg-muted cursor-pointer px-3 text-xs"
                        >
                            <LayersIcon className="w-4 h-4 flex-shrink-0 align-center" />
                            Create View
                        </DropdownMenuItem>
                    </DropdownMenuContent>
                </DropdownMenu> */}
            </div>
            <NavigationMenu className="flex flex-col space-y-2">
                <DropdownSection
                    title="Personal"
                    expanded={personalExpanded}
                    setExpanded={setPersonalExpanded}
                >
                    <NavigationMenuList className="flex flex-col items-center w-full">
                        <NavigationMenuItem className="w-full">
                            <div className="w-full px-3">
                                <Button
                                    variant="ghost"
                                    size="sm"
                                    onClick={() =>
                                        handleMenuClick(NAVBAR_ITEMS.inbox)
                                    }
                                    className={`flex justify-center items-center text-left w-full box-border ${
                                        selectedItem === NAVBAR_ITEMS.inbox
                                            ? "bg-secondary text-gray-950"
                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                    }`}
                                >
                                    <DrawingPinIcon className="w-4 h-4" />
                                    <div
                                        className={`flex-1 ml-2 ${
                                            collapsed ? "hidden" : "block"
                                        }`}
                                    >
                                        <div className="flex items-center">
                                            My Inbox
                                        </div>
                                    </div>
                                </Button>
                            </div>
                        </NavigationMenuItem>
                        {/* {viewsQuery.data
                            ?.filter(
                                (view) =>
                                    view.user_id !==
                                        "00000000-0000-0000-0000-000000000000" &&
                                    !view.is_internal_team,
                            )
                            .map((view) => {
                                return (
                                    <NavigationMenuItem
                                        className="w-full px-3"
                                        key={view.id}
                                    >
                                        <ContextMenu>
                                            <ContextMenuTrigger className="w-full">
                                                <Button
                                                    variant="ghost"
                                                    size="sm"
                                                    onClick={() =>
                                                        handleMenuClick(
                                                            NAVBAR_ITEMS.savedView,
                                                            view.id,
                                                        )
                                                    }
                                                    className={`whitespace-nowrap flex justify-center items-center text-left w-full ${
                                                        selectedItem ===
                                                        `savedView_${view.id}`
                                                            ? "bg-secondary text-gray-950"
                                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                    }`}
                                                >
                                                    <LayersIcon
                                                        className={`w-4 h-4 flex-shrink-0 align-center ${collapsed && "-ml-6"}`}
                                                    />
                                                    <div
                                                        className={`flex-1 ml-2 ${collapsed ? "hidden" : "block"}`}
                                                    >
                                                        <div className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                            {view.name}
                                                        </div>
                                                    </div>
                                                </Button>
                                            </ContextMenuTrigger>
                                            <ContextMenuContent className="w-60">
                                                <ContextMenuItem
                                                    inset
                                                    className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                    onClick={() => {
                                                        deleteViewsMutation.mutate(
                                                            view.id,
                                                        );
                                                    }}
                                                >
                                                    <TrashIcon className="w-3.5 h-3.5" />
                                                    Delete View
                                                </ContextMenuItem>
                                            </ContextMenuContent>
                                        </ContextMenu>
                                    </NavigationMenuItem>
                                );
                            })} */}
                    </NavigationMenuList>
                </DropdownSection>

                <DropdownSection
                    title="Teamspaces"
                    expanded={teamspacesExpanded}
                    setExpanded={setTeamspacesExpanded}
                >
                    <NavigationMenuList className="flex flex-col items-center w-full">
                        {teamsQuery.data?.map((team) => {
                            return (
                                <NavigationMenuItem
                                    className="w-full px-3"
                                    style={{ margin: "0px" }} // don't know why mx didn't work, but temporary solution to keep items aligned
                                    key={team.id}
                                >
                                    <DropdownSectionButton
                                        parentButton={
                                            <Button
                                                variant="ghost"
                                                size="sm"
                                                className={
                                                    "flex justify-center items-center text-left w-full box-border text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                }
                                            >
                                                {orgId === team.id ? (
                                                    <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                                        <HouseIcon
                                                            className="text-iris9"
                                                            strokeWidth={1.5}
                                                            size={16}
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                                        <UsersIcon
                                                            className="text-red9"
                                                            strokeWidth={1.5}
                                                            size={16}
                                                        />
                                                    </div>
                                                )}

                                                <div
                                                    className={`flex items-center flex-1 ml-2 ${collapsed ? "hidden" : "flex"}`}
                                                >
                                                    <div className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap mr-1">
                                                        {team.team_name}
                                                    </div>
                                                    <motion.div
                                                        initial="collapsed"
                                                        animate={
                                                            expandedTeamId ===
                                                            team.id
                                                                ? "expanded"
                                                                : "collapsed"
                                                        }
                                                    >
                                                        {expandedTeamId ===
                                                        team.id ? (
                                                            <TriangleDownIcon className="h-4 w-4" />
                                                        ) : (
                                                            <TriangleRightIcon className="h-4 w-4" />
                                                        )}
                                                    </motion.div>
                                                </div>
                                            </Button>
                                        }
                                        expanded={expandedTeamId === team.id}
                                        setExpanded={() =>
                                            toggleTeamExpanded(team.id)
                                        }
                                    >
                                        <div className="w-full">
                                            {orgId === team.id ? (
                                                <>
                                                    {props.isAdmin && (
                                                        <NavigationMenuItem className="w-full">
                                                            <div className="w-full px-3">
                                                                <Button
                                                                    variant="ghost"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        handleMenuClick(
                                                                            NAVBAR_ITEMS.integrations,
                                                                        )
                                                                    }
                                                                    className={`flex justify-center items-center text-left w-full box-border ${
                                                                        selectedItem ===
                                                                        NAVBAR_ITEMS.integrations
                                                                            ? "bg-secondary text-gray-950"
                                                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                                    }`}
                                                                >
                                                                    <FrameIcon className="w-4 h-4" />
                                                                    <div
                                                                        className={`flex-1 ml-2 ${
                                                                            collapsed
                                                                                ? "hidden"
                                                                                : "block"
                                                                        }`}
                                                                    >
                                                                        Manage
                                                                        Integrations
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        </NavigationMenuItem>
                                                    )}

                                                    {/* Issues */}
                                                    <NavigationMenuItem className="w-full">
                                                        <div className="w-full px-3">
                                                            <Button
                                                                variant="ghost"
                                                                size="sm"
                                                                onClick={() =>
                                                                    handleMenuClick(
                                                                        NAVBAR_ITEMS.queries,
                                                                    )
                                                                }
                                                                className={`flex justify-center items-center text-left w-full box-border ${
                                                                    selectedItem ===
                                                                    NAVBAR_ITEMS.queries
                                                                        ? "bg-secondary text-gray-950"
                                                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                                }`}
                                                            >
                                                                <MailboxIcon
                                                                    strokeWidth={
                                                                        1.5
                                                                    }
                                                                    size={16}
                                                                    className="w-4 h-4"
                                                                />
                                                                <div
                                                                    className={`flex-1 ml-2 ${
                                                                        collapsed
                                                                            ? "hidden"
                                                                            : "block"
                                                                    }`}
                                                                >
                                                                    <div className="flex items-center">
                                                                        Interactions
                                                                    </div>
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    </NavigationMenuItem>

                                                    {/* Views */}
                                                    {/* {viewsQuery.data
                                                        ?.filter(
                                                            (view) =>
                                                                view.user_id ===
                                                                "00000000-0000-0000-0000-000000000000",
                                                        )
                                                        .map((view) => (
                                                            <NavigationMenuItem
                                                                className="w-full px-3"
                                                                key={view.id}
                                                            >
                                                                <ContextMenu>
                                                                    <ContextMenuTrigger className="w-full">
                                                                        <Button
                                                                            variant="ghost"
                                                                            size="sm"
                                                                            onClick={() =>
                                                                                handleMenuClick(
                                                                                    NAVBAR_ITEMS.savedView,
                                                                                    view.id,
                                                                                )
                                                                            }
                                                                            className={`whitespace-nowrap flex justify-center items-center text-left w-full ${
                                                                                selectedItem ===
                                                                                `savedView_${view.id}`
                                                                                    ? "bg-secondary text-gray-950"
                                                                                    : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                                            }`}
                                                                        >
                                                                            <LayersIcon
                                                                                className={`w-4 h-4 flex-shrink-0 align-center ${collapsed && "-ml-6"}`}
                                                                            />
                                                                            <div
                                                                                className={`flex-1 ml-2 ${collapsed ? "hidden" : "block"}`}
                                                                            >
                                                                                <div className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
                                                                                    {
                                                                                        view.name
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </Button>
                                                                    </ContextMenuTrigger>
                                                                    <ContextMenuContent className="w-60">
                                                                        <ContextMenuItem
                                                                            inset
                                                                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                                            onClick={() => {
                                                                                deleteViewsMutation.mutate(
                                                                                    view.id,
                                                                                );
                                                                            }}
                                                                        >
                                                                            <TrashIcon className="w-3.5 h-3.5" />
                                                                            Delete
                                                                            View
                                                                        </ContextMenuItem>
                                                                    </ContextMenuContent>
                                                                </ContextMenu>
                                                            </NavigationMenuItem>
                                                        ))} */}

                                                    {/* Analytics */}
                                                    <NavigationMenuItem className="w-full">
                                                        <div className="w-full px-3">
                                                            <Button
                                                                variant="ghost"
                                                                size="sm"
                                                                onClick={() =>
                                                                    handleMenuClick(
                                                                        NAVBAR_ITEMS.analytics,
                                                                    )
                                                                }
                                                                className={`flex justify-center items-center text-left w-full box-border ${
                                                                    selectedItem ===
                                                                    NAVBAR_ITEMS.analytics
                                                                        ? "bg-secondary text-gray-950"
                                                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                                }`}
                                                            >
                                                                <MagicWandIcon className="w-4 h-4" />
                                                                <div
                                                                    className={`flex-1 ml-2 ${
                                                                        collapsed
                                                                            ? "hidden"
                                                                            : "block"
                                                                    }`}
                                                                >
                                                                    Analytics
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    </NavigationMenuItem>

                                                    {/* Product Insights */}
                                                    <NavigationMenuItem className="w-full">
                                                        <div className="w-full px-3">
                                                            <Button
                                                                variant="ghost"
                                                                size="sm"
                                                                onClick={() =>
                                                                    handleMenuClick(
                                                                        NAVBAR_ITEMS.insights,
                                                                    )
                                                                }
                                                                className={`flex justify-center items-center text-left w-full box-border ${
                                                                    selectedItem ===
                                                                    NAVBAR_ITEMS.insights
                                                                        ? "bg-secondary text-gray-950"
                                                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                                }`}
                                                            >
                                                                <RocketIcon className="w-4 h-4" />
                                                                <div
                                                                    className={`flex-1 ml-2 ${
                                                                        collapsed
                                                                            ? "hidden"
                                                                            : "block"
                                                                    }`}
                                                                >
                                                                    Product
                                                                    Insights
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    </NavigationMenuItem>

                                                    {/* Announcements */}
                                                    <NavigationMenuItem className="w-full">
                                                        <div className="w-full px-3">
                                                            <Button
                                                                variant="ghost"
                                                                size="sm"
                                                                onClick={() =>
                                                                    handleMenuClick(
                                                                        NAVBAR_ITEMS.announcements,
                                                                    )
                                                                }
                                                                className={`flex justify-center items-center text-left w-full box-border ${
                                                                    selectedItem ===
                                                                    NAVBAR_ITEMS.announcements
                                                                        ? "bg-secondary text-gray-950"
                                                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                                }`}
                                                            >
                                                                <SpeakerLoudIcon className="w-4 h-4" />
                                                                <div
                                                                    className={`flex-1 ml-2 ${
                                                                        collapsed
                                                                            ? "hidden"
                                                                            : "block"
                                                                    }`}
                                                                >
                                                                    Announcements
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    </NavigationMenuItem>

                                                    {/* Companies */}
                                                    <NavigationMenuItem className="w-full">
                                                        <div className="w-full px-3">
                                                            <Button
                                                                variant="ghost"
                                                                size="sm"
                                                                onClick={() =>
                                                                    handleMenuClick(
                                                                        NAVBAR_ITEMS.crm,
                                                                    )
                                                                }
                                                                className={`flex justify-center items-center text-left w-full box-border ${
                                                                    selectedItem ===
                                                                    NAVBAR_ITEMS.crm
                                                                        ? "bg-secondary text-gray-950"
                                                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                                }`}
                                                            >
                                                                <BuildingIcon
                                                                    strokeWidth={
                                                                        1.5
                                                                    }
                                                                    className="w-4 h-4"
                                                                />
                                                                <div
                                                                    className={`flex-1 ml-2 ${
                                                                        collapsed
                                                                            ? "hidden"
                                                                            : "block"
                                                                    }`}
                                                                >
                                                                    Companies
                                                                </div>
                                                            </Button>
                                                        </div>
                                                    </NavigationMenuItem>
                                                </>
                                            ) : (
                                                <NavigationMenuItem className="w-full">
                                                    <div className="w-full px-3">
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            onClick={() =>
                                                                handleMenuClick(
                                                                    NAVBAR_ITEMS.queries,
                                                                    "",
                                                                    team.id,
                                                                )
                                                            }
                                                            className={`flex justify-center items-center text-left w-full box-border ${
                                                                selectedItem ===
                                                                `${NAVBAR_ITEMS.queries}_${team.id}`
                                                                    ? "bg-secondary text-gray-950"
                                                                    : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                            }`}
                                                        >
                                                            <MailboxIcon
                                                                strokeWidth={
                                                                    1.5
                                                                }
                                                                size={16}
                                                                className="w-4 h-4"
                                                            />
                                                            <div
                                                                className={`flex-1 ml-2 ${
                                                                    collapsed
                                                                        ? "hidden"
                                                                        : "block"
                                                                }`}
                                                            >
                                                                <div className="flex items-center">
                                                                    Interactions
                                                                </div>
                                                            </div>
                                                        </Button>
                                                    </div>
                                                </NavigationMenuItem>
                                            )}
                                        </div>
                                    </DropdownSectionButton>
                                </NavigationMenuItem>
                            );
                        })}
                    </NavigationMenuList>
                </DropdownSection>
                <DropdownSectionButton
                    parentButton={
                        <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => {
                                handleMenuClick(NAVBAR_ITEMS.search);
                            }}
                            className={`flex justify-center items-center text-left w-full box-border ${
                                selectedItem === NAVBAR_ITEMS.search
                                    ? "bg-secondary text-gray-950"
                                    : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                            }`}
                        >
                            <MagnifyingGlassIcon className="w-4 h-4" />
                            <div
                                className={`flex-1 ml-2 ${
                                    collapsed ? "hidden" : "block"
                                }`}
                            >
                                <div className="flex items-center">
                                    Knowledge Search
                                    {searchDropdownOpen ? (
                                        <TriangleDownIcon className="h-4 w-4" />
                                    ) : (
                                        <TriangleRightIcon className="h-4 w-4" />
                                    )}
                                </div>
                            </div>
                        </Button>
                    }
                    expanded={knowledgeSearchExpanded}
                    setExpanded={setKnowledgeSearchExpanded}
                >
                    <NavigationMenuList className="flex flex-col items-center w-full">
                        {props.filters &&
                            Array.from(props.filters.entries()).map(
                                ([key, value]: [string, FilterMetadata]) => {
                                    const svgIcon =
                                        integrationMappingToSvg.get(key); // item type

                                    if (!svgIcon) {
                                        return null;
                                    }
                                    return (
                                        <FilterComponent
                                            items={value.granularFilters}
                                            name={key}
                                            key={key}
                                            // biome-ignore lint/style/noNonNullAssertion: <explanation>
                                            handleToggle={props.handleToggle!} // MUST pass handle toggle if we pass in something to filters.
                                            selected={value.enabled}
                                            svg={
                                                <SvgIcon
                                                    component={svgIcon}
                                                    inheritViewBox
                                                    style={{
                                                        transform: "scale(0.8)",
                                                        width: "20px",
                                                        height: "20px",
                                                    }}
                                                />
                                            }
                                        />
                                    );
                                },
                            )}
                    </NavigationMenuList>
                </DropdownSectionButton>
            </NavigationMenu>

            <div className="fixed bottom-0 left-0 p-4">
                <BallPopover />
            </div>
        </div>
    );
}
