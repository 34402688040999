import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getRoot, $isParagraphNode } from "lexical";
import { useEffect } from "react";

import { LoaderCircleIcon, SendHorizonalIcon } from "lucide-react";
import { Button } from "../shadcn/ui/button";

import { CLEAR_EDITOR_COMMAND } from "lexical";
import type { UploadedFile } from "@/interfaces/serverData";

export default function ActionsPlugin({
    setIsSendDisabled,
    isSendDisabled,
    loading,
    handleSubmit,
    uploadedFiles
}: {
    setIsSendDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    isSendDisabled: boolean;
    loading: boolean;
    handleSubmit: () => void;
    uploadedFiles: UploadedFile[];
}): JSX.Element {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (uploadedFiles?.length > 0) {
            setIsSendDisabled(true)
        } else {
            return editor.registerUpdateListener(
                ({ dirtyElements, prevEditorState, tags }) => {
                    editor.getEditorState().read(() => {
                        const root = $getRoot();
                        const children = root.getChildren();

                        if (children.length > 1) {
                            setIsSendDisabled(true);
                        } else {
                            if ($isParagraphNode(children[0])) {
                                const paragraphChildren = children[0].getChildren();
                                const isParagraphEmpty = paragraphChildren.every(
                                    (child) => {
                                        return !child.getTextContent().trim();
                                    },
                                );
                                setIsSendDisabled(!isParagraphEmpty);
                            } else {
                                setIsSendDisabled(false);
                            }
                        }
                    });
                },
            );
        }
    }, [editor, setIsSendDisabled, uploadedFiles]);

    return (
        <div className="overflow-hidden flex justify-end pr-1">
            <Button
                disabled={!isSendDisabled}
                className="px-2 py-2 disabled:opacity-50 bg-[#5B5BD6]"
                onClick={() => {
                    editor.getEditorState().read(() => {
                        handleSubmit();
                        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
                    });
                }}
                type="submit"
            >
                {loading ? (
                    <LoaderCircleIcon className="h-4 w-4 animate-spin" />
                ) : (
                    <SendHorizonalIcon className="h-4 w-4" />
                )}
            </Button>
        </div>
    );
}
