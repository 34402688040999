import { Card, CardContent } from "@/component/shadcn/ui/card";
import type {
    GetTopicsResponse,
    IconEntry,
    Insight,
} from "@/interfaces/serverData";
import {
    getColorLight,
    getExternalIssueIcon,
    processInsightMetadata,
} from "@/utilities/methods";
import {
    BookmarkFilledIcon,
    BookmarkIcon,
    CaretUpIcon,
    ComponentBooleanIcon,
    DotFilledIcon,
    DoubleArrowLeftIcon,
    OpenInNewWindowIcon,
    ReloadIcon,
} from "@radix-ui/react-icons";
import { Badge } from "@radix-ui/themes";
import { memo, useMemo, useState } from "react";
import React from "react";
import { useNavigate } from "react-router-dom";
import SparklesIcon from "../images/icons8-sparkles-48.png";
import { ContextMenu, ContextMenuContent, ContextMenuItem, ContextMenuTrigger } from "@/component/shadcn/ui/context-menu";

interface InsightsListCardProps {
    insight: Insight;
    userID: string;
    handleSaveAIGeneratedInsight: (insight: Insight) => Promise<void>;
    saved: boolean;
    topicsMap: Map<string, GetTopicsResponse>;
}

function InsightsListCard({
    insight,
    handleSaveAIGeneratedInsight,
    saved,
    topicsMap,
}: InsightsListCardProps) {
    const navigate = useNavigate();
    const [insightState, setInsightState] = useState<Insight>(insight);

    const [isAIGenerated, topic, category] = processInsightMetadata(insight);
    const [titleMaxWidth, setTitleMaxWidth] = useState(
        window.innerWidth * 0.55,
    );

    const handleRowClick = (id: string) => {
        if (saved) {
            navigate(`/insight/${id}`);
        }
    };

    const externalIssuesIcons = useMemo(() => {
        const icons = new Set<IconEntry>();
        // biome-ignore lint/complexity/noForEach: <explanation>
        insightState.external_issues?.forEach((issue) => {
            icons.add({
                Component: getExternalIssueIcon(issue.url),
                props: {
                    width: 20,
                    height: 20,
                    style: { marginLeft: "2", marginRight: "2" },
                },
            });
        });
        return icons;
    }, [insightState.external_issues]);

    const created_date: string = useMemo(() => {
        try {
            const userLocale = navigator.language || "en-US";
            return new Date(insightState.created_at).toLocaleDateString(
                userLocale,
                {
                    month: "short",
                    day: "numeric",
                },
            );
        } catch (err) {
            console.log(
                `Could not convert ticket's created at timestamp ${insightState.created_at} to a valid date, so using the original timestamp format. Error: ${err}`,
            );
            return insightState.created_at;
        }
    }, [insightState.created_at]);

    const handleOpenInNewTab = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        event.stopPropagation()
        window.open(`/insight/${id}`, '_blank');
    };

    const handleReload = () => {
        window.location.reload();
    };

    return (
        <Card
            className="py-2.5 px-5 border-l-transparent border-r-transparent border-b-transparent hover:bg-muted rounded w-full"
            onClick={() => handleRowClick(insightState.id)}
        >
            <CardContent className="p-0">
                <ContextMenu>
                    <ContextMenuTrigger>
                        <button
                            className="text-xs bg-transparent border-none p-0 cursor-pointer w-full"
                            type="button"
                        >
                            <div className="flex items-center justify-between w-full gap-2">
                                <div className="flex gap-2 items-start">
                                    {saved ? (
                                        <BookmarkFilledIcon className="h-4 w-4 text-muted-foreground" />
                                    ) : (
                                        <button
                                            type="button"
                                            className="p-1 hover:bg-secondary hover:text-gray-950 rounded-md"
                                            onClick={() =>
                                                handleSaveAIGeneratedInsight(insight)
                                            }
                                        >
                                            <BookmarkIcon className="h-4 w-4 text-muted-foreground" />
                                        </button>
                                    )}
                                    <div
                                        className="flex flex-col gap-0.5"
                                        style={{ maxWidth: titleMaxWidth }}
                                    >
                                        <div className="flex flex items-center gap-1">
                                            <div className="font-semibold text-[13px] overflow-hidden whitespace-nowrap text-ellipsis">
                                                {insightState.title.trim()}
                                            </div>
                                            {!saved && (
                                                <img
                                                    src={SparklesIcon}
                                                    alt=""
                                                    className="h-6 w-6 rounded p-1"
                                                />
                                            )}
                                        </div>
                                        <div className="text-xs text-muted-foreground overflow-hidden whitespace-nowrap text-ellipsis">
                                            {insightState.description}
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-end">
                                    <div className="relative flex-none">
                                        <div className="flex items-center gap-2">
                                            {topic && (
                                                <Badge
                                                    color="gray"
                                                    size="2"
                                                    radius="full"
                                                    variant="outline"
                                                    className="m-0.5"
                                                >
                                                    <div className="flex flex-row items-center">
                                                        <DotFilledIcon
                                                            color={
                                                                topicsMap.get(topic)
                                                                    ?.color &&
                                                                    topicsMap.get(topic)
                                                                        ?.color !== ""
                                                                    ? topicsMap.get(
                                                                        topic,
                                                                    )?.color
                                                                    : "#9B9EF0"
                                                            }
                                                            style={{
                                                                transform: "scale(1.8)",
                                                            }}
                                                        />
                                                        <p className="pl-0.3">
                                                            {topic}
                                                        </p>
                                                    </div>
                                                </Badge>
                                            )}
                                            {category &&
                                                category.toLowerCase() !==
                                                "unknown" && (
                                                    <Badge
                                                        color="gray"
                                                        size="2"
                                                        radius="full"
                                                        variant="outline"
                                                        className="m-0.5"
                                                    >
                                                        <div className="flex flex-row items-center">
                                                            <ComponentBooleanIcon
                                                                color={getColorLight(
                                                                    category,
                                                                )}
                                                            />
                                                            <p className="pl-0.5">
                                                                {category}
                                                            </p>
                                                        </div>
                                                    </Badge>
                                                )}
                                            {saved && (
                                                <div className="text-[12px] w-11 flex justify-center items-center">
                                                    {created_date}
                                                </div>
                                            )}
                                            {Array.from(externalIssuesIcons).map(
                                                (icon) =>
                                                    React.createElement(
                                                        icon.Component,
                                                        icon.props,
                                                    ),
                                            )}
                                            <Badge
                                                color="gray"
                                                size="2"
                                                radius="full"
                                                variant="soft"
                                                className="m-0.5"
                                            >
                                                <div className="flex flex-row items-center">
                                                    <CaretUpIcon />
                                                    <p className="pl-0.5">
                                                        {insightState.count}
                                                    </p>
                                                </div>
                                            </Badge>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </ContextMenuTrigger>
                    <ContextMenuContent className="w-60">
                        <ContextMenuItem inset
                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                            onClick={(event) => handleOpenInNewTab(event, insightState.id)}>
                            <OpenInNewWindowIcon className="w-3.5 h-3.5" />
                            Open in New Tab
                        </ContextMenuItem>
                        <ContextMenuItem inset className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5">
                            <DoubleArrowLeftIcon className="w-3.5 h-3.5" />
                            Back
                        </ContextMenuItem>
                        <ContextMenuItem inset onClick={handleReload} className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5">
                            <ReloadIcon className="w-3 h-3" />
                            Reload
                        </ContextMenuItem>
                    </ContextMenuContent>
                </ContextMenu>
            </CardContent>
        </Card>
    );
}

export default memo(InsightsListCard);
