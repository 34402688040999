import { Input } from "@/component/shadcn/ui/input";
import type {
    CustomerGroup,
    GetUserResponse,
    Query,
} from "@/interfaces/serverData";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { memo } from "react";
import { FilterItems } from "./FilterItems";

interface FilterBarProps {
    issues: Query[];
    topics: { color: string; label: string; value: string }[];
    users: GetUserResponse[];
    customerGroups: CustomerGroup[];
    searchQuery: string;
    setSearchQuery: (query: string) => void;

    filters: Map<string, Set<string>>;
    setFilters: (filters: Map<string, Set<string>>) => void;
    savedViewFilters: Map<string, Set<string>>;
}

const FilterBar: React.FC<FilterBarProps> = ({
    issues,
    topics,
    users,
    customerGroups,
    searchQuery,
    setSearchQuery,
    filters,
    setFilters,
    savedViewFilters,
}) => {
    return (
        <div className="flex flex-col gap-1 m-1 py-1 bg-muted">
            <div className="flex items-center gap-0 mx-5 mt-2">
                <MagnifyingGlassIcon className="w-4 h-4 text-gray-700" />
                <Input
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full p-1.5 rounded-md text-xs border-none shadow-none focus:outline-none focus:ring-0 focus:border-transparent focus:shadow-none"
                />
            </div>
            <div className="flex mx-4 mt-2">
                <FilterItems
                    filters={filters}
                    setFilters={setFilters}
                    topics={topics}
                    users={users}
                    customerGroups={customerGroups}
                    savedViewFilters={savedViewFilters}
                />
            </div>
        </div>
    );
};

export default memo(FilterBar);
