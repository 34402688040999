import { TeamsAPI, URLS, pages } from "@/constant";
import type { Teams } from "@/interfaces/serverData";
import { useAuthInfo } from "@propelauth/react";
import {
    GroupIcon,
    IdCardIcon,
    PlusIcon,
    ThickArrowRightIcon,
    TrashIcon,
} from "@radix-ui/react-icons";
import {
    BellIcon,
    BookmarkIcon,
    PersonIcon,
    TimerIcon,
} from "@radix-ui/react-icons";
import { NavigationMenu } from "@radix-ui/react-navigation-menu";
import { useMutation, useQuery } from "@tanstack/react-query";
import { HouseIcon, SettingsIcon, UsersIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../shadcn/ui/button";
import {
    NavigationMenuItem,
    NavigationMenuList,
} from "../shadcn/ui/navigation-menu";
import BallPopover from "./BallPopover";
import MenuDropdownComponent from "./MenuDropdownComponent";

import { TriangleDownIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import {
    ContextMenu,
    ContextMenuContent,
    ContextMenuItem,
    ContextMenuTrigger,
} from "../shadcn/ui/context-menu";

import { motion } from "framer-motion";
import { DropdownSection, DropdownSectionButton } from "./utils";

import { Separator } from "../shadcn/ui/separator";

export type FilterMetadata = {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    granularFilters: Map<any, boolean>;
    enabled: boolean;
};

export const NAVBAR_PREFERENCES_ITEMS = {
    profile: "profile",
    tags: "tags",
    alerts: "alerts",
    members: "members",
    teams: "teams",
    sla: "sla",
    customergroups: "customergroups",
    integrations: "integrations",
    workflows: "workflows",
    savedTeam: "savedTeam",
    savedTeamSettings: "savedTeamSettings",
};
interface SideBarProps {
    organizationName: string;
    orgID: string;
    filters?: Map<string, FilterMetadata>;
    activeButton: string;
    setActiveButton: (newTerm: string) => void;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    handleToggle?: (type: string, value: any) => () => void;
    isAdmin: boolean;
    width: number;
}

export const drawerWidth = "250px";

export default function WorkspaceSideBarComponent(props: SideBarProps) {
    const [selectedItem, setSelectedItem] = useState(props.activeButton);

    const [teamID, setTeamID] = useState<string | null>(null);

    // Change this to store the ID of the currently expanded team (if any)
    const [expandedTeamId, setExpandedTeamId] = useState<string>(props.orgID);

    useEffect(() => {
        const path = window.location.pathname;
        if (path.includes("/preferences/profile")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.profile);
        } else if (path.includes("/preferences/tags")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.tags);
        } else if (path.includes("/preferences/alerts")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.alerts);
        } else if (path.includes("/preferences/members")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.members);
        } else if (path.includes("/preferences/sla")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.sla);
        } else if (path.includes("/preferences/customergroups")) {
            setSelectedItem(NAVBAR_PREFERENCES_ITEMS.customergroups);
        } else if (path.includes("/preferences/teams")) {
            const id = path.split("/").pop(); // Get the last segment of the path
            setTeamID(id || null); // Set teamID to the extracted id
            setSelectedItem(`savedTeam_${id}`);
        }
    }, [window.location.pathname]);

    const handleMenuClick = (buttonName: string, teamID?: string) => {
        // You can call the respective function here
        props.setActiveButton(buttonName);
        setSelectedItem(buttonName);
        if (buttonName === NAVBAR_PREFERENCES_ITEMS.profile) {
            navigate("/preferences/profile");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.tags) {
            navigate("/preferences/tags");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.alerts) {
            navigate("/preferences/alerts");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.members) {
            navigate("/preferences/members");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.sla) {
            navigate("/preferences/sla");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.customergroups) {
            navigate("/preferences/customergroups");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.workflows) {
            navigate("/preferences/workflows");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.teams) {
            navigate("/preferences/teams");
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.savedTeam) {
            if (teamID) {
                setSelectedItem(`savedTeam_${teamID}`);
                navigate(`/preferences/teams/${teamID}`);
            } else {
                navigate("/preferences");
            }
        } else if (buttonName === NAVBAR_PREFERENCES_ITEMS.savedTeamSettings) {
            if (teamID) {
                setSelectedItem(`savedTeamSettings_${teamID}`);
                navigate(`/preferences/teams/${teamID}/settings`);
            } else {
                navigate("/preferences");
            }
        }
    };

    const authInfo = useAuthInfo();

    const authInfoRef = useRef(authInfo);

    const teamsQuery = useQuery<Teams[]>({
        queryKey: ["teams"],
        queryFn: async () => {
            const [url, method] = TeamsAPI.listTeams;
            const response = await fetch(`${URLS.serverUrl}${url}`, {
                method: method,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            const d = await response.json();
            return d.data;
        },
    });

    const deleteTeamsMutation = useMutation({
        mutationFn: async (id: string) => {
            const [url, method] = TeamsAPI.deleteTeam;
            const response = await fetch(`${URLS.serverUrl}${url}/${id}`, {
                method: method,
                headers: {
                    Authorization: `Bearer ${authInfoRef.current?.accessToken}`,
                },
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            return result.data;
        },
        onSuccess: (data) => {
            teamsQuery.refetch();
            const currentViewId = selectedItem.split("_")[1];
            if (currentViewId === data) {
                setSelectedItem(NAVBAR_PREFERENCES_ITEMS.members);
                navigate("/preferences/members");
            }
        },
    });

    const collapsed = props.width < 10;
    const navigate = useNavigate();

    const [teamViewsExpanded, setTeamViewsExpanded] = useState<boolean>(true);

    const toggleTeamExpanded = (teamId: string) => {
        setExpandedTeamId((prevId) => (prevId === teamId ? "" : teamId));
    };

    const [selectedSubItem, setSelectedSubItem] = useState<string>("");

    return (
        <div
            className={`relative transition-all duration-300 min-w-[15px]${
                collapsed ? "w-15" : "w-50"
            }`}
        >
            <div className="flex flex-row items-center justify-between pt-3 pb-6 px-3 w-full">
                <MenuDropdownComponent
                    organizationName={props.organizationName}
                    state={pages.search}
                    collapsed={collapsed}
                />
            </div>

            <NavigationMenu
                className="flex flex-col space-y-2"
                orientation="vertical"
            >
                <div
                    className={`text-xs px-5 font-bold text-gray-500 ${
                        collapsed ? "hidden" : "block"
                    }`}
                >
                    My Account
                </div>
                <NavigationMenuList className="flex flex-col items-center w-full">
                    <NavigationMenuItem className="w-full">
                        <div className="w-full px-3">
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() =>
                                    handleMenuClick(
                                        NAVBAR_PREFERENCES_ITEMS.profile,
                                    )
                                }
                                className={`flex justify-center items-center text-left w-full box-border ${
                                    selectedItem ===
                                    NAVBAR_PREFERENCES_ITEMS.profile
                                        ? "bg-secondary text-gray-950"
                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                }`}
                            >
                                <PersonIcon />
                                <div
                                    className={`flex-1 ml-2 ${
                                        collapsed ? "hidden" : "block"
                                    }`}
                                >
                                    Profile
                                </div>
                            </Button>
                        </div>
                    </NavigationMenuItem>
                </NavigationMenuList>
                {props.isAdmin && (
                    <>
                        <div
                            className={`text-xs px-5 font-bold text-gray-500 ${
                                collapsed ? "hidden" : "block"
                            }`}
                        >
                            Preferences
                        </div>
                        <NavigationMenuList className="flex flex-col items-center w-full">
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.tags,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.tags
                                                ? "bg-secondary text-gray-950"
                                                : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                        }`}
                                    >
                                        <BookmarkIcon />
                                        <div
                                            className={`flex-1 ml-2 ${
                                                collapsed ? "hidden" : "block"
                                            }`}
                                        >
                                            Tags
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.alerts,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.alerts
                                                ? "bg-secondary text-gray-950"
                                                : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                        }`}
                                    >
                                        <BellIcon />
                                        <div
                                            className={`flex-1 ml-2 ${
                                                collapsed ? "hidden" : "block"
                                            }`}
                                        >
                                            Alerts
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.members,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.members
                                                ? "bg-secondary text-gray-950"
                                                : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                        }`}
                                    >
                                        <IdCardIcon />
                                        <div
                                            className={`flex-1 ml-2 ${
                                                collapsed ? "hidden" : "block"
                                            }`}
                                        >
                                            Members
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.sla,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.sla
                                                ? "bg-secondary text-gray-950"
                                                : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                        }`}
                                    >
                                        <TimerIcon />
                                        <div
                                            className={`flex-1 ml-2 ${
                                                collapsed ? "hidden" : "block"
                                            }`}
                                        >
                                            SLAs
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.workflows,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.workflows
                                                ? "bg-secondary text-gray-950"
                                                : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                        }`}
                                    >
                                        <ThickArrowRightIcon />
                                        <div
                                            className={`flex-1 ml-2 ${
                                                collapsed ? "hidden" : "block"
                                            }`}
                                        >
                                            Workflows
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                            <NavigationMenuItem className="w-full">
                                <div className="w-full px-3">
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={() =>
                                            handleMenuClick(
                                                NAVBAR_PREFERENCES_ITEMS.customergroups,
                                            )
                                        }
                                        className={`flex justify-center items-center text-left w-full box-border ${
                                            selectedItem ===
                                            NAVBAR_PREFERENCES_ITEMS.customergroups
                                                ? "bg-secondary text-gray-950"
                                                : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                        }`}
                                    >
                                        <GroupIcon />
                                        <div
                                            className={`flex-1 ml-2 ${
                                                collapsed ? "hidden" : "block"
                                            }`}
                                        >
                                            Customer Groups
                                        </div>
                                    </Button>
                                </div>
                            </NavigationMenuItem>
                        </NavigationMenuList>
                    </>
                )}
                {props.isAdmin && (
                    <DropdownSection
                        title="Teams"
                        expanded={teamViewsExpanded}
                        setExpanded={setTeamViewsExpanded}
                    >
                        <NavigationMenuList className="flex flex-col items-center w-full">
                            {teamsQuery.data?.map((team) => {
                                return (
                                    <NavigationMenuItem
                                        className="w-full px-3"
                                        style={{ margin: "0px" }}
                                        key={team.id}
                                    >
                                        <DropdownSectionButton
                                            parentButton={
                                                <ContextMenu>
                                                    <ContextMenuTrigger className="w-full">
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            className={
                                                                "flex justify-center items-center text-left w-full box-border text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                            }
                                                        >
                                                            {team.id ===
                                                            props.orgID ? (
                                                                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                                                                    <HouseIcon
                                                                        className="text-iris9"
                                                                        strokeWidth={
                                                                            1.5
                                                                        }
                                                                        size={
                                                                            16
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <div className="flex items-center justify-center rounded-lg p-1 bg-red3 border border-red4 shadow-sm">
                                                                    <UsersIcon
                                                                        className="text-red9"
                                                                        strokeWidth={
                                                                            1.5
                                                                        }
                                                                        size={
                                                                            16
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                            <div
                                                                className={`flex items-center flex-1 ml-2 ${collapsed ? "hidden" : "flex"}`}
                                                            >
                                                                <div className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap mr-1">
                                                                    {
                                                                        team.team_name
                                                                    }
                                                                </div>
                                                                <motion.div
                                                                    initial="collapsed"
                                                                    animate={
                                                                        expandedTeamId ===
                                                                        team.id
                                                                            ? "expanded"
                                                                            : "collapsed"
                                                                    }
                                                                >
                                                                    {expandedTeamId ===
                                                                    team.id ? (
                                                                        <TriangleDownIcon className="h-4 w-4" />
                                                                    ) : (
                                                                        <TriangleRightIcon className="h-4 w-4" />
                                                                    )}
                                                                </motion.div>
                                                            </div>
                                                        </Button>
                                                    </ContextMenuTrigger>
                                                    <ContextMenuContent
                                                        hidden={
                                                            team.id ===
                                                            props.orgID
                                                        }
                                                        className="w-60"
                                                    >
                                                        <ContextMenuItem
                                                            inset
                                                            className="text-xs rounded-md text-gray-700 hover:text-gray-950 hover:bg-gray-100 text-semibold flex items-center gap-1.5"
                                                            onClick={() => {
                                                                deleteTeamsMutation.mutate(
                                                                    team.id,
                                                                );
                                                            }}
                                                        >
                                                            <TrashIcon className="w-3.5 h-3.5" />
                                                            Delete Team
                                                        </ContextMenuItem>
                                                    </ContextMenuContent>
                                                </ContextMenu>
                                            }
                                            expanded={
                                                expandedTeamId === team.id
                                            }
                                            setExpanded={() =>
                                                toggleTeamExpanded(team.id)
                                            }
                                        >
                                            <div className="flex w-full">
                                                <div className="flex-none">
                                                    <Separator
                                                        orientation="vertical"
                                                        className="w-px bg-sidebarBorder ml-6"
                                                    />
                                                </div>
                                                <div className="w-full">
                                                    <NavigationMenuList className="flex flex-col w-full">
                                                        <NavigationMenuItem className="w-full pl-4 pb-0.5">
                                                            <Button
                                                                onClick={() => {
                                                                    handleMenuClick(
                                                                        NAVBAR_PREFERENCES_ITEMS.savedTeam,
                                                                        team.id,
                                                                    );
                                                                    setSelectedSubItem(
                                                                        `members_${team.id}`,
                                                                    );
                                                                }}
                                                                variant="ghost"
                                                                size="sm"
                                                                className={`flex justify-start items-center text-left w-full box-border pl-3 text-xs ${
                                                                    selectedSubItem ===
                                                                    `members_${team.id}`
                                                                        ? "bg-secondary text-gray-950"
                                                                        : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                                }`}
                                                            >
                                                                <IdCardIcon />
                                                                <div
                                                                    className={`flex-1 ml-2 ${
                                                                        collapsed
                                                                            ? "hidden"
                                                                            : "block"
                                                                    }`}
                                                                >
                                                                    Members
                                                                </div>
                                                            </Button>
                                                        </NavigationMenuItem>
                                                        {props.orgID !==
                                                            team.id && (
                                                            <NavigationMenuItem className="w-full pl-4 pt-1">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleMenuClick(
                                                                            NAVBAR_PREFERENCES_ITEMS.savedTeamSettings,
                                                                            team.id,
                                                                        );
                                                                        setSelectedSubItem(
                                                                            `setup_${team.id}`,
                                                                        );
                                                                    }}
                                                                    variant="ghost"
                                                                    size="sm"
                                                                    className={`flex justify-start items-center text-left w-full box-border pl-3 text-xs ${
                                                                        selectedSubItem ===
                                                                        `setup_${team.id}`
                                                                            ? "bg-secondary text-gray-950"
                                                                            : "text-gray-700 hover:text-gray-950 hover:bg-gray-100"
                                                                    }`}
                                                                >
                                                                    <SettingsIcon
                                                                        strokeWidth={
                                                                            1.2
                                                                        }
                                                                        size={
                                                                            14
                                                                        }
                                                                    />
                                                                    <div
                                                                        className={`flex-1 ml-2 ${
                                                                            collapsed
                                                                                ? "hidden"
                                                                                : "block"
                                                                        }`}
                                                                    >
                                                                        Settings
                                                                    </div>
                                                                </Button>
                                                            </NavigationMenuItem>
                                                        )}
                                                    </NavigationMenuList>
                                                </div>
                                            </div>
                                        </DropdownSectionButton>
                                    </NavigationMenuItem>
                                );
                            })}
                        </NavigationMenuList>
                    </DropdownSection>
                )}
            </NavigationMenu>
            <Button
                className="bg-[#5B5BD6] text-white m-6 py-1.5 px-2.5 hover:shadow-lg shadow-md outline outline-1 outline-offset-0 outline-white flex flex-wrap gap-2 justify-start data-[state=open]:bg-muted text-[12px]"
                variant="outline"
                onClick={() => {
                    navigate("/preferences/teams/new");
                }}
            >
                Add Team
                <PlusIcon />
            </Button>
            <div className="fixed bottom-0 left-0 p-4">
                <BallPopover />
            </div>
        </div>
    );
}
