import { sources, statuses, tags } from "@/IssuesTable/constants";
import { API, URLS } from "@/constant";
import type {
    CustomerGroup,
    GetUserResponse,
    Insight,
    InsightData,
    Query,
    ScopeResponse,
    TicketStatus,
    TicketTag,
    TicketTopics,
} from "@/interfaces/serverData";
import { integrationBackEndDataMappingToSvg } from "@/pages/Admin/Integrations/constant";

import {
    type CheckCircledIcon,
    EnvelopeOpenIcon,
    ExclamationTriangleIcon,
    type LapTimerIcon,
    PersonIcon,
    QuestionMarkCircledIcon,
    ReaderIcon,
    ResetIcon,
    type StopwatchIcon,
    TargetIcon,
} from "@radix-ui/react-icons";
import type { badgePropDefs } from "@radix-ui/themes/dist/cjs/components/badge.props";
import type { QueryClient } from "@tanstack/react-query";
import type { AxiosInstance } from "axios";
import _ from "lodash";
import { ListChecksIcon, MoonStarIcon, StarIcon, TagIcon } from "lucide-react";
import ReactDOMServer from "react-dom/server";

export type BadgeColor = (typeof badgePropDefs.color.values)[number];
export function getColor(input: string): BadgeColor {
    switch (input) {
        case "Query":
            return "green";
        case "Feature":
            return "blue";
        case "Bug":
            return "red";
        default:
            return "gray";
    }
}

export function getColorLight(input: string): string {
    switch (input) {
        case "Query":
            return "#5BB98B";
        case "Feature":
            return "#5EB1EF";
        case "Bug":
            return "#EB8E90";
        default:
            return "#BBBBBB";
    }
}

export function getTopicColor(input: string): BadgeColor {
    switch (input) {
        case "autoresponder setup":
            return "green";
        case "channel activity":
            return "pink";
        default:
            return "iris";
    }
}

export function processInsightMetadata(
    insight: Insight,
): [boolean, string, string] {
    if (insight.metadata) {
        const metadata = new Map(Object.entries(JSON.parse(insight.metadata)));
        const isAIGenerated =
            metadata.has("ai_generated") &&
            Boolean(metadata.get("ai_generated"));
        const topicGeneratedFrom: string =
            (metadata.get("topic_generated_from") as string) ?? "";
        const categoryGeneratedFrom: string =
            (metadata.get("category_generated_from") as string) ?? "";
        return [isAIGenerated, topicGeneratedFrom, categoryGeneratedFrom];
    }
    return [false, "", ""];
}

export async function saveAIGeneratedInsight(
    insight: Insight,
    queryClient: QueryClient,
    userID: string,
    api: AxiosInstance,
) {
    const requestData: InsightData = {
        title: insight.title,
        description: insight.description,
        user_id: userID,
        related_issues_added: insight.related_issues.map((ri) => ri.id),
        metadata: insight.metadata,
    };

    try {
        const res = await api.post(
            URLS.serverUrl + API.saveInsight,
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        if (res.status === 200) {
            console.log(`Saved insight id ${res.data.data} successfully`);
            await queryClient.refetchQueries({
                queryKey: ["insights"],
                exact: true,
            });
            return res.data.data;
        } else {
            console.log("Call to update status failed");
            return "";
        }
    } catch (error) {
        console.error("Error saving insight:", error);
        return "";
    }
}

export function getExternalIssueIcon(url: string): React.ElementType {
    const icon = url.toLowerCase().includes("linear")
        ? integrationBackEndDataMappingToSvg.get("Linear")
        : url.toLowerCase().includes("github")
            ? integrationBackEndDataMappingToSvg.get("GitHubTicket")
            : url.toLowerCase().includes("atlassian")
                ? integrationBackEndDataMappingToSvg.get("Jira")
                : url.toLowerCase().includes("intercom")
                    ? integrationBackEndDataMappingToSvg.get("Intercom")
                    : undefined;

    return icon || ReaderIcon;
}

export function getExternalIssueText(url: string): string {
    const text = url.toLowerCase().includes("linear")
        ? "Linear"
        : url.toLowerCase().includes("github")
            ? "GitHub"
            : url.toLowerCase().includes("atlassian")
                ? "Jira"
                : url.toLowerCase().includes("intercom")
                    ? "Intercom"
                    : "";
    return text;
}

export const areQueriesEqual = (query1: Query, query2: Query): boolean => {
    return (
        query1.id === query2.id ||
        query1.title === query2.title ||
        query1.query === query2.query
    );
};

export const addToCustomSet = (
    query: Query,
    set: Set<Query>,
    compareFn: (q1: Query, q2: Query) => boolean,
) => {
    if (
        !Array.from(set).some((existingQuery) =>
            compareFn(existingQuery, query),
        )
    ) {
        set.add(query);
    }
};

export function getPlural(type: string) {
    switch (type) {
        case "Source": {
            return "sources";
        }
        case "Broadcast": {
            return "broadcasts";
        }
        case "Tag": {
            return "tags";
        }
        case "Topic": {
            return "topics";
        }
        case "Status": {
            return "statuses";
        }
        case "Assignee": {
            return "assignees";
        }
    }
}

export function getOptions(
    type: string,
    topics: { color: string; label: string; value: string }[],
    users: GetUserResponse[],
    customerGroups: CustomerGroup[],
    channels?: ScopeResponse[],
    organizationSources?: string[],
) {
    switch (type) {
        case "Source": {
            if (organizationSources !== undefined) {
                return sources.filter((broadcast) =>
                    organizationSources.includes(broadcast.value),
                );
            } else {
                return sources;
            }
        }
        case "Channel": {
            return channels?.map((channel) => ({
                label: channel.name,
                value: channel.key,
                color: "",
            }));
        }
        case "Tag": {
            return tags;
        }
        case "Topic": {
            return topics;
        }
        case "Status": {
            return statuses;
        }
        case "Assignee": {
            const options = users.map((user) => ({
                label: `${user.first_name} ${user.last_name}`,
                value: user.id,
                color: "",
            }));
            options.push({
                color: "",
                label: "No Assignee",
                value: "noAssignee",
            });
            return options;
        }
        case "Customer Group": {
            const options = customerGroups.map((cg) => ({
                label: cg.group_name,
                value: cg.group_name,
                color: "",
            }));
            return options;
        }
    }
}

export function getStatusFullIcon(status: string) {
    const Icon = getStatusIcon(status ?? "Unknown");
    switch (status) {
        case "Open": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FFEFD6] border border-[#FFDFB5] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "NeedsResponse": {
            return (props: React.ComponentProps<typeof LapTimerIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#EEF1F0] border border-[#E6E9E8] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Breaching": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#FFF6F8] border border-[#FFE9ED] shadow-sm">
                    <Icon />
                </div>
            );
        }
        case "Closed": {
            return (props: React.ComponentProps<typeof CheckCircledIcon>) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-[#EDF2FE] border border-[#E1E9FF] shadow-sm">
                    <Icon />
                </div>
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => (
                <div className="flex items-center justify-center rounded-lg p-1 bg-iris3 border border-iris4 shadow-sm">
                    <Icon />
                </div>
            );
        }
    }
}

export function getStatusIcon(status: string) {
    switch (status) {
        case "Open": {
            return (props: React.ComponentProps<typeof StopwatchIcon>) => (
                <TargetIcon {...props} color="#EC9455" />
            );
        }
        case "NeedsResponse": {
            return (props: React.ComponentProps<typeof ResetIcon>) => (
                <ResetIcon {...props} color="#868E8B" />
            );
        }
        case "Breaching": {
            return (
                props: React.ComponentProps<typeof ExclamationTriangleIcon>,
            ) => <ExclamationTriangleIcon {...props} color="#f57695" />;
        }
        case "Closed": {
            return (props: React.ComponentProps<typeof MoonStarIcon>) => (
                <MoonStarIcon
                    strokeWidth={1.5}
                    size={16}
                    {...props}
                    color="#3358D4"
                />
            );
        }
        default: {
            return (
                props: React.ComponentProps<typeof QuestionMarkCircledIcon>,
            ) => <QuestionMarkCircledIcon {...props} color="#808080" />;
        }
    }
}

export function getAnnouncementStatusIcon(status: string) {
    switch (status) {
        case "Draft": {
            return ReaderIcon;
        }
        case "Sent": {
            return EnvelopeOpenIcon;
        }
        default: {
            return QuestionMarkCircledIcon;
        }
    }
}

export function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
    return _.isEqual(arr1, arr2);
}

export function formatUsernames(content: string) {
    const regex = /<@([^>]+)>/g;

    return content.replace(regex, (match, username) => {
        return `<span class="mention">@${username}</span>`;
    });
}

export const emojiMap = new Map<string, string>([
    ["large_green_circle", "🟢"],
    ["large_orange_circle", "🟠"],
    ["large_red_circle", "🔴"],
    ["flag-us", "🇺🇸"], // United States
    ["flag-ca", "🇨🇦"], // Canada
    ["flag-gb", "🇬🇧"], // United Kingdom
    ["flag-fr", "🇫🇷"], // France
    ["flag-de", "🇩🇪"], // Germany
    ["flag-it", "🇮🇹"], // Italy
    ["flag-es", "🇪🇸"], // Spain
    ["flag-jp", "🇯🇵"], // Japan
    ["flag-kr", "🇰🇷"], // South Korea
    ["flag-br", "🇧🇷"], // Brazil
    ["flag-cn", "🇨🇳"], // China
    ["flag-in", "🇮🇳"], // India
    ["flag-au", "🇦🇺"], // Australia
    ["flag-ru", "🇷🇺"], // Russia
    ["flag-se", "🇸🇪"], // Sweden
    ["flag-no", "🇳🇴"], // Norway
    ["flag-nz", "🇳🇿"], // New Zealand
    ["flag-mx", "🇲🇽"], // Mexico
    ["flag-pt", "🇵🇹"], // Portugal
    ["flag-ch", "🇨🇭"], // Switzerland
    ["flag-at", "🇦🇹"], // Austria
    ["flag-be", "🇧🇪"], // Belgium
    ["flag-dk", "🇩🇰"], // Denmark
    ["flag-fi", "🇫🇮"], // Finland
    ["flag-gr", "🇬🇷"], // Greece
    ["flag-ru", "🇷🇺"], // Russia
    ["flag-ua", "🇺🇦"], // Ukraine
    ["flag-ph", "🇵🇭"], // Philippines
    ["flag-za", "🇿🇦"], // South Africa
    ["flag-tw", "🇹🇼"], // Taiwan
    ["flag-id", "🇮🇩"], // Indonesia
    ["satellite_antenna", "📡"],
    ["waving_white_flag", "🏳️"]
]);

export function formatEmojis(content: string) {
    return content.replace(/(:[\w-]+:)/g, (match) => {
        const emojiName = match.slice(1, -1);
        console.log("emoji name is", emojiName)
        return emojiMap.get(emojiName) || match
    });
};

export function cleanText(text: string): string {
    // Replace HTML tags with an empty string
    const htmlTagRegex = /<[^>]*>/g;
    let cleanedText = text.replace(htmlTagRegex, " ");

    // Remove images
    cleanedText = cleanedText.replace(/!\[.*?\]\(.*?\)/g, "");
    // Remove headers
    cleanedText = cleanedText.replace(/^#.*$/gm, "");
    // Remove blockquotes
    cleanedText = cleanedText.replace(/^> .*/gm, "");

    // Replace newlines with a single space and trim leading/trailing spaces
    return cleanedText.replace(/(\r\n|\n|\r)/gm, " ").trim();
}

export function getHtmlStringFromReactContent(
    reactElement: React.ReactNode,
): string {
    return ReactDOMServer.renderToString(reactElement);
}

export const getIconForType = (type: string) => {
    switch (type) {
        case "Tag":
            return <TagIcon className="w-3 h-3" />;
        case "Topic":
            return <ListChecksIcon className="w-3 h-3" />;
        case "Status":
            return <StarIcon className="w-3.5 h-3.5" />;
        case "Assignee":
            return <PersonIcon className="w-3.5 h-3.5" />;
        default:
            return null;
    }
};

export function saveIssue(
    type: string,
    value: string,
    api: AxiosInstance,
    issueState: Query,
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
) {
    switch (type) {
        case "Tag":
            return saveTag(value, api, issueState, updateIssueState, userID);
        case "Topic":
            return toggleTopicSelection(
                value,
                api,
                issueState,
                updateIssueState,
                userID,
            );
        case "Status":
            return saveStatus(value, api, issueState, updateIssueState, userID);
        case "Assignee":
            return saveAssignee(
                value,
                api,
                issueState,
                updateIssueState,
                userID,
            );
        default:
            return null;
    }
}

function saveTag(
    tag: string,
    api: AxiosInstance,
    issueState: Query,
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
) {
    const requestData: TicketTag = {
        id: issueState.id,
        tag: tag,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            updateIssueState({ bot_category: tag });
            console.log(`Updated tag to ${tag} successfully`);
        } else {
            console.log("Call to update tag failed");
        }
    });
}

const toggleTopicSelection = (
    topic: string,
    api: AxiosInstance,
    issueState: Query,
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
) => {
    const isSelected = issueState.topic?.includes(topic);
    let newTopicsSelected: string[];
    let addedTopics: string[] = [];
    let deletedTopics: string[] = [];

    if (isSelected) {
        newTopicsSelected = issueState.topic?.filter((t) => t !== topic) || [];
        deletedTopics = [topic];
    } else {
        newTopicsSelected = [...(issueState.topic || []), topic];
        addedTopics = [topic];
    }
    updateIssueState({ topic: newTopicsSelected });
    saveTopics(addedTopics, deletedTopics, api, issueState, userID);
};

function saveTopics(
    addedTopics: string[],
    deletedTopics: string[],
    api: AxiosInstance,
    issueState: Query,
    userID: string,
) {
    const requestData: TicketTopics = {
        id: issueState.id,
        added_topics: addedTopics,
        deleted_topics: deletedTopics,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            console.log(
                `Added topics ${addedTopics} and deleted topics ${deletedTopics} successfully`,
            );
        } else {
            console.log("Call to update topics failed");
        }
    });
}

function saveStatus(
    status: string,
    api: AxiosInstance,
    issueState: Query,
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
) {
    const requestData: TicketStatus = {
        id: issueState.id,
        status: status,
        source: "Web",
        user_id: userID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            updateIssueState({ ticket_status: status });
            console.log(`Updated status to ${status} successfully`);
        } else {
            console.log("Call to update status failed");
        }
    });
}

function saveAssignee(
    assigneeID: string,
    api: AxiosInstance,
    issueState: Query,
    updateIssueState: (newState: Partial<Query>) => void,
    userID: string,
) {
    console.log("in save assignee");
    const requestData = {
        id: issueState.id,
        source: "Web",
        user_id: userID,
        assignee_user_id: assigneeID === "noAssignee" ? "" : assigneeID,
    };
    api.patch(URLS.serverUrl + API.saveTicket, requestData, {
        headers: {
            "Content-Type": "application/json",
        },
    }).then((res) => {
        if (res.status === 200) {
            updateIssueState({ assignee_user_id: assigneeID });
            console.log(`Updated assignee to ${assigneeID} successfully`);
        } else {
            console.log("Call to update assignee failed");
        }
    });
}

export const mapsEqual = (
    mapA: Map<string, Set<string>>,
    mapB: Map<string, Set<string>>,
) => {
    if (mapA.size !== mapB.size) return false;
    for (const [key, value] of Array.from(mapA.entries())) {
        if (!mapB.has(key) || mapB.get(key) !== value) {
            return false;
        }
    }
    return true;
};
