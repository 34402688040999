import type { Account, IconEntry } from "@/interfaces/serverData";
import { Badge } from "@radix-ui/themes";
import type { ColumnDef } from "@tanstack/react-table";
import type { Row } from "@tanstack/react-table";
import { ImageIcon } from "lucide-react";
import React from "react";
import { DataTableColumnHeader } from "../../../WorkspacePreferences/DataTable/data-table-column-header";
import { integrationBackEndDataMappingToSvg } from "../../Integrations/constant";
import { queryToLabel } from "./constants";

export function generateCustomerColumms(
    rowState: Map<string, Account>,
    handleRowClick: (row: Row<Account>) => void,
): ColumnDef<Account>[] {
    const columns: ColumnDef<Account>[] = [
        // {
        //     accessorKey: "select",
        //     header: ({ column }) => <></>,
        //     cell: ({ row }) => (
        //         <Checkbox
        //             checked={row.getIsSelected()}
        //             onCheckedChange={row.getToggleSelectedHandler()}
        //         />
        //     ),
        //     enableSorting: false,
        //     size: 0,
        // },
        {
            accessorKey: "name",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.name}
                />
            ),
            cell: ({ row }) => {
                return (
                    <div
                        className="flex items-center gap-1"
                        onClick={() => {
                            if (handleRowClick) {
                                handleRowClick(row);
                            }
                        }}
                        onKeyDown={(e) => {}}
                    >
                        {row.original.image_url !== "" &&
                        row.original.image_url !== undefined ? (
                            <div className="lb-avatar rounded w-5 h-5">
                                <img
                                    className="lb-avatar-image"
                                    src={row.original.image_url}
                                    alt={row.original.name}
                                />

                                <span>{row.original.name ?? ""}</span>
                            </div>
                        ) : (
                            <div className="lb-avatar rounded w-6 h-6">
                                <ImageIcon className="w-5 h-5 mx-1" />
                            </div>
                        )}
                        <div className="flex flex-col items-start gap-1">
                            {row.original.name !== "" &&
                            row.original.name !== undefined ? (
                                <p className="text-xs pl-1 font-semibold">
                                    {row.original.name}
                                </p>
                            ) : (
                                <p className="text-xs pl-1 font-semibold">
                                    <i>Untitled</i>
                                </p>
                            )}
                        </div>
                    </div>
                );
            },

            filterFn: (row, id, value) => {
                // Return true to include all rows
                if (!value || value.length === 0) {
                    return true;
                }
                const query = row.original.name;
                return query.toLowerCase().includes(value.toLowerCase());
            },
            sortingFn: (rowA, rowB, columnId) => {
                return rowA.original.name.localeCompare(rowB.original.name);
            },
            enableSorting: true,
            size: 250,
        },
        {
            accessorKey: "accountType",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.accountType}
                    className="text-xs"
                />
            ),
            cell: ({ row }) => {
                return (
                    <div
                        className="flex flex-row float-right pr-2"
                        onClick={() => {
                            if (handleRowClick) {
                                handleRowClick(row);
                            }
                        }}
                        onKeyDown={(e) => {}}
                    >
                        <Badge
                            color={
                                row.original.company_account === "Company"
                                    ? "blue"
                                    : "orange"
                            }
                            size="2"
                            radius="medium"
                            variant="outline"
                            className="text-xs px-2 mr-2 items-center"
                        >
                            {row.original.company_account}
                        </Badge>
                    </div>
                );
            },
            enableSorting: false,
            size: 10,
        },
        {
            accessorKey: "plan",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.plan}
                />
            ),
            cell: ({ row }) => {
                return (
                    row.original.plan !== "" &&
                    row.original.plan !== undefined && (
                        <Badge
                            color="green"
                            size="2"
                            radius="medium"
                            variant="soft"
                            className="text-xs px-2 mr-2 items-center"
                            onClick={() => {
                                if (handleRowClick) {
                                    handleRowClick(row);
                                }
                            }}
                            onKeyDown={(e) => {}}
                        >
                            <div className="flex flex-row gap-1">
                                <p>{row.original.plan}</p>
                            </div>
                        </Badge>
                    )
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.original.plan);
            },
            enableResizing: true,
            size: 10,
        },
        {
            accessorKey: "Contract Value",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.contractValue}
                />
            ),
            cell: ({ row }) => {
                return (
                    row.original.contract_value !== 0 &&
                    row.original.contract_value !== undefined && (
                        <Badge
                            color="mint"
                            size="2"
                            radius="medium"
                            variant="soft"
                            className="text-xs px-2 mr-2 items-center"
                            onClick={() => {
                                if (handleRowClick) {
                                    handleRowClick(row);
                                }
                            }}
                            onKeyDown={(e) => {}}
                        >
                            <p>
                                {row.original.contract_type} | $
                                {row.original.contract_value}{" "}
                            </p>
                        </Badge>
                    )
                );
            },
            filterFn: (row, id, value) => {
                return value.includes(row.original.contract_value);
            },
            sortingFn: (rowA, rowB, columnId) => {
                return (
                    rowA.original.contract_value - rowB.original.contract_value
                );
            },
            enableSorting: true,
            enableResizing: true,
            size: 10,
        },
        // {
        //     accessorKey: "First Seen",
        //     header: ({ column }) => (
        //         <DataTableColumnHeader
        //             column={column}
        //             title={queryToLabel.firstSeen}
        //         />
        //     ),
        //     cell: ({ row }) => {
        //         let createdDate = new Date(row.original.created_at);

        //         if (
        //             Number.isNaN(createdDate.getTime()) ||
        //             !row.original.created_at
        //         ) {
        //             createdDate = new Date();
        //         }

        //         const today = new Date();

        //         const isToday =
        //             createdDate.getDate() === today.getDate() &&
        //             createdDate.getMonth() === today.getMonth() &&
        //             createdDate.getFullYear() === today.getFullYear();

        //         // Otherwise, return the standard date format
        //         const userLocale = navigator.language || "en-US";
        //         let date = createdDate.toLocaleDateString(userLocale, {
        //             month: "short",
        //             day: "numeric",
        //         });
        //         if (isToday) {
        //             const userLocale = navigator.language || "en-US";
        //             date = createdDate.toLocaleTimeString(userLocale, {
        //                 hour: "numeric",
        //                 minute: "numeric",
        //                 hour12: true,
        //             });
        //         }

        //         return (
        //             <p className="text-xs float-right font-normal items-center">
        //                 {date}
        //             </p>
        //         );
        //     },
        //     enableResizing: true,
        //     size: 10,
        // },
        {
            accessorKey: "sources",
            header: ({ column }) => (
                <DataTableColumnHeader
                    column={column}
                    title={queryToLabel.sources}
                    className="text-xs"
                />
            ),
            cell: ({ row }) => {
                const metadata = row.original.metadata ?? {};
                const keys = Object.keys(metadata);

                const serviceIcons = new Set<IconEntry>();
                // biome-ignore lint/complexity/noForEach: <explanation>
                keys.forEach((serviceName) => {
                    const IconComponent =
                        integrationBackEndDataMappingToSvg.get(serviceName);
                    if (IconComponent) {
                        serviceIcons.add({
                            Component: IconComponent,
                            props: {
                                width: 20,
                                height: 20,
                                style: {
                                    marginLeft: "-2px",
                                    marginRight: "-2px",
                                },
                            },
                        });
                    }
                });
                return (
                    <div
                        className="flex flex-row float-right pr-2"
                        onClick={() => {
                            if (handleRowClick) {
                                handleRowClick(row);
                            }
                        }}
                        onKeyDown={(e) => {}}
                    >
                        {Array.from(serviceIcons).map((icon) =>
                            React.createElement(icon.Component, icon.props),
                        )}
                    </div>
                );
            },
            enableSorting: false,
            size: 10,
        },
    ];
    return columns;
}
